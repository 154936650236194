<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="calculator">
    <div class="calculator_wrapper">
      <div class="calculator_wrapper_inner">
        <h1>Estimate Your Shipping or Landing Cost</h1>

        <form @submit.prevent="calculateShipment" class="calculator_form">
          <div class="calculator_form_inner">
            <div class="calculator_wrapper_inner_options">
              <div class="shipments_select">
                <label>Choose Estimate Type</label>
                <div class="shipments_select_wrapper">
                  <select
                    required
                    v-model="typeOfCalculation"
                    class="input_field"
                  >
                    <option value="">Select type</option>
                    <option value="shipping-cost">Shipping Cost</option>

                    <option value="landing-cost">Landing Cost</option>
                  </select>
                </div>
              </div>

              <div
                v-if="typeOfCalculation !== 'shipping-cost'"
                class="shipments_select"
              >
                <label>Currency</label>
                <div class="shipments_select_wrapper">
                  <select
                    required
                    v-model="currency"
                    :items="currencyOptions"
                    class="input_field"
                  >
                    <option value="">Select currency of purchase</option>
                    <option
                      v-for="option in currencyOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                v-if="typeOfCalculation !== 'shipping-cost'"
                class="shipments_select"
              >
                <label>Purchase Total in {{ currency }}</label>
                <div class="input">
                  <input
                    v-model="priceOfGoods"
                    required
                    type="number"
                    min="0"
                    placeholder="0"
                    class="input_field"
                  />
                </div>
              </div>

              <div class="shipments_select">
                <label>Origin Country</label>
                <div class="shipments_select_wrapper">
                  <select
                    required
                    v-model="originCountry"
                    :items="originCountryOptions"
                    class="input_field"
                  >
                    <option value="">Select country</option>
                    <option
                      v-for="option in originCountryOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="shipments_select">
                <label>Destination Country</label>
                <div class="shipments_select_wrapper">
                  <select
                    required
                    v-model="destinationCountry"
                    :items="destinationCountryOptions"
                    class="input_field"
                  >
                    <option value="">Select country</option>
                    <option
                      v-for="option in destinationCountryOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="shipments_select">
                <label>Shipment Method</label>
                <div class="shipments_select_wrapper">
                  <select
                    required
                    v-model="shipmentMethod"
                    :items="shipmentMethodOptions"
                    class="input_field"
                  >
                    <option value="">Select a category</option>
                    <option
                      v-for="shipment_method in shipmentMethodOptions"
                      :key="shipment_method.value"
                      :value="shipment_method.value"
                    >
                      {{ shipment_method?.type }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="calculator_wrapper_inner_options"> -->
              <div class="shipments_select">
                <label>Weight Unit</label>
                <div class="shipments_select_wrapper">
                  <select
                    required
                    v-model="weightUnit"
                    :items="weightOptions"
                    class="input_field"
                  >
                    <option value="">Select Unit</option>
                    <option
                      v-for="option in weightOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="shipments_select">
                <label>Weight of Shipment in {{ weightUnit }}</label>
                <div class="input">
                  <input
                    v-model="weight"
                    required
                    type="number"
                    min="0"
                    placeholder="0"
                    class="input_field"
                  />
                </div>
              </div>

              <div
                class="shipments_select"
                v-if="destinationCountry && weightUnit && shipmentMethod"
              >
                <label>Shipping Fee</label>
                <div class="input">
                  <input
                    :value="shippingValue"
                    placeholder="$0"
                    class="input_field"
                    disabled
                  />
                </div>
              </div>

              <div
                v-if="
                  this.originCountry === 'China' && this.weightUnit !== 'CBM'
                "
                class="shipments_select"
              >
                <label>Clearing Fee</label>
                <div class="input">
                  <input
                    :value="clearingFee"
                    placeholder="$0"
                    class="input_field"
                    disabled
                  />
                </div>
              </div>

              <div
                class="shipments_select"
                v-if="destinationCountry === 'Nigeria'"
              >
                <label>Exchange Rate</label>
                <div class="input">
                  <input
                    :value="`${
                      exchangeRate?.amount ?? exchangeRate?.rate ?? '0'
                    } ${
                      exchangeRate?.amount
                        ? exchangeRate.currencyAbbreviation === 'GBP'
                          ? '/£'
                          : '/$'
                        : ''
                    }`"
                    placeholder="$0"
                    class="input_field"
                    disabled
                  />
                </div>
              </div>

              <div class="shipments_select">
                <label>Estimated Total</label>
                <div class="input">
                  <input
                    :value="estimate"
                    type="Estimated total"
                    placeholder="$0"
                    class="input_field"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="calculator_wrapper_inner_options">
            <div class="button">
              <button class="button_btn">Calculate</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import SupplierPayments from "../../utils/api/supplierPayments/SupplierPayments";

export default {
  data() {
    return {
      originCountry: "",
      typeOfCalculation: "",
      shippingValue: "",
      clearingFee: "",
      exchangeRate: null,
      estimate: null,
      destinationCountry: "",
      emptyTable: false,
      originCountryOptions: ["UK", "US", "Canada", "China"],
      destinationCountryOptions: ["UK", "US", "Canada", "Nigeria"],
      shipmentMethod: "",
      shipmentMethodOptions: [
        {
          value: "AIR_FREIGHT",
          type: "Air-freight",
        },
        {
          value: "SEA_FREIGHT_LCL",
          type: "Sea-freight-LCL",
        },
        {
          value: "SEA_FREIGHT_FCL (20 Ft)",
          type: "Sea-freight-FCL (20 Ft)",
        },
        {
          value: "SEA_FREIGHT_FCL (40 Ft)",
          type: "Sea-freight-FCL (40 Ft)",
        },
      ],
      weightOptions: ["KG", "LBS", "CBM"],
      weightUnit: "",
      weight: "",
      currencyOptions: ["USD", "NGN"],
      exchangeRates: [
        {
          currency: "USD-NGN",
          rate: "₦1280",
        },
        {
          currency: "USD-USD",
          rate: "₦1",
        },
        {
          currency: "USD-GBP",
          rate: "£0.79",
        },
        {
          currency: "USD-CAD",
          rate: "CA$1.35",
        },
      ],
      currency: "",
      priceOfGoods: 0,
      allExchangeRates: [],
      shippingRates: [],
      convertedPrice: 0,
      priceCurrency: "",
    };
  },

  mounted() {
    this.fetchExchangeRates();
  },

  watch: {
    typeOfCalculation(newValue) {
      this.estimate = null;
      if (newValue === "shipping-cost") {
        this.priceOfGoods = 0;
      }
    },

    // currency(newValue) {
    //   if (newValue === "USD") {
    //     return (this.exchangeRate = this.allExchangeRates.find(
    //       (rate) => rate.currencyAbbreviation === "USD"
    //     ));
    //   } else {
    //     return (this.exchangeRate = this.exchangeRates.find(
    //       (rate) => rate.currency === "USD-USD"
    //     ));
    //   }

    // },

    originCountry(newValue) {
      if (newValue === "UK") {
        return (this.exchangeRate = this.allExchangeRates.find(
          (rate) => rate.currencyAbbreviation === "GBP"
        ));
      } else {
        return (this.exchangeRate = this.allExchangeRates.find(
          (rate) => rate.currencyAbbreviation === "USD"
        ));
      }
    },

    // Function to determine the exchange rates for the shipment
    destinationCountry(newValue) {
      if (this.typeOfCalculation !== "shipping-cost") {
        let exchangeRate;
        if (
          this.originCountry === "UK" &&
          newValue === "Nigeria" &&
          this.weightUnit === "KG"
        ) {
          exchangeRate = this.allExchangeRates.find(
            (rate) => rate.currencyAbbreviation === "GBP"
          );
          this.exchangeRate = exchangeRate;
          this.convertedPrice = this.priceOfGoods * exchangeRate.amount;
        } else if (this.currency !== "USD") {
          exchangeRate = this.allExchangeRates.find(
            (currency) => currency.currencyAbbreviation === "USD"
          );

          this.convertedPrice = this.priceOfGoods * exchangeRate.amount;
        } else {
          this.convertedPrice = this.priceOfGoods * 1;
        }
      } else {
        if (newValue === "Nigeria") {
          if (
            this.originCountry === "UK" &&
            newValue === "Nigeria"
            // && this.weightUnit === "KG"
          ) {
            this.exchangeRate = this.allExchangeRates.find(
              (rate) => rate.currencyAbbreviation === "GBP"
            );
          } else if (
            this.originCountry === "Canada" &&
            newValue === "Nigeria"
          ) {
            this.exchangeRate = this.allExchangeRates.find(
              (rate) => rate.currencyAbbreviation === "GBP"
            );
          } else {
            return (this.exchangeRate = this.allExchangeRates.find(
              (rate) => rate.currencyAbbreviation === "USD"
            ));
          }
        } else {
          return (this.exchangeRate = this.exchangeRates.find(
            (rate) => rate.currency === "USD-USD"
          ));
        }
      }
    },

    // getShippingRate(location, unit, destination = null) {
    //   const rate = this.shippingRates?.find(
    //     (rate) =>
    //       rate.location === location &&
    //       rate.weightUnit === unit &&
    //       (!destination || rate.destination === destination)
    //   );
    //   return `${rate?.currency}${rate?.rate?.$numberDecimal}/${rate?.weightUnit}`;
    // },

    shippingFeeMethodFunc: {
      handler: function (newValue) {
        const shippingRates = {
          "China-CBM": `${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "CBM"
            )?.currency
          }${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "CBM"
            )?.rate?.$numberDecimal
          }/${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "CBM"
            )?.weightUnit
          }`,
          "China-KG": `${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "KG"
            )?.currency
          }${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "KG"
            )?.rate?.$numberDecimal
          }/${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "KG"
            )?.weightUnit
          }`,
          "US-LBS": `${
            this.shippingRates?.find(
              (rate) =>
                (rate.location === "USA" || rate.location === "US") &&
                rate.weightUnit === "LB"
            )?.currency
          }${
            this.shippingRates?.find(
              (rate) =>
                (rate.location === "USA" || rate.location === "US") &&
                rate.weightUnit === "LB"
            )?.rate?.$numberDecimal
          }/${
            this.shippingRates?.find(
              (rate) =>
                (rate.location === "USA" || rate.location === "US") &&
                rate.weightUnit === "LB"
            )?.weightUnit
          }`,
          "UK-Nigeria-KG": `${
            this.shippingRates?.find(
              (rate) =>
                rate.location === "UK" &&
                rate.destination === "NIGERIA" &&
                rate.weightUnit === "KG"
            )?.currency
          }${
            this.shippingRates?.find(
              (rate) =>
                rate.location === "UK" &&
                rate.destination === "NIGERIA" &&
                rate.weightUnit === "KG"
            )?.rate?.$numberDecimal
          }/${
            this.shippingRates?.find(
              (rate) =>
                rate.location === "UK" &&
                rate.destination === "NIGERIA" &&
                rate.weightUnit === "KG"
            )?.weightUnit
          }`,
          "UK-CBM": `${
            this.shippingRates?.find(
              (rate) => rate.location === "UK" && rate.weightUnit === "CBM"
            )?.currency
          }${
            this.shippingRates?.find(
              (rate) => rate.location === "UK" && rate.weightUnit === "CBM"
            )?.rate?.$numberDecimal
          }/${
            this.shippingRates?.find(
              (rate) => rate.location === "UK" && rate.weightUnit === "CBM"
            )?.weightUnit
          }`,
          "Canada-Nigeria-KG": `${
            this.shippingRates?.find(
              (rate) =>
                rate.location === "CANADA" &&
                rate.destination &&
                rate.weightUnit === "KG"
            )?.currency
          }${
            this.shippingRates?.find(
              (rate) =>
                rate.location === "CANADA" &&
                rate.destination &&
                rate.weightUnit === "KG"
            )?.rate?.$numberDecimal
          }/${
            this.shippingRates?.find(
              (rate) =>
                rate.location === "CANADA" &&
                rate.destination &&
                rate.weightUnit === "KG"
            )?.weightUnit
          }`,
          "Default-CBM": `${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "CBM"
            )?.currency
          }${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "CBM"
            )?.rate?.$numberDecimal
          }/${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "CBM"
            )?.weightUnit
          }`,
          Default: `${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "KG"
            )?.currency
          }${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "KG"
            )?.rate?.$numberDecimal
          }/${
            this.shippingRates?.find(
              (rate) => rate.location === "CHINA" && rate.weightUnit === "KG"
            )?.weightUnit
          }`,
        };

        let rateKey = `${newValue.originCountry}-${newValue.weightUnit}`;
        if (
          newValue.originCountry === "UK" &&
          newValue.destinationCountry === "Nigeria" &&
          newValue.weightUnit === "KG"
        ) {
          rateKey = "UK-Nigeria-KG";
          this.exchangeRate = this.allExchangeRates.find(
            (rate) => rate.currencyAbbreviation === "GBP"
          );
        } else if (
          newValue.originCountry === "Canada" &&
          newValue.destinationCountry === "Nigeria" &&
          newValue.weightUnit === "KG"
        ) {
          rateKey = "Canada-Nigeria-KG";
        }

        return (this.shippingValue =
          shippingRates[rateKey] || shippingRates["Default"]);
      },
    },

    clearingFeeMethodFunc: {
      handler: function (newValue) {
        if (
          newValue.weightUnit === "KG" &&
          newValue.originCountry === "China"
        ) {
          return (this.clearingFee = "N970/kg");
        } else {
          return (this.clearingFee = "0");
        }
      },
    },

    shipmentMethodFunc: {
      handler: function (newValue) {
        if (
          newValue.shipmentMethod === "SEA_FREIGHT_LCL" &&
          newValue.destinationCountry === "Nigeria" &&
          newValue.originCountry === "China"
        ) {
          this.weightOptions = ["CBM"];
          this.weightUnit = "CBM";
        } else if (
          newValue.shipmentMethod === "AIR_FREIGHT" &&
          newValue.destinationCountry === "Nigeria" &&
          newValue.originCountry === "US"
        ) {
          this.weightOptions = ["LBS"];
          this.weightUnit = "LBS";
          this.exchangeRate = this.allExchangeRates.find(
            (rate) => rate.currencyAbbreviation === "USD"
          );
        } else if (
          newValue.shipmentMethod === "AIR_FREIGHT" &&
          newValue.destinationCountry === "Nigeria" &&
          newValue.originCountry === "UK"
        ) {
          this.weightOptions = ["KG"];
          this.weightUnit = "KG";
          this.exchangeRate = this.allExchangeRates.find(
            (rate) => rate.currencyAbbreviation === "GBP"
          );
        } else if (newValue.shipmentMethod === "AIR_FREIGHT") {
          this.weightOptions = ["KG"];
          this.weightUnit = "KG";
        } else if (
          newValue.shipmentMethod === "SEA_FREIGHT_LCL" &&
          newValue.originCountry === "China"
        ) {
          this.weightOptions = ["CBM"];
          this.weightUnit = "CBM";
        } else {
          this.weightOptions = ["KG", "CBM", "LBS"];
        }
      },
    },
  },

  computed: {
    shipmentMethodFunc() {
      const { shipmentMethod, destinationCountry, originCountry } = this;
      return {
        shipmentMethod,
        destinationCountry,
        originCountry,
      };
    },

    shippingFeeMethodFunc() {
      const { originCountry, weightUnit, destinationCountry } = this;
      return {
        originCountry,
        weightUnit,
        destinationCountry,
      };
    },

    clearingFeeMethodFunc() {
      const { originCountry, weightUnit } = this;
      return {
        originCountry,
        weightUnit,
      };
    },
  },

  methods: {
    async fetchExchangeRates() {
      this.isLoading = true;
      try {
        const exchangeRateResponse = await SupplierPayments.getExchangeRates();
        const shippingRatesResponse = await SupplierPayments.getShippingRates();

        this.allExchangeRates = exchangeRateResponse.data.data;
        this.shippingRates = shippingRatesResponse.data.data;

        console.log(shippingRatesResponse.data.data);
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
    // Function to convert a string to number
    convertStringToNumber(stringParam) {
      const rateString = stringParam;

      if (typeof stringParam === "string") {
        // Split the string by "/"
        const parts = rateString?.split("/");

        // Extract numeric part of the string using a regular expression
        const numericPart = parts[0]?.replace(/[^\d.]/g, "");
        // Convert the extracted numeric part to a number
        const floatNumber = parseFloat(numericPart);

        return floatNumber;
      } else {
        return stringParam;
      }
    },
    calculateClearingTotal(weight, clearingRate, exchangeRate) {
      if (this.originCountry === "China" && this.weightUnit !== "CBM") {
        const rateOfExchange = this.convertStringToNumber(exchangeRate);

        const clearingRateOfGoods = this.convertStringToNumber(clearingRate);
        if (this.clearingFee.includes("kg") && this.currency === "NGN") {
          return weight * clearingRateOfGoods;
        } else if (this.clearingFee.includes("kg") && this.currency !== "NGN") {
          const dollarToNaira = this.allExchangeRates.find(
            (rate) => rate.currencyAbbreviation === "USD"
          );

          const amountInNumber = dollarToNaira.amount;

          const clearingRateOfGoodsInDollar =
            clearingRateOfGoods / amountInNumber;
          return weight * clearingRateOfGoodsInDollar * rateOfExchange;
        } else {
          return weight * clearingRateOfGoods * rateOfExchange;
        }
      } else {
        return 0;
      }
    },

    calculateShippingTotal(weight, shippingRate, exchangeRate) {
      const rateOfExchange = this.convertStringToNumber(exchangeRate);

      const shippingFeeOfGoods = this.convertStringToNumber(shippingRate);

      // if (
      //   this.originCountry === "UK" &&
      //   this.destinationCountry === "Nigeria" &&
      //   this.weightUnit === "KG"
      // ) {
      //   return (
      //     weight *
      //     (shippingFeeOfGoods *
      //       (this.allExchangeRates.find(
      //         (rate) => rate.currencyAbbreviation === "GBP"
      //       ).amount /
      //         this.allExchangeRates.find(
      //           (rate) => rate.currencyAbbreviation === "USD"
      //         ).amount)) *
      //     rateOfExchange
      //   );
      // } else {
      return weight * shippingFeeOfGoods * rateOfExchange;
      // }
    },

    calculateShipment() {
      // Assign currency sign.
      if (this.destinationCountry === "Nigeria") {
        this.priceCurrency = "₦";
      } else {
        this.priceCurrency = "$";
      }

      // Calculate Total
      const clearingTotal = this.calculateClearingTotal(
        this.weight,
        this.clearingFee,
        this.exchangeRate.amount || this.exchangeRate.rate
      );

      const shippingTotal = this.calculateShippingTotal(
        this.weight,
        this.shippingValue,
        this.exchangeRate.amount || this.exchangeRate.rate
      );

      let exchangeRate;
      if (this.currency === "USD") {
        exchangeRate = this.allExchangeRates.find(
          (currency) => currency.currencyAbbreviation === "USD"
        );

        this.convertedPrice = this.priceOfGoods * exchangeRate.amount;
      } else {
        this.convertedPrice = this.priceOfGoods * 1;
      }

      const totalLandingCost =
        this.convertedPrice + shippingTotal + clearingTotal;

      this.estimate = `${this.priceCurrency}${Number(
        totalLandingCost
      ).toLocaleString()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.calculator {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .calculator_wrapper {
    display: flex;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 1.25rem;
    background: #fafafa;
    .calculator_wrapper_inner {
      display: flex;
      padding: 12px 15px;
      max-width: 59.1875em;
      flex: 1;
      flex-direction: column;
      margin: 3.875rem auto;
      h1 {
        color: #363539;
        font-family: $primary-font;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @media only screen and (max-width: 920px) {
          font-size: 1.25rem;
        }
      }
      .calculator_form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.875rem;
        .calculator_form_inner {
          width: 100%;
          display: flex;
          gap: 0.875rem;
          .calculator_wrapper_inner_options {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            gap: 35px;
            margin-top: 1.8125rem;

            @media only screen and (max-width: 920px) {
              flex-direction: column;
            }

            .shipments_select {
              display: flex;
              flex-direction: column;
              gap: 10px;
              border-radius: 0.625rem;
              width: 100%;
              max-width: 26.25em;

              .shipments_select_wrapper {
                border: 1px solid #dedce9;
                padding: 5px 0rem;
                width: 100%;
                height: 3.125em;
                display: flex;
                border-radius: 0.375rem;
                background-color: #fefefe;

                select {
                  border-radius: 0.3125rem;
                  width: 100%;
                  height: auto;
                  background-color: #fefefe;
                  padding: 0rem 1rem;
                  color: #363539;
                  border: none;
                  border: 1px solid transparent;

                  font-family: $secondary-font;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  &:focus {
                    outline: none;
                  }
                }
              }

              label {
                color: #606060;
                font-family: $secondary-font;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }

        .button {
          width: 100%;
          flex: 1;
        }
      }
    }
  }
}
</style>
