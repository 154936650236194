<template>
  <div class="login">
    <div class="login_wrapper">
      <div class="login_body">
        <!-- {/* logo */} -->
        <div class="signup_body_text_logo">
          <div class="signup_body_text_logo_inner">
            <img src="../../assets/logo2.png" alt="logo" />
          </div>
        </div>

        <h3>Reset Your Password</h3>

        <div class="signup_body_form_container_inputs">
          <form @submit.prevent="resetPasswordFormSubmit">
            <div class="input">
              <input
                v-model="email"
                type="email"
                required
                placeholder="Email Address"
                class="input_field"
              />
            </div>
            <div class="button">
              <button class="button_btn">Reset Password</button>
            </div>
          </form>
        </div>

        <router-link to="/login">
          Remember your password? <span>Login</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AuthApi from "../../utils/api/AuthApi";
export default {
  data() {
    return {
      email: "",
    };
  },
  mounted() {},
  methods: {
    async resetPasswordFormSubmit() {
      try {
        const res = await AuthApi.forgotPasswordHandler({
          email: this.email,
        });
        this.$toast.success(res.data?.message);
        return res;
      } catch (err) {
        this.$toast.error(err.response?.data?.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .login_wrapper {
    width: 30em;
    display: flex;
    height: 37.5em;
    background: #fff;
    border-radius: 0.9375rem;
    margin: auto;

    @media only screen and (max-width: 920px) {
      width: 95%;
      height: 34.375em;
    }

    .login_body {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 23.375em;
      align-items: center;

      @media only screen and (max-width: 920px) {
        width: 19.375em;
        height: auto;
      }

      .signup_body_text_logo {
        display: flex;
        width: 10.3125em;
        height: 3.6875em;
        margin-bottom: 4.5625rem;
        justify-content: center;
        align-items: center;

        .signup_body_text_logo_inner {
          width: 100%;
          display: flex;
          height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .signup_body_form_container_inputs {
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;

        form {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 1.25rem;

          .signup_body_form_container_inputs_container {
            display: flex;
            gap: 1.5rem;
          }
        }
      }

      h3 {
        color: $black-color;
        font-family: $primary-font;
        font-size: 2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        margin-bottom: 2.625rem;

        @media only screen and (max-width: 920px) {
          font-size: 22px;
        }
      }

      a {
        text-decoration: none;
        text-align: center;
        color: $grey-link-color;
        margin-bottom: 0.3125rem;
        font-family: $secondary-font;

        @media only screen and (max-width: 920px) {
          font-size: 0.75rem;
        }

        span {
          color: $purple-color;
        }
      }
    }
  }
}
</style>
