import { createRouter, createWebHistory } from "vue-router";
import SignUp from "../views/auth/SignUp.vue";
import LogIn from "../views/auth/LogIn.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import VerifyReset from "../views/auth/VerifyReset";
import Overview from "../views/home/OverView.vue";
import Shipments from "../views/shipments/ShipMents.vue";
import Tracking from "../views/home/TracKing.vue";
import Calculator from "../views/home/Calculator.vue";
import Addresses from "../views/home/Addresses.vue";
import Bookings from "../views/shipments/Bookings.vue";
import ShipmentsTable from "../views/shipments/ShipmentsTable.vue";
import Procurement from "../views/procurement/Procurement.vue";
import ProcurementTable from "../views/procurement/ProcurementTable.vue";
import ProcureItem from "../views/procurement/ProcureItem.vue";
import ProcurementItem from "../views/procurement/ProcurementItem.vue";
import ShipmentItem from "../views/shipments/ShipmentItem.vue";
import SupplierPayments from "../views/SupplierPayments/SupplierPayments.vue";
import SupplierPaymentTable from "../views/SupplierPayments/SupplierPaymentTable.vue";
import SupplierPaymentOverview from "../views/SupplierPayments/SupplierPaymentOverview.vue";
import NewSupply from "../views/SupplierPayments/NewSupply.vue";
import Resources from "../views/Resources/Resources.vue";
import Profile from "../views/profile/Profile.vue";
import store from "../store/Store";

const requireAuth = (to, from, next) => {
  const token = store.state.tokens.accessToken;
  if (
    (to.path === "/login" ||
      to.path === "/reset-password" ||
      to.path === "/register" ||
      to.path === "/verify") &&
    token
  ) {
    // Skip login if already authenticated
    next({ path: "/" }); // Redirect to home or desired route
  } else if (!token && to.matched.some((record) => record.meta.requiresAuth)) {
    // Redirect to login if accessing protected route without token
    next({ path: "/login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/register",
    name: "Signup",
    component: SignUp,
    beforeEnter: requireAuth,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/login",
    name: "LogIn",
    component: LogIn,
    beforeEnter: requireAuth,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    beforeEnter: requireAuth,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/verify",
    name: "verifyPassword",
    component: VerifyReset,
    beforeEnter: requireAuth,
    meta: {
      requiresVisitor: true,
    },
  },

  {
    path: "/",
    name: "overview",
    component: Overview,
    children: [
      {
        path: "home",
        component: Tracking,
      },
      {
        path: "shipping-calculator",
        component: Calculator,
      },
      {
        path: "shipping-addresses",
        component: Addresses,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shipments",
    name: "shipments",
    component: Shipments,
    children: [
      {
        path: "",
        name: "shipments table",
        component: ShipmentsTable,
      },
      {
        path: "book-a-shipment",
        component: Bookings,
      },
      {
        name: "editShipment",
        path: "edit-shipment/:Id",
        component: Bookings,
      },
      {
        name: "procurementToShipment",
        path: "procurement-to-shipment",
        component: Bookings,
      },
      {
        name: "shipmentItem",
        path: "/shipment-item/:itemId",
        component: ShipmentItem,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/procurement",
    name: "procurement",
    component: Procurement,
    children: [
      {
        path: "",
        name: "procurements table",
        component: ProcurementTable,
      },
      {
        path: "procure-a-new-item",
        component: ProcureItem,
      },
      {
        name: "editProcurement",
        path: "edit-procurement/:Id",
        component: ProcureItem,
      },
      {
        name: "procurementItem",
        path: "/procurement-item/:itemId",
        component: ProcurementItem,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/supplier-payments",
    name: "supplier-payments",
    component: SupplierPayments,
    children: [
      {
        path: "",
        name: "supplier payments table",
        component: SupplierPaymentTable,
      },
      {
        path: "new-supplier-payment",
        component: NewSupply,
      },
      {
        name: "supply-payment-overview",
        path: "/supplier-payments/:itemId",
        component: SupplierPaymentOverview,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/resources",
    name: "resources",
    component: Resources,
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/profile",
    name: "profile",
    component: Profile,
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
