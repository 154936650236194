<template>
  <div class="dashboard_menu">
    <router-link to="/home">
      <p>Track Your Shipment</p>
    </router-link>

    <router-link to="/shipping-calculator">
      <p>Shipping Calculator</p>
    </router-link>

    <router-link to="/shipping-addresses">
      <p>Shipping Addresses</p>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.dashboard_menu {
  display: flex;
  width: 31.5000625em;
  border-bottom: 0.0625rem solid $lilac-color;

  @media only screen and (max-width: 920px) {
    width: 100%;
  }

  .router-link-active {
    position: relative;
    border-radius: 0px;
    border-bottom: 1px solid $purple-color;

    div {
      height: 0.0813rem;
      position: absolute;
      transform: rotate(2deg) translate(-0.875rem, 0.2rem);
      background: #000;
      animation: drawLine 0.5s ease-in-out forwards;

      @media only screen and (max-width: 920px) {
        height: 0.0513rem;
      }
    }
    p {
      color: $purple-color;
      font-family: $secondary-font;
      z-index: 10;
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 0.75rem;
      }
    }
  }

  a {
    display: flex;
    width: 11.1875em;
    height: 3.125em;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625rem;
    text-decoration: none;
    position: relative;
    border-radius: 1.875rem;

    p {
      color: rgba(85, 75, 149, 0.65);
      font-family: $secondary-font;
      font-size: 0.9375em;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 0.625rem;
      }
    }
  }
  @keyframes drawLine {
    from {
      width: 0em;
    }
    to {
      width: 8.4375em;
    }
  }
}
</style>
