import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const Shipment = {
  createSupplierPayment: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/supplier-payment/create`,
      method: "POST",
      data: JSON.stringify({
        bankName: payload.bankName,
        accountNumber: payload.accountNumber,
        amount: payload.amount,
        method: payload.method,
        currency: payload.currency,
        accountName: payload.accountName,
        companyName: payload.companyName,
        invoiceFile: payload.invoiceFile,
        accountType: payload.accountType,
        swiftBicCode: payload.swiftBicCode,
        alipayNumber: payload.alipayNumber,
        address: payload.address,
        city: payload.city,
        country: payload.country,
        postCode: payload.postCode,
        supplierEmail: payload.supplierEmail,
        contactNumber: payload.contactNumber,
        routingNumber: payload.routingNumber,
        fedWireRoutingNumber: payload.fedWireRoutingNumber,
        sortCode: payload.sortCode,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getSupplierPayment: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/supplier-payments`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  printInvoice: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/supplier-payment/invoice/${payload.supplierPaymentId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getSupplierPaymentById: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/supplier-payment/${payload.payment_id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getExchangeRates: () => {
    const res = axios({
      url: `${baseUrl}/admin/exchange-rates`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getShippingRates: () => {
    const res = axios({
      url: `${baseUrl}/admin/get-shipping-rates`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default Shipment;
