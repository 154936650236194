<template>
  <div class="tracking">
    <div class="tracking_card">
      <div class="tracking_card_input">
        <h1>Track Your Shipment</h1>
        <form @submit.prevent="getUserId">
          <div class="input track_input">
            <input
              v-model="trackingId"
              required
              class="input_field"
              placeholder="NV2024..."
            />
          </div>

          <div v-if="!isLoading" class="btn_wrapper">
            <div class="button">
              <button class="button_btn">Track</button>
            </div>
          </div>

          <div v-else class="btn_wrapper">
            <div class="button">
              <button :disabled="isLoading" class="button_btn">
                <div class="loading-circle"></div>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div>
        <svg
          width="390"
          height="236"
          viewBox="0 0 390 236"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M171.851 31.244V38.4915H155.539L162.28 26.6654L171.851 31.244Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M171.848 38.4914V31.244L162.469 26.6692V28.3069C162.469 29.354 162.718 30.3861 163.196 31.3177C163.675 32.2493 164.367 33.0538 165.218 33.6646L171.848 38.4914Z"
            fill="#59221D"
          />
          <path
            d="M161.773 24.4777V21.6028C161.773 20.9293 161.964 20.2695 162.323 19.6997C162.682 19.1299 163.195 18.6734 163.803 18.383L167.881 16.433C168.298 16.2334 168.754 16.126 169.217 16.1183C169.68 16.1105 170.14 16.2024 170.564 16.3879C170.988 16.5733 171.368 16.8479 171.677 17.193C171.985 17.5381 172.216 17.9456 172.354 18.3878L172.737 19.6336L161.773 24.4777Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M161.773 24.4777L176.669 17.8934"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M161.716 28.8474H163.67L164.021 30.0222C164.152 30.4587 164.42 30.8414 164.785 31.1132C165.151 31.385 165.595 31.5314 166.05 31.5306H171.258C171.625 31.5305 171.988 31.4526 172.323 31.302C172.658 31.1513 172.957 30.9314 173.2 30.6567C173.443 30.382 173.626 30.0588 173.735 29.7085C173.844 29.3581 173.878 28.9885 173.834 28.6241L172.747 19.6365L162.292 24.2276H161.717C161.109 24.2273 160.525 24.4669 160.092 24.8945C159.66 25.322 159.413 25.903 159.406 26.5111C159.403 26.8166 159.46 27.1198 159.575 27.403C159.689 27.6862 159.859 27.944 160.074 28.1612C160.288 28.3785 160.544 28.551 160.826 28.6688C161.108 28.7866 161.41 28.8473 161.716 28.8474Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M162.258 24.4653C162.366 24.4653 162.473 24.4865 162.572 24.5279C162.672 24.5692 162.763 24.6298 162.839 24.7061C162.915 24.7825 162.976 24.8732 163.017 24.9729C163.058 25.0727 163.079 25.1795 163.079 25.2875V26.5735H163.329C163.66 26.5735 163.976 26.4422 164.21 26.2086C164.444 25.975 164.575 25.6581 164.575 25.3277V24.8285C164.575 24.5253 164.654 24.2275 164.804 23.9642C164.955 23.7009 165.171 23.4812 165.432 23.3268L167.466 22.1213L162.258 24.4653Z"
            fill="#59221D"
          />
          <path
            d="M172.95 22.2104C172.95 22.2104 170.618 21.8855 170.331 23.0115C170.152 23.7101 172.332 27.2145 170.663 27.2145"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M168.081 25.4724C168.333 25.4724 168.537 25.1939 168.537 24.8504C168.537 24.507 168.333 24.2285 168.081 24.2285C167.829 24.2285 167.625 24.507 167.625 24.8504C167.625 25.1939 167.829 25.4724 168.081 25.4724Z"
            fill="#59221D"
          />
          <path
            d="M172.331 25.4724C172.583 25.4724 172.787 25.1939 172.787 24.8504C172.787 24.507 172.583 24.2285 172.331 24.2285C172.079 24.2285 171.875 24.507 171.875 24.8504C171.875 25.1939 172.079 25.4724 172.331 25.4724Z"
            fill="#59221D"
          />
          <path
            d="M162.281 26.5734C162.281 26.2544 162.154 25.9485 161.929 25.723C161.703 25.4974 161.397 25.3707 161.078 25.3707"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M170.32 28.5983C170.32 28.5983 168.404 28.5983 168.414 27.6572"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M207.186 70.2486C206.147 69.4681 205.438 68.3275 205.197 67.0509L202.313 51.8804C201.587 48.1123 199.572 44.7145 196.613 42.2711C193.654 39.8277 189.936 38.4913 186.099 38.4915H143.839C139.86 38.4915 135.941 39.4646 132.424 41.3259C128.907 43.1872 125.898 45.8802 123.66 49.1704L106.037 75.0754C103.565 78.7111 102.648 82.7416 102.979 86.5805C103.56 93.3133 109.278 98.4439 116.036 98.4439H118.275L131.829 81.9865C130.548 81.7057 129.9 80.5443 130.57 79.5391L138.553 67.5607C139.872 65.5837 142.874 65.9316 143.709 68.1529L153.1 92.1979C153.1 92.1979 191.944 92.1979 191.944 92.2055L207.186 70.2486Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M122.462 54.4847L119.508 57.4391L122.462 60.3934L125.416 57.4391L122.462 54.4847Z"
            fill="#F3B304"
          />
          <path
            d="M120.149 57.4404L117.195 60.3947L116.5 59.7L118.892 56.1831L119.83 57.1213L120.149 57.4404Z"
            fill="#F3B304"
          />
          <path
            d="M127.728 54.4846L124.773 57.439L127.728 60.3933L130.682 57.439L127.728 54.4846Z"
            fill="#F3B304"
          />
          <path
            d="M133.095 54.4847L130.141 57.4391L133.095 60.3934L136.049 57.4391L133.095 54.4847Z"
            fill="#F3B304"
          />
          <path
            d="M138.368 54.4847L135.414 57.4391L138.368 60.3934L141.323 57.4391L138.368 54.4847Z"
            fill="#F3B304"
          />
          <path
            d="M143.642 54.4846L140.688 57.439L143.642 60.3933L146.596 57.439L143.642 54.4846Z"
            fill="#F3B304"
          />
          <path
            d="M148.907 54.4845L145.953 57.4388L148.907 60.3932L151.862 57.4388L148.907 54.4845Z"
            fill="#F3B304"
          />
          <path
            d="M154.275 54.4847L151.32 57.4391L154.275 60.3934L157.229 57.4391L154.275 54.4847Z"
            fill="#F3B304"
          />
          <path
            d="M159.54 54.4847L156.586 57.4391L159.54 60.3934L162.495 57.4391L159.54 54.4847Z"
            fill="#F3B304"
          />
          <path
            d="M164.72 54.4852L161.766 57.4396L164.72 60.3939L167.674 57.4396L164.72 54.4852Z"
            fill="#F3B304"
          />
          <path
            d="M169.993 54.4852L167.039 57.4396L169.993 60.3939L172.948 57.4396L169.993 54.4852Z"
            fill="#F3B304"
          />
          <path
            d="M175.353 54.4854L172.398 57.4397L175.353 60.394L178.307 57.4397L175.353 54.4854Z"
            fill="#F3B304"
          />
          <path
            d="M180.626 54.4852L177.672 57.4396L180.626 60.3939L183.581 57.4396L180.626 54.4852Z"
            fill="#F3B304"
          />
          <path
            d="M185.9 54.4852L182.945 57.4396L185.9 60.3939L188.854 57.4396L185.9 54.4852Z"
            fill="#F3B304"
          />
          <path
            d="M191.173 54.4851L188.219 57.4395L191.173 60.3938L194.127 57.4395L191.173 54.4851Z"
            fill="#F3B304"
          />
          <path
            d="M196.532 54.4852L193.578 57.4396L196.532 60.3939L199.487 57.4396L196.532 54.4852Z"
            fill="#F3B304"
          />
          <path
            d="M203.592 58.6084L201.806 60.3947L198.852 57.4403L201.806 54.486L201.981 54.6613L203.044 55.724L203.592 58.6084Z"
            fill="#F3B304"
          />
          <path
            d="M125.418 51.8824L122.464 54.8368L120.883 53.2556L123.275 49.7388L125.099 51.5633L125.418 51.8824Z"
            fill="#F3B304"
          />
          <path
            d="M127.728 48.9274L124.773 51.8817L127.728 54.8361L130.682 51.8817L127.728 48.9274Z"
            fill="#F3B304"
          />
          <path
            d="M133.095 48.9266L130.141 51.881L133.095 54.8353L136.049 51.881L133.095 48.9266Z"
            fill="#F3B304"
          />
          <path
            d="M138.368 48.9265L135.414 51.8809L138.368 54.8352L141.323 51.8809L138.368 48.9265Z"
            fill="#F3B304"
          />
          <path
            d="M143.642 48.9274L140.688 51.8817L143.642 54.8361L146.596 51.8817L143.642 48.9274Z"
            fill="#F3B304"
          />
          <path
            d="M148.907 48.9273L145.953 51.8816L148.907 54.8359L151.862 51.8816L148.907 48.9273Z"
            fill="#F3B304"
          />
          <path
            d="M154.275 48.9265L151.32 51.8809L154.275 54.8352L157.229 51.8809L154.275 48.9265Z"
            fill="#F3B304"
          />
          <path
            d="M159.548 48.9275L156.594 51.8818L159.548 54.8362L162.502 51.8818L159.548 48.9275Z"
            fill="#F3B304"
          />
          <path
            d="M164.72 48.927L161.766 51.8813L164.72 54.8357L167.674 51.8814L164.72 48.927Z"
            fill="#F3B304"
          />
          <path
            d="M169.993 48.927L167.039 51.8813L169.993 54.8357L172.948 51.8814L169.993 48.927Z"
            fill="#F3B304"
          />
          <path
            d="M175.353 48.9273L172.398 51.8816L175.353 54.8359L178.307 51.8816L175.353 48.9273Z"
            fill="#F3B304"
          />
          <path
            d="M180.626 48.927L177.672 51.8813L180.626 54.8357L183.581 51.8814L180.626 48.927Z"
            fill="#F3B304"
          />
          <path
            d="M185.9 48.927L182.945 51.8813L185.9 54.8357L188.854 51.8814L185.9 48.927Z"
            fill="#F3B304"
          />
          <path
            d="M191.173 48.927L188.219 51.8813L191.173 54.8357L194.127 51.8814L191.173 48.927Z"
            fill="#F3B304"
          />
          <path
            d="M196.532 48.9271L193.578 51.8815L196.532 54.8358L199.487 51.8815L196.532 48.9271Z"
            fill="#F3B304"
          />
          <path
            d="M202.704 53.9389L201.806 54.8368L198.852 51.8824L201.552 49.182C201.882 50.0584 202.136 50.9611 202.313 51.8805L202.704 53.9389Z"
            fill="#F3B304"
          />
          <path
            d="M130.686 46.4202L127.732 49.3746L125.336 46.9789C126.245 45.9207 127.244 44.9428 128.322 44.0562L130.414 46.1481L130.686 46.4202Z"
            fill="#F3B304"
          />
          <path
            d="M133.095 43.4648L130.141 46.4192L133.095 49.3735L136.049 46.4192L133.095 43.4648Z"
            fill="#F3B304"
          />
          <path
            d="M138.368 43.4648L135.414 46.4192L138.368 49.3735L141.323 46.4192L138.368 43.4648Z"
            fill="#F3B304"
          />
          <path
            d="M143.642 43.4647L140.688 46.4191L143.642 49.3734L146.596 46.4191L143.642 43.4647Z"
            fill="#F3B304"
          />
          <path
            d="M148.907 43.4646L145.953 46.4189L148.907 49.3733L151.862 46.4189L148.907 43.4646Z"
            fill="#F3B304"
          />
          <path
            d="M154.275 43.4648L151.32 46.4192L154.275 49.3735L157.229 46.4192L154.275 43.4648Z"
            fill="#F3B304"
          />
          <path
            d="M159.548 43.4648L156.594 46.4192L159.548 49.3735L162.502 46.4192L159.548 43.4648Z"
            fill="#F3B304"
          />
          <path
            d="M164.72 43.4644L161.766 46.4187L164.72 49.373L167.674 46.4187L164.72 43.4644Z"
            fill="#F3B304"
          />
          <path
            d="M169.993 43.4653L167.039 46.4197L169.993 49.374L172.948 46.4197L169.993 43.4653Z"
            fill="#F3B304"
          />
          <path
            d="M175.353 43.4646L172.398 46.4189L175.353 49.3733L178.307 46.4189L175.353 43.4646Z"
            fill="#F3B304"
          />
          <path
            d="M180.626 43.4645L177.672 46.4188L180.626 49.3732L183.581 46.4188L180.626 43.4645Z"
            fill="#F3B304"
          />
          <path
            d="M185.9 43.4644L182.945 46.4187L185.9 49.373L188.854 46.4187L185.9 43.4644Z"
            fill="#F3B304"
          />
          <path
            d="M191.173 43.4652L188.219 46.4196L191.173 49.3739L194.127 46.4196L191.173 43.4652Z"
            fill="#F3B304"
          />
          <path
            d="M196.532 43.4645L193.578 46.4188L196.532 49.3732L199.487 46.4188L196.532 43.4645Z"
            fill="#F3B304"
          />
          <path
            d="M201.515 49.0833L198.852 46.4202L199.673 45.599C200.425 46.6821 201.043 47.852 201.515 49.0833Z"
            fill="#F3B304"
          />
          <path
            d="M136.049 40.8622L133.095 43.8165L131.258 41.9814C132.524 41.219 133.857 40.5743 135.24 40.0553L135.728 40.5431L136.049 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M141.323 40.8623L138.368 43.8166L135.414 40.8623L136.723 39.5514C137.59 39.2864 138.471 39.0705 139.362 38.9045L141.001 40.5432L141.323 40.8623Z"
            fill="#F3B304"
          />
          <path
            d="M146.596 40.8622L143.642 43.8166L140.688 40.8622L143.045 38.5049C143.309 38.4966 143.574 38.4921 143.84 38.4915H144.224L146.275 40.5431L146.596 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M151.862 40.8622L148.907 43.8166L145.953 40.8622L148.324 38.4915H149.491L151.591 40.591L151.862 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M157.229 40.8622L154.275 43.8166L151.32 40.8622L153.691 38.4915H154.858L156.91 40.5431L157.229 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M162.502 40.8622L159.548 43.8166L156.594 40.8622L158.965 38.4915H160.132L162.135 40.4952L162.502 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M167.674 40.8622L164.72 43.8166L161.766 40.8622L164.136 38.4915H165.304L167.355 40.5431L167.674 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M172.948 40.8622L169.993 43.8166L167.039 40.8622L169.41 38.4915H170.577L172.677 40.591L172.948 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M178.307 40.8622L175.353 43.8166L172.398 40.8622L174.769 38.4915H175.936L177.988 40.5431L178.307 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M183.581 40.8622L180.626 43.8166L177.672 40.8622L180.043 38.4915H181.21L183.261 40.5431L183.581 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M188.854 40.8622L185.9 43.8166L182.945 40.8622L185.316 38.4915H186.1C186.229 38.4915 186.36 38.4915 186.483 38.4962L188.854 40.8622Z"
            fill="#F3B304"
          />
          <path
            d="M194.127 40.8623L191.173 43.8166L188.219 40.8623L190.103 38.9812C191.282 39.2745 192.425 39.6977 193.511 40.2432L194.127 40.8623Z"
            fill="#F3B304"
          />
          <path
            d="M197.391 42.9598L196.708 43.6431L196.532 43.8184L193.578 40.8641L193.849 40.5929L193.959 40.4836C195.204 41.1593 196.357 41.9915 197.391 42.9598Z"
            fill="#F3B304"
          />
          <path
            d="M151.866 79.4806L149.182 82.1647L147.523 77.9148L148.912 76.5262L151.866 79.4806Z"
            fill="#F3B304"
          />
          <path
            d="M154.275 76.5255L151.32 79.4799L154.275 82.4342L157.229 79.4799L154.275 76.5255Z"
            fill="#F3B304"
          />
          <path
            d="M159.548 76.5254L156.594 79.4797L159.548 82.4341L162.502 79.4797L159.548 76.5254Z"
            fill="#F3B304"
          />
          <path
            d="M164.814 76.5253L161.859 79.4796L164.814 82.434L167.768 79.4796L164.814 76.5253Z"
            fill="#F3B304"
          />
          <path
            d="M170.087 76.5253L167.133 79.4796L170.087 82.434L173.041 79.4796L170.087 76.5253Z"
            fill="#F3B304"
          />
          <path
            d="M175.454 76.5254L172.5 79.4797L175.454 82.4341L178.409 79.4797L175.454 76.5254Z"
            fill="#F3B304"
          />
          <path
            d="M180.72 76.5253L177.766 79.4796L180.72 82.434L183.674 79.4796L180.72 76.5253Z"
            fill="#F3B304"
          />
          <path
            d="M185.9 76.525L182.945 79.4794L185.9 82.4337L188.854 79.4794L185.9 76.525Z"
            fill="#F3B304"
          />
          <path
            d="M191.173 76.5249L188.219 79.4792L191.173 82.4336L194.127 79.4793L191.173 76.5249Z"
            fill="#F3B304"
          />
          <path
            d="M196.532 76.525L193.578 79.4794L196.532 82.4337L199.487 79.4794L196.532 76.525Z"
            fill="#F3B304"
          />
          <path
            d="M202.409 77.1291L199.987 80.6163L198.852 79.4807L201.806 76.5254L202.409 77.1291Z"
            fill="#F3B304"
          />
          <path
            d="M146.598 73.9226L146.279 74.2417L146.144 74.3768L145.972 73.9341L145.96 73.9226L145.965 73.9178L145.562 72.8867L146.279 73.6035L146.598 73.9226Z"
            fill="#F3B304"
          />
          <path
            d="M148.907 70.9678L145.953 73.9221L148.907 76.8765L151.862 73.9221L148.907 70.9678Z"
            fill="#F3B304"
          />
          <path
            d="M154.275 70.9673L151.32 73.9216L154.275 76.876L157.229 73.9216L154.275 70.9673Z"
            fill="#F3B304"
          />
          <path
            d="M159.548 70.9672L156.594 73.9215L159.548 76.8759L162.502 73.9215L159.548 70.9672Z"
            fill="#F3B304"
          />
          <path
            d="M164.814 70.967L161.859 73.9214L164.814 76.8757L167.768 73.9214L164.814 70.967Z"
            fill="#F3B304"
          />
          <path
            d="M170.087 70.967L167.133 73.9214L170.087 76.8757L173.041 73.9214L170.087 70.967Z"
            fill="#F3B304"
          />
          <path
            d="M175.454 70.9673L172.5 73.9216L175.454 76.876L178.409 73.9216L175.454 70.9673Z"
            fill="#F3B304"
          />
          <path
            d="M180.72 70.9672L177.766 73.9215L180.72 76.8759L183.674 73.9215L180.72 70.9672Z"
            fill="#F3B304"
          />
          <path
            d="M185.9 70.9678L182.945 73.9221L185.9 76.8765L188.854 73.9221L185.9 70.9678Z"
            fill="#F3B304"
          />
          <path
            d="M191.173 70.9677L188.219 73.922L191.173 76.8763L194.127 73.922L191.173 70.9677Z"
            fill="#F3B304"
          />
          <path
            d="M196.532 70.9669L193.578 73.9213L196.532 76.8756L199.487 73.9213L196.532 70.9669Z"
            fill="#F3B304"
          />
          <path
            d="M204.664 73.8268L202.265 77.336L198.852 73.9226L201.806 70.9673L204.664 73.8268Z"
            fill="#F3B304"
          />
          <path
            d="M146.597 68.4605L144.609 70.4489L143.713 68.149C143.484 67.5282 143.047 67.0058 142.477 66.6704L143.641 65.5061L146.597 68.4605Z"
            fill="#F3B304"
          />
          <path
            d="M148.907 65.5054L145.953 68.4597L148.907 71.4141L151.862 68.4597L148.907 65.5054Z"
            fill="#F3B304"
          />
          <path
            d="M154.275 65.5056L151.32 68.46L154.275 71.4143L157.229 68.46L154.275 65.5056Z"
            fill="#F3B304"
          />
          <path
            d="M159.548 65.5055L156.594 68.4598L159.548 71.4142L162.502 68.4598L159.548 65.5055Z"
            fill="#F3B304"
          />
          <path
            d="M164.814 65.5054L161.859 68.4597L164.814 71.4141L167.768 68.4597L164.814 65.5054Z"
            fill="#F3B304"
          />
          <path
            d="M170.087 65.5054L167.133 68.4597L170.087 71.4141L173.041 68.4597L170.087 65.5054Z"
            fill="#F3B304"
          />
          <path
            d="M175.454 65.5055L172.5 68.4598L175.454 71.4142L178.409 68.4598L175.454 65.5055Z"
            fill="#F3B304"
          />
          <path
            d="M180.72 65.5054L177.766 68.4597L180.72 71.4141L183.674 68.4597L180.72 65.5054Z"
            fill="#F3B304"
          />
          <path
            d="M185.9 65.5051L182.945 68.4595L185.9 71.4138L188.854 68.4595L185.9 65.5051Z"
            fill="#F3B304"
          />
          <path
            d="M191.173 65.505L188.219 68.4594L191.173 71.4137L194.127 68.4594L191.173 65.505Z"
            fill="#F3B304"
          />
          <path
            d="M196.532 65.5051L193.578 68.4595L196.532 71.4138L199.487 68.4595L196.532 65.5051Z"
            fill="#F3B304"
          />
          <path
            d="M201.806 65.5051L198.852 68.4595L201.806 71.4138L204.76 68.4595L201.806 65.5051Z"
            fill="#F3B304"
          />
          <path
            d="M206.632 70.9674L204.125 68.4605L205.26 67.3259C205.546 68.4936 206.228 69.5263 207.189 70.2487L206.632 70.9674Z"
            fill="#F3B304"
          />
          <path
            d="M143.642 59.9473L140.688 62.9016L143.642 65.856L146.596 62.9016L143.642 59.9473Z"
            fill="#F3B304"
          />
          <path
            d="M148.907 59.9471L145.953 62.9015L148.907 65.8558L151.862 62.9015L148.907 59.9471Z"
            fill="#F3B304"
          />
          <path
            d="M154.275 59.9474L151.32 62.9017L154.275 65.8561L157.229 62.9017L154.275 59.9474Z"
            fill="#F3B304"
          />
          <path
            d="M159.548 59.9473L156.594 62.9016L159.548 65.856L162.502 62.9016L159.548 59.9473Z"
            fill="#F3B304"
          />
          <path
            d="M164.814 59.9471L161.859 62.9015L164.814 65.8558L167.768 62.9015L164.814 59.9471Z"
            fill="#F3B304"
          />
          <path
            d="M170.087 59.9471L167.133 62.9015L170.087 65.8558L173.041 62.9015L170.087 59.9471Z"
            fill="#F3B304"
          />
          <path
            d="M175.454 59.9474L172.5 62.9017L175.454 65.8561L178.409 62.9017L175.454 59.9474Z"
            fill="#F3B304"
          />
          <path
            d="M180.72 59.9473L177.766 62.9016L180.72 65.856L183.674 62.9016L180.72 59.9473Z"
            fill="#F3B304"
          />
          <path
            d="M185.9 59.9469L182.945 62.9012L185.9 65.8556L188.854 62.9012L185.9 59.9469Z"
            fill="#F3B304"
          />
          <path
            d="M191.173 59.9468L188.219 62.9011L191.173 65.8555L194.127 62.9011L191.173 59.9468Z"
            fill="#F3B304"
          />
          <path
            d="M196.532 59.947L193.578 62.9014L196.532 65.8557L199.487 62.9014L196.532 59.947Z"
            fill="#F3B304"
          />
          <path
            d="M204.465 63.1976L201.806 65.8568L198.852 62.9025L201.806 59.9481L202.029 60.1714L204.326 62.4684L204.441 62.5834L204.363 62.6619L204.465 63.1976Z"
            fill="#F3B304"
          />
          <path
            d="M204.479 63.2561L204.125 62.9025L204.366 62.662L204.468 63.1977L204.479 63.2561Z"
            fill="#F3B304"
          />
          <path
            d="M157.229 90.5966L155.628 92.1978H153.097L152.148 89.7686L154.275 87.6422L157.229 90.5966Z"
            fill="#F3B304"
          />
          <path
            d="M162.502 90.5966L160.901 92.1978H158.195L156.594 90.5966L159.548 87.6422L162.502 90.5966Z"
            fill="#F3B304"
          />
          <path
            d="M167.768 90.5966L166.167 92.1978H163.461L161.859 90.5966L164.814 87.6422L167.768 90.5966Z"
            fill="#F3B304"
          />
          <path
            d="M173.042 90.5966L171.44 92.1978H168.734L167.133 90.5966L170.087 87.6422L173.042 90.5966Z"
            fill="#F3B304"
          />
          <path
            d="M178.409 90.5966L176.807 92.1978H174.101L172.5 90.5966L175.454 87.6422L178.409 90.5966Z"
            fill="#F3B304"
          />
          <path
            d="M183.674 90.5966L182.073 92.1978H179.367L177.766 90.5966L180.72 87.6422L183.674 90.5966Z"
            fill="#F3B304"
          />
          <path
            d="M188.854 90.5966L187.253 92.1978H184.547L182.945 90.5966L185.9 87.6422L188.854 90.5966Z"
            fill="#F3B304"
          />
          <path
            d="M193.499 89.9689L191.96 92.2017L189.82 92.1978L188.219 90.5966L191.173 87.6422L193.499 89.9689Z"
            fill="#F3B304"
          />
          <path
            d="M151.866 85.0386L150.743 86.1617L149.305 82.4772L151.595 84.7674L151.866 85.0386Z"
            fill="#F3B304"
          />
          <path
            d="M154.275 82.0836L151.32 85.038L154.275 87.9923L157.229 85.038L154.275 82.0836Z"
            fill="#F3B304"
          />
          <path
            d="M159.548 82.0835L156.594 85.0378L159.548 87.9922L162.502 85.0378L159.548 82.0835Z"
            fill="#F3B304"
          />
          <path
            d="M164.814 82.0835L161.859 85.0378L164.814 87.9922L167.768 85.0378L164.814 82.0835Z"
            fill="#F3B304"
          />
          <path
            d="M170.087 82.0834L167.133 85.0377L170.087 87.9921L173.041 85.0377L170.087 82.0834Z"
            fill="#F3B304"
          />
          <path
            d="M175.454 82.0836L172.5 85.038L175.454 87.9923L178.409 85.038L175.454 82.0836Z"
            fill="#F3B304"
          />
          <path
            d="M180.72 82.0835L177.766 85.0378L180.72 87.9922L183.674 85.0378L180.72 82.0835Z"
            fill="#F3B304"
          />
          <path
            d="M185.9 82.0831L182.945 85.0375L185.9 87.9918L188.854 85.0375L185.9 82.0831Z"
            fill="#F3B304"
          />
          <path
            d="M191.173 82.083L188.219 85.0374L191.173 87.9917L194.127 85.0374L191.173 82.083Z"
            fill="#F3B304"
          />
          <path
            d="M195.547 87.0079L193.578 85.0387L196.532 82.0834L197.969 83.5198L195.547 87.0079Z"
            fill="#F3B304"
          />
          <path
            d="M104.241 79.4807L103.922 79.7998L103.648 80.0729C103.69 79.9339 103.733 79.795 103.779 79.657C103.831 79.4979 103.884 79.3398 103.942 79.1779L104.241 79.4807Z"
            fill="#F3B304"
          />
          <path
            d="M109.515 79.4806L106.56 82.435L103.781 79.656C103.833 79.4969 103.887 79.3388 103.944 79.1769L103.924 79.1567L103.967 79.1136L106.384 76.6968L106.559 76.5215L109.515 79.4806Z"
            fill="#F3B304"
          />
          <path
            d="M111.923 76.5255L108.969 79.4799L111.923 82.4342L114.877 79.4799L111.923 76.5255Z"
            fill="#F3B304"
          />
          <path
            d="M117.189 76.5255L114.234 79.4799L117.189 82.4342L120.143 79.4799L117.189 76.5255Z"
            fill="#F3B304"
          />
          <path
            d="M122.462 76.5254L119.508 79.4797L122.462 82.4341L125.416 79.4797L122.462 76.5254Z"
            fill="#F3B304"
          />
          <path
            d="M130.635 79.4336L130.566 79.5362C130.534 79.586 130.504 79.632 130.477 79.6857L127.728 82.435L124.773 79.4806L127.728 76.5262L130.411 79.2094L130.635 79.4336Z"
            fill="#F3B304"
          />
          <path
            d="M131.531 78.0902L130.636 79.4318L130.567 79.5343C130.534 79.5842 130.505 79.6302 130.478 79.6838C130.463 79.7126 130.447 79.7423 130.434 79.772L130.141 79.4788L131.531 78.0902Z"
            fill="#F3B304"
          />
          <path
            d="M109.513 73.9226L106.559 76.877L105.539 75.8574C105.698 75.5948 105.866 75.3341 106.042 75.0754L107.914 72.3232L109.513 73.9226Z"
            fill="#F3B304"
          />
          <path
            d="M111.915 70.9674L108.961 73.9218L111.915 76.8761L114.87 73.9218L111.915 70.9674Z"
            fill="#F3B304"
          />
          <path
            d="M117.189 70.9673L114.234 73.9216L117.189 76.876L120.143 73.9216L117.189 70.9673Z"
            fill="#F3B304"
          />
          <path
            d="M122.462 70.9673L119.508 73.9216L122.462 76.876L125.416 73.9216L122.462 70.9673Z"
            fill="#F3B304"
          />
          <path
            d="M127.728 70.9672L124.773 73.9215L127.728 76.8759L130.682 73.9215L127.728 70.9672Z"
            fill="#F3B304"
          />
          <path
            d="M135.005 72.8781L132.642 76.4237L130.141 73.9226L133.095 70.9683L135.005 72.8781Z"
            fill="#F3B304"
          />
          <path
            d="M114.872 68.4605L111.918 71.4149L109.898 69.3958L112.29 65.8789L114.872 68.4605Z"
            fill="#F3B304"
          />
          <path
            d="M117.189 65.5046L114.234 68.459L117.189 71.4133L120.143 68.459L117.189 65.5046Z"
            fill="#F3B304"
          />
          <path
            d="M122.462 65.5055L119.508 68.4598L122.462 71.4142L125.416 68.4598L122.462 65.5055Z"
            fill="#F3B304"
          />
          <path
            d="M127.728 65.5054L124.773 68.4597L127.728 71.4141L130.682 68.4597L127.728 65.5054Z"
            fill="#F3B304"
          />
          <path
            d="M133.095 65.5046L130.141 68.459L133.095 71.4133L136.049 68.459L133.095 65.5046Z"
            fill="#F3B304"
          />
          <path
            d="M139.514 66.6513C139.128 66.8768 138.801 67.188 138.555 67.5607L136.938 69.9833L135.414 68.4596L138.368 65.5052L139.514 66.6513Z"
            fill="#F3B304"
          />
          <path
            d="M116.673 59.4336L120.142 62.9025L117.188 65.8569L114.281 62.9505L116.673 59.4336Z"
            fill="#F3B304"
          />
          <path
            d="M122.462 59.9474L119.508 62.9017L122.462 65.8561L125.416 62.9017L122.462 59.9474Z"
            fill="#F3B304"
          />
          <path
            d="M127.728 59.9473L124.773 62.9016L127.728 65.856L130.682 62.9016L127.728 59.9473Z"
            fill="#F3B304"
          />
          <path
            d="M133.095 59.9474L130.141 62.9017L133.095 65.8561L136.049 62.9017L133.095 59.9474Z"
            fill="#F3B304"
          />
          <path
            d="M138.368 59.9474L135.414 62.9017L138.368 65.8561L141.323 62.9017L138.368 59.9474Z"
            fill="#F3B304"
          />
          <path
            d="M104.243 90.5966L104.075 90.7643C104.008 90.6167 103.944 90.4672 103.884 90.3168C103.864 90.2727 103.846 90.2277 103.828 90.1826L104.243 90.5966Z"
            fill="#F3B304"
          />
          <path
            d="M103.883 90.3167L106.557 87.6422L109.512 90.5966L106.141 93.9668L103.883 90.3167Z"
            fill="#F3B304"
          />
          <path
            d="M111.923 87.641L108.969 90.5953L111.923 93.5497L114.877 90.5953L111.923 87.641Z"
            fill="#F3B304"
          />
          <path
            d="M117.189 87.641L114.234 90.5953L117.189 93.5497L120.143 90.5953L117.189 87.641Z"
            fill="#F3B304"
          />
          <path
            d="M114.877 96.2504L113.033 98.095C111.726 97.7899 110.474 97.286 109.319 96.6011L108.969 96.2504L111.923 93.296L114.877 96.2504Z"
            fill="#F3B304"
          />
          <path
            d="M120.143 96.2504L119.772 96.6212L117.952 98.441H116.425L114.234 96.2504L117.189 93.296L117.316 93.4235L120.108 96.2149L120.143 96.2504Z"
            fill="#F3B304"
          />
          <path
            d="M125.044 90.2238L122.375 93.4637L119.508 90.5966L122.462 87.6422L125.044 90.2238Z"
            fill="#F3B304"
          />
          <path
            d="M104.238 85.0386L103.919 85.3577L102.96 86.316C102.899 85.4659 102.899 84.6124 102.96 83.7622L103.919 84.7205L104.238 85.0386Z"
            fill="#F3B304"
          />
          <path
            d="M106.548 82.0835L103.594 85.0378L106.548 87.9922L109.502 85.0378L106.548 82.0835Z"
            fill="#F3B304"
          />
          <path
            d="M111.923 82.0828L108.969 85.0371L111.923 87.9915L114.877 85.0371L111.923 82.0828Z"
            fill="#F3B304"
          />
          <path
            d="M117.189 82.0837L114.234 85.0381L117.189 87.9924L120.143 85.0381L117.189 82.0837Z"
            fill="#F3B304"
          />
          <path
            d="M122.462 82.0836L119.508 85.038L122.462 87.9923L125.416 85.038L122.462 82.0836Z"
            fill="#F3B304"
          />
          <path
            d="M129.929 84.2854L127.261 87.5263L124.773 85.0386L127.728 82.0842L127.903 82.2596L129.929 84.2854Z"
            fill="#F3B304"
          />
          <path
            d="M191.938 92.1979V61.3837"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M172.043 38.204V58.9937L162.758 38.204H172.043Z"
            fill="#59221D"
          />
          <path
            d="M178.47 38.779H172.039V34.4572C173.226 34.4773 174.364 34.9376 175.231 35.7489L178.47 38.779Z"
            fill="#7EBAD2"
          />
          <path
            d="M163.041 38.7789L162.01 35.86C161.865 35.4498 161.596 35.0946 161.241 34.8433C160.885 34.5919 160.461 34.4567 160.026 34.4562H152.13C150.914 34.4562 149.744 34.9183 148.856 35.7489L145.617 38.7789H163.041Z"
            fill="#7EBAD2"
          />
          <path
            d="M157.891 38.779L160.682 35.4384C160.765 35.3408 160.872 35.2666 160.992 35.2232C161.113 35.1799 161.243 35.1689 161.369 35.1914C161.495 35.2139 161.613 35.2691 161.711 35.3515C161.809 35.4338 161.884 35.5405 161.928 35.6607L163.044 38.779H157.891Z"
            fill="#59221D"
          />
          <path
            d="M177.113 57.3282H186.483V63.0261C186.483 63.3973 186.335 63.7531 186.073 64.0155C185.81 64.2778 185.454 64.4252 185.083 64.4252H178.509C178.138 64.4252 177.782 64.2777 177.519 64.0152C177.257 63.7526 177.109 63.3965 177.109 63.0252V57.3282H177.113Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M186.479 55.4183H177.109V57.3272H186.479V55.4183Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M199.202 226.819H174.271C172.184 226.819 170.465 225.151 170.234 223.127H173.939C175.266 223.126 176.582 222.877 177.817 222.391L181.696 220.863C184.027 219.946 186.509 219.476 189.014 219.476H191.955L199.202 226.819Z"
            fill="#59221D"
          />
          <path
            d="M134.431 226.817H104.179C103.191 226.817 102.238 226.449 101.507 225.783C100.776 225.118 100.32 224.205 100.227 223.221H109.268C110.595 223.22 111.911 222.971 113.146 222.485L117.025 220.957C119.356 220.04 121.838 219.569 124.342 219.57H127.475L134.431 226.817Z"
            fill="#59221D"
          />
          <path
            d="M191.952 219.474H189.011C186.506 219.474 184.024 219.944 181.693 220.861L177.814 222.389C176.579 222.875 175.263 223.125 173.936 223.125H170.231C170.213 222.97 170.204 222.814 170.205 222.658V121C170.205 120.048 168.897 119.788 168.531 120.666L127.476 219.57H124.344C121.839 219.569 119.357 220.04 117.026 220.957L113.147 222.485C111.912 222.971 110.596 223.22 109.269 223.221H100.228C100.169 222.586 100.265 221.946 100.507 221.356L153.105 92.1979H191.947V219.474H191.952Z"
            fill="#A74602"
          />
          <path
            d="M191.941 92.1979V97.0851H151.109L153.1 92.1979H191.941Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M176.022 92.1979H172.222C171.914 92.1979 171.664 92.4476 171.664 92.7556V96.5561C171.664 96.8641 171.914 97.1138 172.222 97.1138H176.022C176.33 97.1138 176.58 96.8641 176.58 96.5561V92.7556C176.58 92.4476 176.33 92.1979 176.022 92.1979Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M171.664 94.6415H174.122"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M185.458 92.1979H184.398V97.0851H185.458V92.1979Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M163.615 92.1979H162.555V97.0851H163.615V92.1979Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M178.576 38.4916L142.336 106.721H146.793L182.657 38.4916H178.576Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M146.987 106.693L135.737 134.188C135.412 134.987 134.856 135.671 134.14 136.153C133.424 136.635 132.581 136.892 131.718 136.892H120.172C119.684 136.893 119.202 136.774 118.77 136.545C118.338 136.317 117.969 135.986 117.695 135.582C117.42 135.177 117.25 134.712 117.197 134.226C117.144 133.74 117.212 133.249 117.393 132.795L126.818 109.413C127.14 108.609 127.695 107.919 128.412 107.433C129.13 106.946 129.976 106.686 130.843 106.686L146.987 106.693Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M146.987 106.721L135.9 134.01C135.58 134.798 135.033 135.473 134.328 135.949C133.623 136.424 132.792 136.678 131.942 136.679H127.366C126.885 136.679 126.411 136.561 125.986 136.335C125.561 136.11 125.198 135.784 124.928 135.385C124.658 134.987 124.49 134.528 124.439 134.05C124.387 133.571 124.454 133.088 124.632 132.641L133.921 109.411C134.238 108.618 134.786 107.938 135.493 107.459C136.201 106.98 137.036 106.724 137.89 106.723L146.987 106.721Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M142.193 106.721L141.922 107.387"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M141.386 108.719L131.112 134.01C130.883 134.572 130.538 135.079 130.098 135.498C129.659 135.916 129.135 136.236 128.562 136.437"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-dasharray="15.01 15.01"
          />
          <path
            d="M127.863 136.616C127.627 136.656 127.388 136.676 127.148 136.676"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M131.154 116.167H124.18"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M212.264 73.1457C209.851 74.0771 206.863 78.1785 205.042 83.449C202.766 90.0362 203.03 96.104 205.617 97.0009C206.052 97.1431 206.52 97.1504 206.959 97.022L207.786 96.6578C207.361 97.1737 206.827 97.5893 206.223 97.8749C205.618 98.1605 204.958 98.309 204.29 98.3099H198.564C195.192 98.3099 191.966 95.713 191.961 92.3408C191.954 87.6453 196.944 72.517 206.91 69.7697C210.286 68.8392 215.018 69.7457 215.39 72.3388L212.264 73.1457Z"
            fill="#59221D"
          />
          <path
            d="M256.471 76.4575H244.296C244.088 76.4574 243.884 76.4032 243.704 76.3003C243.524 76.1973 243.373 76.0492 243.268 75.8704C243.162 75.6917 243.105 75.4885 243.102 75.2809C243.099 75.0733 243.149 74.8685 243.25 74.6866L251.143 60.3556C251.359 59.9628 251.677 59.6352 252.064 59.4072C252.45 59.1791 252.891 59.0589 253.339 59.0591H265.509C265.717 59.0592 265.921 59.1134 266.101 59.2163C266.281 59.3193 266.432 59.4674 266.537 59.6461C266.643 59.8249 266.7 60.028 266.703 60.2356C266.707 60.4432 266.656 60.6481 266.556 60.83L258.667 75.1581C258.451 75.5515 258.133 75.8796 257.747 76.1082C257.36 76.3368 256.92 76.4574 256.471 76.4575Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M254.906 69.2234L257.817 76.0635"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M265.896 59.0571L255.648 66.0314"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M252.04 59.4232L255.997 66.5537C256.22 66.9552 256.279 67.427 256.163 67.8711C256.046 68.3152 255.763 68.6973 255.372 68.9379L243.562 76.2035"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M206.963 97.0239C206.524 97.1523 206.056 97.145 205.621 97.0028C203.025 96.1059 202.767 90.0381 205.046 83.4509C206.867 78.1804 209.855 74.079 212.268 73.1476L241.651 65.5312C242.208 65.2735 243.869 65.2361 244.484 65.2361H252.098V65.6874C252.098 66.294 251.884 66.8812 251.495 67.346C251.105 67.8108 250.564 68.1233 249.966 68.2288L248.036 68.728C247.633 68.799 247.252 68.9597 246.92 69.1982C246.588 69.4368 246.314 69.7471 246.119 70.106L243.693 75.1389C243.302 75.8557 243.82 76.4412 244.637 76.4412H253.27C253.27 76.4412 250.696 81.4894 241.853 83.3656L236.241 84.1371L206.963 97.0239Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M245.187 72.0293C244.024 74.1155 242.05 76.7871 239.453 77.0919"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M233.586 82.2493C233.904 82.8676 234.408 83.3709 235.027 83.6886C235.645 84.0064 236.348 84.1226 237.036 84.0211"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M119.053 98.9231H114.262C109.611 98.9231 106.631 93.4993 108.8 89.3845C109.966 87.1699 111.593 84.9122 113.419 82.9066C119.438 76.2945 127.455 73.7876 131.329 77.3122C132.095 78.0136 132.75 79.3485 132.75 81.5027L119.053 98.9231Z"
            fill="#59221D"
          />
          <path
            d="M118.818 98.7153C117.203 97.3468 118.921 91.8023 122.651 87.3942C125.344 84.2156 128.448 81.9982 130.576 81.521C130.739 81.5066 130.895 81.4999 131.045 81.4999H139.861C143.471 81.4996 147.018 82.4565 150.138 84.2731L156.434 87.9366C157.2 88.4102 157.832 89.0715 158.27 89.8579C158.708 90.6443 158.939 91.5296 158.939 92.4299V100.936C158.939 102.037 158.533 103.101 157.798 103.922C157.063 104.743 156.051 105.265 154.956 105.387L148.681 106.086V104.728C148.68 104.341 148.777 103.959 148.961 103.618C149.145 103.277 149.411 102.987 149.735 102.774L151.246 101.951C151.311 101.916 151.363 101.859 151.392 101.791C151.422 101.722 151.428 101.646 151.41 101.574C151.391 101.502 151.349 101.438 151.291 101.392C151.232 101.347 151.159 101.322 151.085 101.322H149.188C146.576 101.322 144.548 100.587 142.68 98.7603L121.102 98.7277L118.818 98.7153Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M152.844 92.9004L156.236 95C157.002 95.4737 157.633 96.1351 158.072 96.9214C158.51 97.7078 158.74 98.5931 158.74 99.4933V102.125"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M152.844 89.3547L156.236 91.4543C157.002 91.9284 157.635 92.5906 158.073 93.3779C158.511 94.1651 158.741 95.0514 158.74 95.9524V99.3505"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M158.63 102.469C158.342 100.869 157.576 99.6245 156.33 98.8541L152.938 96.7535"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M142.031 96.3195C142.031 97.0509 142.225 97.7693 142.593 98.4015C142.96 99.0337 143.489 99.5572 144.125 99.9187"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M289.898 227.296C289.898 226.004 290.153 224.724 290.647 223.53C291.142 222.336 291.867 221.251 292.78 220.338C293.694 219.424 294.779 218.699 295.973 218.204C297.167 217.71 298.447 217.456 299.739 217.456H299.852C296.628 213.048 294.695 207.474 294.669 201.414C294.606 186.964 305.285 175.29 318.597 175.259C330.459 175.232 340.32 184.554 342.274 196.82C345.282 194.362 348.972 193.107 352.96 193.107C363.081 193.107 371.285 202.235 371.285 213.734C371.285 218.518 369.673 223.693 367.283 227.208L289.898 227.296Z"
            fill="#FED4C7"
          />
          <path
            d="M269.238 113.148C271.125 113.147 272.981 112.662 274.627 111.74C276.273 110.817 277.655 109.487 278.641 107.877L284.373 98.5244H268.859L264.779 105.181C262.643 108.67 265.151 113.148 269.238 113.148Z"
            fill="#FED4C7"
          />
          <path
            d="M310.801 83.5121V96.9959H284.544V83.5121C284.544 81.2098 283.7 78.9873 282.171 77.2655C280.643 75.5438 278.536 74.4424 276.25 74.1699C276.619 74.1256 276.991 74.1035 277.363 74.1038H301.394C303.889 74.1043 306.282 75.0957 308.046 76.8601C309.81 78.6244 310.801 81.0172 310.801 83.5121Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M284.547 83.5283V97.1875H267.953V83.5283C267.953 79.263 270.719 75.6513 274.534 74.4362C275.65 74.0807 276.849 74.0807 277.965 74.4362C281.78 75.6513 284.547 79.263 284.547 83.5283Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M267.953 83.5283C267.953 79.263 270.719 75.6513 274.534 74.4362C275.65 74.0807 276.849 74.0807 277.965 74.4362C281.78 75.6513 284.547 79.263 284.547 83.5283V95.271L267.953 83.5283Z"
            fill="#59221D"
          />
          <path
            d="M284.539 96.996V226.817"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M268.353 112.823C270.372 112.823 272.357 112.306 274.119 111.32C275.881 110.334 277.36 108.912 278.415 107.191L284.548 97.1876H267.948L263.584 104.307C261.298 108.033 263.981 112.823 268.353 112.823Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M295.348 66.7585H289.625V69.3191H295.348V66.7585Z"
            fill="#7EBAD2"
          />
          <path
            d="M289.625 89.5559V66.749"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M289.875 89.6517V74.4219H293.52L289.875 89.6517Z"
            fill="#FED4C7"
          />
          <path
            d="M261.336 227.051V170.453C261.342 167.418 262.536 164.506 264.664 162.342C266.791 160.177 269.682 158.932 272.716 158.873C275.751 158.815 278.687 159.947 280.897 162.028C283.106 164.109 284.413 166.972 284.536 170.005C284.542 170.153 284.545 170.303 284.545 170.453V227.051"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M284.523 227.051V170.453C284.523 170.303 284.523 170.153 284.533 170.005C284.656 166.972 285.962 164.109 288.172 162.028C290.381 159.947 293.318 158.815 296.352 158.873C299.387 158.932 302.278 160.177 304.405 162.342C306.532 164.506 307.727 167.418 307.733 170.453V227.051"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M307.734 227.051V170.453C307.74 167.418 308.935 164.506 311.062 162.342C313.19 160.177 316.08 158.932 319.115 158.873C322.149 158.815 325.086 159.947 327.295 162.028C329.505 164.109 330.811 166.972 330.934 170.005C330.941 170.153 330.944 170.303 330.944 170.453V227.051"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M330.922 227.051V170.453C330.922 170.303 330.922 170.153 330.931 170.005C331.055 166.972 332.361 164.109 334.57 162.028C336.78 159.947 339.716 158.815 342.751 158.873C345.785 158.932 348.676 160.177 350.803 162.342C352.931 164.506 354.125 167.418 354.131 170.453V227.051"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M261.336 213.672C261.338 210.652 262.516 207.752 264.622 205.588C266.727 203.423 269.593 202.165 272.611 202.079C275.629 201.993 278.562 203.087 280.786 205.128C283.011 207.17 284.353 209.998 284.526 213.012"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M284.547 213.012C284.72 209.998 286.062 207.17 288.287 205.128C290.511 203.087 293.444 201.993 296.462 202.079C299.48 202.165 302.346 203.423 304.451 205.588C306.557 207.752 307.735 210.652 307.737 213.672"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M307.734 213.672C307.736 210.652 308.915 207.752 311.02 205.588C313.125 203.423 315.991 202.165 319.009 202.079C322.027 201.993 324.96 203.087 327.185 205.128C329.41 207.17 330.751 209.998 330.925 213.012"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M330.945 213.012C331.119 209.998 332.46 207.17 334.685 205.128C336.91 203.087 339.842 201.993 342.861 202.079C345.879 202.165 348.745 203.423 350.85 205.588C352.955 207.752 354.134 210.652 354.136 213.672"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M354.117 227.051V170.453C354.117 170.303 354.117 170.153 354.127 170.005C354.25 166.972 355.556 164.109 357.766 162.028C359.975 159.947 362.912 158.815 365.946 158.873C368.981 158.932 371.871 160.177 373.999 162.342C376.126 164.506 377.321 167.418 377.327 170.453V227.051"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M354.133 213.012C354.306 209.998 355.648 207.17 357.872 205.128C360.097 203.087 363.03 201.993 366.048 202.079C369.066 202.165 371.932 203.423 374.037 205.588C376.142 207.752 377.321 210.652 377.323 213.672"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M261.336 180.936H377.325"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M301.18 227.051H387"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M330.918 226.817C328.672 226.817 326.852 225.149 326.852 223.09C326.852 221.032 328.672 219.363 330.918 219.363C331.339 219.362 331.759 219.422 332.163 219.54C332.173 218.339 332.482 217.159 333.063 216.107C333.643 215.055 334.477 214.165 335.488 213.517C336.5 212.868 337.657 212.482 338.855 212.394C340.053 212.305 341.254 212.517 342.35 213.009C342.482 212.501 342.715 212.025 343.036 211.609C343.356 211.193 343.757 210.846 344.215 210.588C344.673 210.331 345.177 210.169 345.699 210.111C346.221 210.053 346.749 210.101 347.252 210.252C347.755 210.403 348.223 210.654 348.626 210.99C349.03 211.326 349.362 211.74 349.602 212.207C349.842 212.674 349.985 213.184 350.024 213.708C350.062 214.232 349.994 214.758 349.824 215.255C350.002 215.232 350.181 215.221 350.361 215.221C351.482 215.221 352.557 215.664 353.352 216.454C354.147 217.244 354.597 218.317 354.604 219.437C355.127 219.169 355.703 219.023 356.291 219.008C356.878 218.994 357.461 219.112 357.997 219.354C358.532 219.597 359.006 219.957 359.382 220.407C359.759 220.858 360.029 221.388 360.173 221.958C360.317 222.528 360.33 223.122 360.211 223.698C360.093 224.273 359.847 224.815 359.49 225.282C359.133 225.749 358.676 226.129 358.152 226.395C357.628 226.661 357.051 226.805 356.463 226.816H330.918V226.817Z"
            fill="#7EBAD2"
          />
          <path
            d="M338.175 215.962C338.651 215.962 339.037 215.576 339.037 215.099C339.037 214.623 338.651 214.237 338.175 214.237C337.699 214.237 337.312 214.623 337.312 215.099C337.312 215.576 337.699 215.962 338.175 215.962Z"
            fill="white"
          />
          <path
            d="M340.846 211.344C341.508 211.344 342.044 210.808 342.044 210.146C342.044 209.484 341.508 208.948 340.846 208.948C340.185 208.948 339.648 209.484 339.648 210.146C339.648 210.808 340.185 211.344 340.846 211.344Z"
            fill="#7EBAD2"
          />
          <path
            d="M325.094 221.857C325.888 221.857 326.531 221.213 326.531 220.42C326.531 219.626 325.888 218.982 325.094 218.982C324.3 218.982 323.656 219.626 323.656 220.42C323.656 221.213 324.3 221.857 325.094 221.857Z"
            fill="#7EBAD2"
          />
          <path
            d="M331.207 223.153C331.684 223.153 332.071 222.766 332.071 222.289C332.071 221.812 331.684 221.426 331.207 221.426C330.73 221.426 330.344 221.812 330.344 222.289C330.344 222.766 330.73 223.153 331.207 223.153Z"
            fill="white"
          />
          <path
            d="M340.229 218.982C340.891 218.982 341.427 218.446 341.427 217.784C341.427 217.123 340.891 216.587 340.229 216.587C339.568 216.587 339.031 217.123 339.031 217.784C339.031 218.446 339.568 218.982 340.229 218.982Z"
            fill="white"
          />
          <path
            d="M350.643 219.897C351.305 219.897 351.841 219.361 351.841 218.7C351.841 218.038 351.305 217.502 350.643 217.502C349.982 217.502 349.445 218.038 349.445 218.7C349.445 219.361 349.982 219.897 350.643 219.897Z"
            fill="white"
          />
          <path
            d="M346.356 224.241C346.832 224.241 347.219 223.855 347.219 223.378C347.219 222.901 346.832 222.514 346.356 222.514C345.879 222.514 345.492 222.901 345.492 223.378C345.492 223.855 345.879 224.241 346.356 224.241Z"
            fill="white"
          />
          <path
            d="M363.723 226.721C364.947 226.721 365.939 225.729 365.939 224.506C365.939 223.282 364.947 222.29 363.723 222.29C362.5 222.29 361.508 223.282 361.508 224.506C361.508 225.729 362.5 226.721 363.723 226.721Z"
            fill="#7EBAD2"
          />
          <path
            d="M64.6045 101.736H33.2067C30.5684 101.736 28.4297 103.874 28.4297 106.513V133.948C28.4297 136.586 30.5684 138.725 33.2067 138.725H64.6045C67.2428 138.725 69.3815 136.586 69.3815 133.948V106.513C69.3815 103.874 67.2428 101.736 64.6045 101.736Z"
            fill="#7EBAD2"
          />
          <path
            d="M58.6647 102.004H32.0582C29.42 102.004 27.2812 104.143 27.2812 106.781V133.68C27.2812 136.318 29.42 138.457 32.0582 138.457H58.6647C61.303 138.457 63.4417 136.318 63.4417 133.68V106.781C63.4417 104.143 61.303 102.004 58.6647 102.004Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M51.5004 115.318H39.0927C38.4979 115.318 38.0156 115.8 38.0156 116.395V124.034C38.0156 124.628 38.4979 125.111 39.0927 125.111H51.5004C52.0953 125.111 52.5775 124.628 52.5775 124.034V116.395C52.5775 115.8 52.0953 115.318 51.5004 115.318Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M52.5785 116.421L46.0297 120.436C45.8091 120.571 45.5556 120.643 45.2971 120.643C45.0385 120.643 44.785 120.571 44.5645 120.436L38.0156 116.421"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M47.4062 121.203L52.3864 124.571"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M38.2031 124.571L43.1833 121.203"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M27.2812 106.781H69.3839"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M228.368 227.051H15.4062"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M54.6719 232.565H199.338"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M203.253 226.721C225.985 226.721 244.413 208.293 244.413 185.561C244.413 162.83 225.985 144.402 203.253 144.402C180.522 144.402 162.094 162.83 162.094 185.561C162.094 208.293 180.522 226.721 203.253 226.721Z"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M203.253 223.892C224.422 223.892 241.584 206.731 241.584 185.562C241.584 164.392 224.422 147.231 203.253 147.231C182.083 147.231 164.922 164.392 164.922 185.562C164.922 206.731 182.083 223.892 203.253 223.892Z"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M44.1597 226.721C66.8916 226.721 85.3195 208.293 85.3195 185.561C85.3195 162.83 66.8916 144.402 44.1597 144.402C21.4278 144.402 3 162.83 3 185.561C3 208.293 21.4278 226.721 44.1597 226.721Z"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M44.159 223.892C65.3286 223.892 82.49 206.731 82.49 185.562C82.49 164.392 65.3286 147.231 44.159 147.231C22.9895 147.231 5.82812 164.392 5.82812 185.562C5.82812 206.731 22.9895 223.892 44.159 223.892Z"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M198.931 103.768H196.266V128.8H198.931V103.768Z"
            fill="#59221D"
          />
          <path
            d="M218.552 106.813L211.851 127.8C211.767 128.062 211.602 128.291 211.38 128.454C211.157 128.616 210.889 128.704 210.613 128.704H208.051C207.92 128.704 207.791 128.673 207.674 128.614C207.557 128.554 207.456 128.468 207.379 128.362C207.302 128.257 207.25 128.134 207.23 128.005C207.209 127.876 207.218 127.743 207.258 127.619L214.062 106.308C214.116 106.14 214.222 105.994 214.365 105.891C214.508 105.788 214.679 105.732 214.855 105.733H217.759C217.889 105.733 218.018 105.763 218.135 105.822C218.251 105.881 218.352 105.967 218.429 106.072C218.506 106.177 218.558 106.299 218.579 106.428C218.601 106.557 218.591 106.689 218.552 106.813Z"
            fill="#A74602"
          />
          <path
            d="M206.891 128.703H202.234L210.197 103.768H214.853L206.891 128.703Z"
            fill="#F3B304"
          />
          <path
            d="M203.735 103.768H198.922V128.8H203.735V103.768Z"
            fill="#FED4C7"
          />
          <path
            d="M194.544 114.264H177.066C176.115 114.264 175.344 115.035 175.344 115.986V127.196C175.344 128.147 176.115 128.918 177.066 128.918H194.544C195.495 128.918 196.266 128.147 196.266 127.196V115.986C196.266 115.035 195.495 114.264 194.544 114.264Z"
            fill="#7EBAD2"
          />
          <path
            d="M167.25 110.54H218.982L214.317 125.149C213.974 126.221 213.3 127.157 212.391 127.821C211.482 128.485 210.385 128.842 209.26 128.842H175.97L167.25 110.54Z"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M215.051 110.54L210.385 125.149C210.043 126.221 209.368 127.157 208.459 127.821C207.55 128.485 206.454 128.842 205.328 128.842"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M210.863 110.54L206.197 125.149C205.855 126.221 205.181 127.157 204.272 127.821C203.363 128.485 202.266 128.842 201.141 128.842"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M206.676 110.54L202.01 125.149C201.668 126.221 200.993 127.157 200.084 127.821C199.175 128.485 198.079 128.842 196.953 128.842"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M202.496 110.54L197.83 125.149C197.488 126.221 196.813 127.157 195.904 127.821C194.996 128.485 193.899 128.842 192.773 128.842"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M198.301 110.54L193.635 125.149C193.293 126.221 192.618 127.157 191.709 127.821C190.8 128.485 189.704 128.842 188.578 128.842"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M194.113 110.54L189.447 125.149C189.105 126.221 188.431 127.157 187.522 127.821C186.613 128.485 185.516 128.842 184.391 128.842"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M189.926 110.54L185.26 125.149C184.918 126.221 184.243 127.157 183.334 127.821C182.425 128.485 181.329 128.842 180.203 128.842"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M185.738 110.54L181.072 125.149C180.73 126.221 180.056 127.157 179.147 127.821C178.238 128.485 177.141 128.842 176.016 128.842"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M181.557 110.54L176.891 125.149C176.6 126.064 176.065 126.882 175.344 127.517"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M177.361 110.54L173.312 123.219"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M173.175 110.54L170.797 117.988"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M156.086 185.624C156.086 159.574 177.203 138.457 203.253 138.457"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M177.397 131.833C167.255 135.341 157.935 143.497 152.44 153.49L145.914 165.352C142.554 171.461 137.616 176.555 131.615 180.103C125.614 183.651 118.771 185.523 111.799 185.523H96.8935L79.8516 130.381"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M203.257 185.562L165.248 106.498C164.457 104.852 163.218 103.463 161.672 102.49C160.126 101.518 158.338 101.002 156.511 101.002H154.484C153.235 101.002 152.008 101.326 150.922 101.941C149.836 102.557 148.927 103.443 148.286 104.514L146.977 106.699"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M71.5867 138.418C71.5865 137.113 71.0678 135.861 70.1449 134.938C69.222 134.015 67.9703 133.497 66.665 133.497H38.8904C37.5852 133.497 36.3335 134.015 35.4106 134.938C34.4876 135.861 33.969 137.113 33.9688 138.418V138.457H76.0638C77.8707 138.456 79.6307 139.032 81.0879 140.101C82.5451 141.169 83.6237 142.675 84.1669 144.398L44.4772 185.561"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M203.229 187.518C204.3 187.518 205.168 186.65 205.168 185.579C205.168 184.508 204.3 183.639 203.229 183.639C202.157 183.639 201.289 184.508 201.289 185.579C201.289 186.65 202.157 187.518 203.229 187.518Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M113.8 195.319C113.727 200.523 109.48 204.77 104.276 204.844C102.995 204.862 101.724 204.626 100.535 204.149C99.3468 203.671 98.2652 202.962 97.3531 202.063C96.441 201.164 95.7168 200.093 95.2224 198.912C94.7281 197.73 94.4735 196.462 94.4735 195.182V195.14C94.4787 194.647 94.3861 194.157 94.2012 193.7C94.0162 193.243 93.7426 192.826 93.3959 192.475C93.0493 192.124 92.6365 191.845 92.1815 191.654C91.7265 191.464 91.2382 191.365 90.7449 191.364H44.5302C42.9443 191.364 41.6075 190.118 41.5711 188.531C41.5622 188.144 41.6306 187.76 41.7724 187.4C41.9142 187.04 42.1265 186.712 42.3968 186.435C42.6671 186.158 42.99 185.938 43.3465 185.788C43.703 185.638 44.0859 185.561 44.4727 185.56H103.223C103.524 185.532 103.829 185.517 104.137 185.517C105.417 185.517 106.685 185.772 107.867 186.266C109.048 186.761 110.12 187.485 111.019 188.397C111.918 189.309 112.627 190.391 113.104 191.579C113.582 192.768 113.818 194.039 113.8 195.319Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M104.142 200.629C107.098 200.629 109.495 198.233 109.495 195.276C109.495 192.32 107.098 189.924 104.142 189.924C101.186 189.924 98.7891 192.32 98.7891 195.276C98.7891 198.233 101.186 200.629 104.142 200.629Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M104.133 195.276L109.403 208.707C109.486 208.918 109.632 209.1 109.82 209.228C110.008 209.356 110.23 209.424 110.457 209.423H123.593"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M44.5645 190.393C45.6357 190.393 46.5041 189.525 46.5041 188.454C46.5041 187.382 45.6357 186.514 44.5645 186.514C43.4934 186.514 42.625 187.382 42.625 188.454C42.625 189.525 43.4934 190.393 44.5645 190.393Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M123.872 207.134H110.411C110.007 207.134 109.68 207.461 109.68 207.865V208.692C109.68 209.096 110.007 209.423 110.411 209.423H123.872C124.276 209.423 124.603 209.096 124.603 208.692V207.865C124.603 207.461 124.276 207.134 123.872 207.134Z"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M89.7395 130.381H73.5868C72.8531 130.381 72.1494 130.09 71.6306 129.571C71.1118 129.052 70.8203 128.348 70.8203 127.615C70.8203 126.881 71.1118 126.177 71.6306 125.658C72.1494 125.14 72.8531 124.848 73.5868 124.848H97.0473C97.1432 124.848 97.237 124.876 97.3166 124.93C97.3963 124.983 97.458 125.059 97.4938 125.148C97.5296 125.237 97.5379 125.335 97.5176 125.429C97.4972 125.523 97.4493 125.608 97.3798 125.674L94.4695 128.474C93.1989 129.698 91.5035 130.381 89.7395 130.381Z"
            fill="#F3B304"
          />
          <path
            d="M23.6875 24.8486V24.5611C25.604 19.5417 30.749 15.9463 36.7976 15.9463C38.616 15.9429 40.4193 16.2753 42.117 16.9266C42.8424 17.2074 43.6282 16.6956 43.6272 15.9683V15.9501C43.6263 14.4004 43.923 12.8649 44.5012 11.4271C46.4724 6.5044 51.5656 3 57.5404 3C63.5152 3 68.6065 6.5044 70.5729 11.4251C70.618 11.5363 70.662 11.6494 70.7032 11.7634C70.8719 11.6471 71.0428 11.5344 71.2159 11.4251C73.5556 9.97508 76.2536 9.20681 79.0062 9.20681C81.7588 9.20681 84.4568 9.97508 86.7965 11.4251C90.4906 13.7537 92.9179 17.6903 92.9179 22.1578V22.177C92.9179 22.7855 93.6846 23.1276 94.1838 22.7375C96.9628 20.5824 100.687 19.2648 104.782 19.2648C110.087 19.2648 114.77 21.4765 117.577 24.8534"
            stroke="#FED4C7"
            stroke-width="6"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M75.1821 27.2855C71.3049 24.7403 66.5059 24.8457 63.1299 27.2002C63.0523 27.2539 62.9756 27.3085 62.9009 27.365C62.8415 27.2175 62.7776 27.0715 62.7092 26.9271C61.8089 24.9894 60.3855 23.3414 58.5994 22.1687C56.8134 20.996 54.7354 20.3451 52.5995 20.2891C49.2091 20.2278 45.9567 21.7486 44.0545 24.6474L44.0469 24.6589"
            stroke="#FED4C7"
            stroke-width="6"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Shipment from "@/utils/api/shipment/ShipmentApi";
export default {
  data() {
    return {
      trackingId: "",
      isLoading: false,
    };
  },

  methods: {
    async getUserId() {
      const token = this.$store.state.tokens.accessToken;
      this.isLoading = true;
      try {
        const userShipmentRes = await Shipment.getUserShipments({
          access_token: token,
        });
        if (userShipmentRes?.data?.error?.name === "TokenExpiredError") {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        }

        this.isLoading = false;

        // Get the shipment with that particular ID
        const userId = userShipmentRes.data.data?.find((trackingId) => {
          return (
            trackingId.trackingNumber.toLowerCase() ===
            this.trackingId.toLowerCase()
          );
        });

        // Route to the shipment page after getting a matching tracking ID
        if (
          userId.trackingNumber.toLowerCase() === this.trackingId.toLowerCase()
        ) {
          this.$router.push(`/shipment-item/${userId._id}`);
        }
      } catch (err) {
        this.isLoading = false;
        this.$toast.error(
          err.response?.data?.message || "Shipment with this ID does not exist."
        );
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.tracking {
  margin-top: 2.375rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  .tracking_card {
    display: flex;
    flex: 1;
    height: 16.875em;
    border-radius: 1.25rem;
    background: #f7ebd4;

    @media only screen and (max-width: 920px) {
      background-image: url("../../assets/background.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    svg {
      @media only screen and (max-width: 920px) {
        width: 150px;
        height: 236px;
        display: none;
      }
    }
    .tracking_card_input {
      display: flex;
      margin: 3.0625rem 2rem;
      flex-direction: column;
      flex: 1;

      .track_input {
        display: flex;
        flex: 1;
      }

      h1 {
        color: #363539;
        font-family: $primary-font;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        margin-bottom: 1.875rem;

        @media only screen and (max-width: 920px) {
          font-size: 20px;
        }
      }
      form {
        display: flex;
        gap: 0.4375rem;

        @media only screen and (max-width: 920px) {
          flex-direction: column;
        }

        .btn_wrapper {
          display: flex;

          @media only screen and (max-width: 920px) {
            width: 100%;
          }

          .loading-circle {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid #fff;
            position: relative;
            animation: rotate 1s linear infinite;
          }

          .loading-circle::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
            transform: translate(-50%, -50%);
            animation: bounce 0.6s linear infinite;
          }

          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          @keyframes bounce {
            0%,
            100% {
              transform: scale(1);
            }
            50% {
              transform: scale(0);
            }
          }

          .button {
            display: flex;
            justify-content: flex-end;

            @media only screen and (max-width: 920px) {
              width: 100%;
            }

            button {
              width: 8.125em;

              @media only screen and (max-width: 920px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
