<template>
  <div>
    <div class="tracking_stats">
      <h2>Your Activity</h2>
      <div class="tracking_stats_wrapper">
        <div
          class="tracking_stats_wrapper_card"
          @click="routeToPage('supplier payments table')"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 7V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V7H20ZM20 5H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V5ZM13 13V15H17V13H13Z"
              fill="#544D92"
            />
          </svg>

          <h1>₦{{ metrics.paidSupplierPayments }}</h1>
          <p>Supplier Payments</p>
        </div>

        <div
          class="tracking_stats_wrapper_card"
          @click="routeToPage('procurements table')"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.757 1.00001L6.291 8.46601L6.299 12.713L10.537 12.706L18 5.24301V18C18 18.2652 17.8946 18.5196 17.7071 18.7071C17.5196 18.8946 17.2652 19 17 19H1C0.734784 19 0.48043 18.8946 0.292893 18.7071C0.105357 18.5196 0 18.2652 0 18V2.00001C0 1.73479 0.105357 1.48044 0.292893 1.2929C0.48043 1.10536 0.734784 1.00001 1 1.00001H13.757ZM17.485 0.100006L18.9 1.51601L9.708 10.708L8.296 10.711L8.294 9.29401L17.485 0.100006Z"
              fill="#7EBAD2"
            />
          </svg>

          <h1>{{ metrics.successFullProcurements }}</h1>
          <p>Procurement History</p>
        </div>

        <div
          class="tracking_stats_wrapper_card"
          @click="routeToPage('shipments table')"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 3H19L22 7.056V13H19.965C19.8461 13.8343 19.4302 14.5977 18.7937 15.1499C18.1571 15.7022 17.3427 16.0063 16.5 16.0063C15.6573 16.0063 14.8429 15.7022 14.2063 15.1499C13.5698 14.5977 13.1539 13.8343 13.035 13H7.965C7.84612 13.8343 7.43021 14.5977 6.79368 15.1499C6.15714 15.7022 5.34272 16.0063 4.5 16.0063C3.65728 16.0063 2.84286 15.7022 2.20632 15.1499C1.56979 14.5977 1.15388 13.8343 1.035 13H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V3ZM16 5V8H20V7.715L17.992 5H16Z"
              fill="white"
            />
          </svg>

          <h1>{{ metrics.successFullShipments }}</h1>
          <p>Shipments</p>
        </div>
      </div>
    </div>

    <div class="tracking_faq" @click="routeToPage('resources')">
      <div>
        <h1>Frequently Asked Questions</h1>

        <p>Get answers to questions people commonly ask.</p>
      </div>
      <div>
        <svg
          width="455"
          height="265"
          viewBox="0 0 455 265"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M317.719 30.3781H358.102C360.306 30.3781 362.42 31.2538 363.979 32.8125C365.538 34.3713 366.413 36.4853 366.413 38.6897V116.966C366.413 118.711 365.72 120.384 364.487 121.617C363.253 122.851 361.58 123.544 359.835 123.544H315.984C314.24 123.544 312.566 122.851 311.333 121.617C310.099 120.384 309.406 118.711 309.406 116.966V38.6897C309.406 37.5981 309.621 36.5172 310.039 35.5088C310.457 34.5003 311.069 33.584 311.841 32.8121C312.613 32.0403 313.529 31.4281 314.538 31.0105C315.546 30.5928 316.627 30.378 317.719 30.3781Z"
            fill="#CEE4ED"
          />
          <path
            d="M425.104 30.3781H384.887C380.251 30.3781 376.492 34.1367 376.492 38.7731V115.149C376.492 119.785 380.251 123.544 384.887 123.544H425.104C429.741 123.544 433.499 119.785 433.499 115.149V38.7731C433.499 34.1367 429.741 30.3781 425.104 30.3781Z"
            fill="#CEE4ED"
          />
          <path
            d="M337.562 59.4387L349.868 42.8733"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M309.07 97.8007L329.736 69.9811"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M304.461 258.628V38.574C304.462 34.9187 305.915 31.4135 308.5 28.8288C311.085 26.2441 314.59 24.7914 318.245 24.7899H424.665C428.322 24.7899 431.829 26.2428 434.415 28.8288C437.001 31.4149 438.454 34.9223 438.454 38.5796V258.628"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M371.453 24.7899V258.387"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M414.125 57.7917L426.506 41.1262"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M402.602 83.3649L421.598 57.7917"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M408.797 64.9823L411.287 61.6317"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M383.695 98.7693L403.798 71.707"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M179.423 30.3781H21.4696C15.0963 30.3781 9.92969 35.5447 9.92969 41.9181V112.004C9.92969 118.377 15.0963 123.544 21.4696 123.544H179.423C185.796 123.544 190.962 118.377 190.962 112.004V41.9181C190.962 35.5447 185.796 30.3781 179.423 30.3781Z"
            fill="#CEE4ED"
          />
          <path
            d="M152.547 63.5211L167.383 43.5483"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M133.094 101.742L161.486 63.5211"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M116.062 112.63L146.809 71.2401"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M50.7422 106.982L66.3857 85.9227"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M38.6484 111.503L74.4504 63.3077"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M234.806 130.922H240.7C246.145 130.922 251.367 133.086 255.218 136.936C259.069 140.787 261.232 146.009 261.232 151.455V168.29C261.232 169.451 260.771 170.563 259.95 171.384C259.13 172.204 258.017 172.665 256.857 172.665H218.648C217.488 172.665 216.375 172.204 215.555 171.384C214.734 170.563 214.273 169.451 214.273 168.29V151.455C214.273 146.009 216.437 140.787 220.287 136.936C224.138 133.086 229.36 130.922 234.806 130.922Z"
            fill="#BDDCE8"
          />
          <path
            d="M167.661 130.922H173.554C179 130.922 184.222 133.086 188.073 136.936C191.923 140.787 194.087 146.009 194.087 151.455V168.29C194.087 169.451 193.626 170.563 192.805 171.384C191.985 172.204 190.872 172.665 189.712 172.665H151.508C150.347 172.665 149.235 172.204 148.414 171.384C147.594 170.563 147.133 169.451 147.133 168.29V151.455C147.133 146.01 149.295 140.788 153.145 136.938C156.995 133.087 162.216 130.924 167.661 130.922Z"
            fill="#7EBAD2"
          />
          <path
            d="M10.4922 189.956H277.134C277.145 188.437 276.855 186.932 276.282 185.526C275.708 184.12 274.862 182.842 273.792 181.764C272.722 180.687 271.45 179.832 270.048 179.249C268.646 178.665 267.143 178.365 265.624 178.366H22.0811C19.0074 178.366 16.0597 179.587 13.8864 181.761C11.7131 183.934 10.4922 186.882 10.4922 189.956Z"
            fill="#BDDCE8"
          />
          <path
            d="M277.15 189.982H11.3984"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M100.508 130.922H106.402C111.847 130.922 117.069 133.086 120.92 136.936C124.771 140.787 126.934 146.009 126.934 151.455V167.895C126.934 169.16 126.431 170.374 125.537 171.268C124.642 172.163 123.429 172.665 122.164 172.665H84.7462C83.4812 172.665 82.2681 172.163 81.3736 171.268C80.4791 170.374 79.9766 169.16 79.9766 167.895V151.455C79.9766 146.009 82.1396 140.787 85.9899 136.937C89.8402 133.086 95.0623 130.923 100.508 130.922Z"
            fill="#BDDCE8"
          />
          <path
            d="M33.3603 130.922H39.2542C41.951 130.922 44.6215 131.452 47.1133 132.484C49.605 133.515 51.8691 135.028 53.7763 136.934C55.6834 138.841 57.1963 141.105 58.2285 143.596C59.2607 146.087 59.7919 148.758 59.7919 151.455V167.895C59.7919 169.16 59.2894 170.374 58.3949 171.268C57.5005 172.163 56.2873 172.665 55.0223 172.665H17.5978C16.3328 172.665 15.1196 172.163 14.2251 171.268C13.3306 170.374 12.8281 169.16 12.8281 167.895V151.455C12.8281 146.009 14.9913 140.787 18.8419 136.936C22.6924 133.086 27.9148 130.922 33.3603 130.922Z"
            fill="#BDDCE8"
          />
          <path
            d="M259.105 54.3121H207.953V113.407H259.105V54.3121Z"
            fill="#FED4C7"
          />
          <path
            d="M212.977 68.6356L218.568 76.0464H226.387L238.219 90.678V101.062L229.626 107.46"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M229.947 108.286C230.531 108.286 231.004 107.813 231.004 107.23C231.004 106.647 230.531 106.174 229.947 106.174C229.364 106.174 228.891 106.647 228.891 107.23C228.891 107.813 229.364 108.286 229.947 108.286Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M238.26 101.899C238.843 101.899 239.316 101.426 239.316 100.842C239.316 100.259 238.843 99.7858 238.26 99.7858C237.676 99.7858 237.203 100.259 237.203 100.842C237.203 101.426 237.676 101.899 238.26 101.899Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M238.26 92.158C238.843 92.158 239.316 91.685 239.316 91.1016C239.316 90.5181 238.843 90.0451 238.26 90.0451C237.676 90.0451 237.203 90.5181 237.203 91.1016C237.203 91.685 237.676 92.158 238.26 92.158Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M226.603 77.3064C227.187 77.3064 227.66 76.8334 227.66 76.2499C227.66 75.6664 227.187 75.1934 226.603 75.1934C226.02 75.1934 225.547 75.6664 225.547 76.2499C225.547 76.8334 226.02 77.3064 226.603 77.3064Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M218.611 77.3064C219.195 77.3064 219.668 76.8334 219.668 76.2499C219.668 75.6664 219.195 75.1934 218.611 75.1934C218.028 75.1934 217.555 75.6664 217.555 76.2499C217.555 76.8334 218.028 77.3064 218.611 77.3064Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M213.033 69.1616C213.616 69.1616 214.089 68.6886 214.089 68.1051C214.089 67.5216 213.616 67.0486 213.033 67.0486C212.45 67.0486 211.977 67.5216 211.977 68.1051C211.977 68.6886 212.45 69.1616 213.033 69.1616Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M249.448 60.0014L242.836 65.5495V78.166L234.006 86.0717H224.207L217.445 91.5252"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M217.502 92.5818C218.085 92.5818 218.558 92.1088 218.558 91.5253C218.558 90.9419 218.085 90.4689 217.502 90.4689C216.918 90.4689 216.445 90.9419 216.445 91.5253C216.445 92.1088 216.918 92.5818 217.502 92.5818Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M224.525 87.1527C225.109 87.1527 225.582 86.6797 225.582 86.0962C225.582 85.5127 225.109 85.0397 224.525 85.0397C223.942 85.0397 223.469 85.5127 223.469 86.0962C223.469 86.6797 223.942 87.1527 224.525 87.1527Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M234.267 87.1527C234.851 87.1527 235.324 86.6797 235.324 86.0962C235.324 85.5127 234.851 85.0397 234.267 85.0397C233.684 85.0397 233.211 85.5127 233.211 86.0962C233.211 86.6797 233.684 87.1527 234.267 87.1527Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M242.736 79.0078C243.32 79.0078 243.793 78.5348 243.793 77.9514C243.793 77.3679 243.32 76.8949 242.736 76.8949C242.153 76.8949 241.68 77.3679 241.68 77.9514C241.68 78.5348 242.153 79.0078 242.736 79.0078Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M242.736 66.8706C243.32 66.8706 243.793 66.3976 243.793 65.8142C243.793 65.2307 243.32 64.7577 242.736 64.7577C242.153 64.7577 241.68 65.2307 241.68 65.8142C241.68 66.3976 242.153 66.8706 242.736 66.8706Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M249.596 60.6431C250.179 60.6431 250.652 60.1701 250.652 59.5866C250.652 59.0031 250.179 58.5302 249.596 58.5302C249.012 58.5302 248.539 59.0031 248.539 59.5866C248.539 60.1701 249.012 60.6431 249.596 60.6431Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linejoin="round"
          />
          <path
            d="M229.625 61.3826V70.1813L244.463 89.1099H250.721"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M229.58 61.4983C230.163 61.4983 230.636 61.0253 230.636 60.4418C230.636 59.8584 230.163 59.3854 229.58 59.3854C228.996 59.3854 228.523 59.8584 228.523 60.4418C228.523 61.0253 228.996 61.4983 229.58 61.4983Z"
            fill="#F5BBBB"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M229.58 71.2401C230.163 71.2401 230.636 70.7671 230.636 70.1836C230.636 69.6001 230.163 69.1271 229.58 69.1271C228.996 69.1271 228.523 69.6001 228.523 70.1836C228.523 70.7671 228.996 71.2401 229.58 71.2401Z"
            fill="#F5BBBB"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M243.947 89.924C244.531 89.924 245.004 89.451 245.004 88.8675C245.004 88.284 244.531 87.811 243.947 87.811C243.364 87.811 242.891 88.284 242.891 88.8675C242.891 89.451 243.364 89.924 243.947 89.924Z"
            fill="#F5BBBB"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M252.103 89.924C252.687 89.924 253.16 89.451 253.16 88.8675C253.16 88.284 252.687 87.811 252.103 87.811C251.52 87.811 251.047 88.284 251.047 88.8675C251.047 89.451 251.52 89.924 252.103 89.924Z"
            fill="#F5BBBB"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M237.675 48.4437H259.102V38.375H237.675V48.4437Z"
            fill="#FFC200"
          />
          <path
            d="M255.284 41.6112H241.656"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M255.286 44.0088H241.711"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M250.819 45.926H246.406"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M208.152 48.4437H229.953V31.8639L208.152 31.8639V48.4437Z"
            fill="#7EBAD2"
          />
          <path
            d="M218.056 35.2979H210.992V48.4437H218.056V35.2979Z"
            fill="#FED4C7"
          />
          <path
            d="M215.219 37.3764H225.594"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M215.219 39.5438H225.594"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M223.915 45.926C225.107 45.926 226.073 44.9601 226.073 43.7686C226.073 42.5771 225.107 41.6112 223.915 41.6112C222.724 41.6112 221.758 42.5771 221.758 43.7686C221.758 44.9601 222.724 45.926 223.915 45.926Z"
            fill="#FFC200"
          />
          <path
            d="M343.168 245.257C343.168 245.257 330.479 258.063 316.023 257.736L277.219 139.523L314.538 138.054L343.168 245.257Z"
            fill="#59221D"
          />
          <path
            d="M336.799 202.219L293.918 244.499C292.598 242.885 283.019 230.71 283.113 214.661L312.27 184.501L296.739 168.087C285.935 156.553 280.916 150.687 277.219 139.523L317.188 128.275L341.189 170.353C344.073 175.406 345.206 181.27 344.412 187.033C343.618 192.796 340.941 198.135 336.799 202.219Z"
            fill="#A74602"
          />
          <path
            d="M303.785 133.329C303.589 134.791 301.005 152.066 287.748 158.089C286.492 156.578 285.36 155.124 284.328 153.677C297.984 146.402 302.955 132.489 302.955 132.489C303.224 132.779 303.501 133.058 303.785 133.329Z"
            fill="#59221D"
          />
          <path
            d="M311.789 184.501L316.587 179.814"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M356.16 261.713V262H317.748L315.781 256.058C333.22 256.159 342.805 245.065 342.805 245.065L353.204 254.899C355.054 256.689 356.117 259.14 356.16 261.713Z"
            fill="white"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M317.055 259.911H355.923"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M347.828 259.642C347.82 258.343 348.108 257.059 348.67 255.888C349.233 254.716 350.055 253.689 351.074 252.883"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M340.539 250.694L344.863 246.579C345.053 246.398 345.306 246.298 345.569 246.301C345.832 246.304 346.083 246.41 346.268 246.596C346.449 246.776 346.553 247.018 346.562 247.272C346.571 247.527 346.483 247.776 346.315 247.968"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M342.352 252.259L346.674 248.145C346.864 247.963 347.118 247.864 347.38 247.867C347.643 247.87 347.894 247.976 348.08 248.161C348.261 248.341 348.367 248.583 348.376 248.838C348.384 249.093 348.295 249.342 348.127 249.534"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M279.366 261.985L277.202 262L277.148 220.384L283.109 214.935C282.841 232.372 293.838 244.455 293.838 244.455L282.292 260.744C281.91 261.133 281.454 261.443 280.952 261.656C280.45 261.869 279.911 261.981 279.366 261.985Z"
            fill="white"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M279.469 251.836C280.728 252.154 281.899 252.756 282.89 253.596C283.882 254.435 284.67 255.489 285.193 256.679"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M279.406 218.316V261.985"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M287.938 244.246L291.838 248.767C292.01 248.966 292.098 249.224 292.082 249.486C292.065 249.749 291.947 249.994 291.751 250.17C291.562 250.341 291.315 250.434 291.06 250.43C290.805 250.426 290.561 250.325 290.377 250.149"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M286.281 245.973L290.181 250.494C290.354 250.693 290.442 250.951 290.425 251.213C290.409 251.476 290.29 251.721 290.095 251.897C289.906 252.068 289.659 252.161 289.404 252.157C289.149 252.153 288.905 252.052 288.721 251.876"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M322.891 94.5956V127.967C322.891 129.091 323.338 130.17 324.133 130.965C324.929 131.761 326.007 132.208 327.132 132.208C328.257 132.208 329.336 131.761 330.131 130.966C330.927 130.17 331.373 129.091 331.373 127.967V109.11"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M338.744 102.132L335.433 110.799C332.153 119.199 324.297 124.728 315.279 124.728L324.663 141.409C324.663 141.409 310.628 140.771 302.934 132.486C302.934 132.486 296.262 151.158 277.148 156.55L277.203 139.519V90.0072C277.203 75.8428 288.689 64.3629 302.854 64.3696H302.911C307.406 64.3695 311.732 66.0888 315.001 69.1751C318.27 72.2614 320.235 76.4811 320.494 80.9694L320.952 88.9263L321.115 91.7877C321.115 91.7877 321.456 103.051 338.744 102.132Z"
            fill="#98C8DB"
          />
          <path
            d="M279.959 78.4284V155.632C279.8 155.692 279.64 155.751 279.477 155.807V79.4282C279.508 79.3625 279.538 79.2969 279.568 79.2313C279.643 79.0701 279.719 78.9088 279.799 78.7498C279.85 78.6408 279.904 78.5352 279.959 78.4284Z"
            fill="#CEE4ED"
          />
          <path
            d="M282.896 73.8945V154.387C282.737 154.463 282.576 154.539 282.414 154.61V74.5128C282.572 74.306 282.738 74.1003 282.896 73.8945Z"
            fill="#CEE4ED"
          />
          <path
            d="M285.833 70.8285V152.819C285.674 152.915 285.514 153.009 285.352 153.102V71.2666C285.511 71.1143 285.671 70.9719 285.833 70.8285Z"
            fill="#CEE4ED"
          />
          <path
            d="M288.755 68.5799V150.873C288.596 150.984 288.435 151.105 288.273 151.221V68.9046C288.434 68.7949 288.594 68.6867 288.755 68.5799Z"
            fill="#CEE4ED"
          />
          <path
            d="M291.694 66.9141V148.497C291.535 148.639 291.374 148.781 291.211 148.921V67.1532C291.352 67.0809 291.495 67.0097 291.637 66.9419C291.655 66.9315 291.674 66.9222 291.694 66.9141Z"
            fill="#CEE4ED"
          />
          <path
            d="M294.63 65.7142V145.595C294.474 145.769 294.313 145.941 294.148 146.112V65.8877C294.305 65.8243 294.469 65.7687 294.63 65.7142Z"
            fill="#CEE4ED"
          />
          <path
            d="M297.56 64.9146V142.031C297.403 142.244 297.242 142.457 297.078 142.672V65.0203C297.238 64.9836 297.398 64.948 297.56 64.9146Z"
            fill="#CEE4ED"
          />
          <path
            d="M300.495 64.4753V137.538C300.433 137.65 300.367 137.761 300.301 137.872C300.208 138.029 300.113 138.19 300.016 138.353V64.5243C300.174 64.5065 300.334 64.4898 300.495 64.4753Z"
            fill="#CEE4ED"
          />
          <path
            d="M303.427 64.3762V133C303.263 132.837 303.102 132.666 302.945 132.502V64.3696C303.107 64.3696 303.267 64.3718 303.427 64.3762Z"
            fill="#CEE4ED"
          />
          <path
            d="M306.364 64.7077V135.453L306.291 135.402C306.154 135.305 306.018 135.206 305.883 135.107V64.6187C306.044 64.6465 306.206 64.6765 306.364 64.7077Z"
            fill="#CEE4ED"
          />
          <path
            d="M309.294 65.5628V137.232C309.133 137.149 308.973 137.062 308.812 136.974V65.3838C308.975 65.4427 309.135 65.5006 309.294 65.5628Z"
            fill="#CEE4ED"
          />
          <path
            d="M312.232 67.033V138.583C312.071 138.518 311.91 138.451 311.75 138.385V66.7428C311.861 66.8073 311.972 66.874 312.084 66.9419C312.134 66.9719 312.184 66.9997 312.232 67.033Z"
            fill="#CEE4ED"
          />
          <path
            d="M315.161 69.3283V139.618C315.001 139.568 314.841 139.518 314.68 139.464V68.8768C314.791 68.9746 314.895 69.0736 315.003 69.1748C315.056 69.2237 315.109 69.2749 315.161 69.3283Z"
            fill="#CEE4ED"
          />
          <path
            d="M318.091 129.729V140.405C317.932 140.368 317.772 140.33 317.609 140.294V128.874L317.643 128.932L317.913 129.413L318.091 129.729Z"
            fill="#CEE4ED"
          />
          <path
            d="M318.091 73.0494V124.546C317.931 124.567 317.771 124.587 317.609 124.604V72.2787C317.732 72.4641 317.852 72.6531 317.969 72.8459C318.011 72.9126 318.052 72.9805 318.091 73.0494Z"
            fill="#CEE4ED"
          />
          <path
            d="M321.028 90.5566V123.954C320.869 123.999 320.708 124.041 320.547 124.081V90.5566H321.028Z"
            fill="#CEE4ED"
          />
          <path
            d="M321.028 134.947V140.991C320.872 140.967 320.71 140.939 320.547 140.91V134.091L321.015 134.924L321.028 134.947Z"
            fill="#CEE4ED"
          />
          <path
            d="M323.966 97.8752V122.908C323.875 122.949 323.782 122.989 323.69 123.027L323.484 123.112V97.3403C323.635 97.5193 323.795 97.6984 323.966 97.8752Z"
            fill="#CEE4ED"
          />
          <path
            d="M323.966 140.158V141.362C323.825 141.349 323.663 141.335 323.484 141.315V139.302L323.966 140.158Z"
            fill="#CEE4ED"
          />
          <path
            d="M326.889 100.085V121.325C326.73 121.43 326.57 121.529 326.406 121.628V99.8057L326.547 99.8891C326.661 99.9581 326.772 100.021 326.889 100.085Z"
            fill="#CEE4ED"
          />
          <path
            d="M329.817 101.283V119.053C329.661 119.2 329.498 119.344 329.336 119.485V101.13C329.494 101.184 329.66 101.234 329.817 101.283Z"
            fill="#CEE4ED"
          />
          <path
            d="M332.755 101.919V115.738C332.599 115.961 332.438 116.172 332.273 116.383V101.841C332.431 101.866 332.591 101.892 332.755 101.919Z"
            fill="#CEE4ED"
          />
          <path
            d="M335.685 102.168V110.127L335.428 110.794C335.372 110.935 335.317 111.077 335.258 111.216C335.24 111.259 335.222 111.302 335.203 111.346V102.141C335.362 102.156 335.522 102.161 335.685 102.168Z"
            fill="#CEE4ED"
          />
          <path
            d="M338.62 102.141V102.45L338.466 102.848L338.133 103.711V102.16C338.296 102.154 338.456 102.147 338.62 102.141Z"
            fill="#CEE4ED"
          />
          <path
            d="M317.906 129.414H277.195V128.932H317.636L317.906 129.414Z"
            fill="#CEE4ED"
          />
          <path
            d="M316.246 126.462H277.195V125.981H315.974L316.246 126.462Z"
            fill="#CEE4ED"
          />
          <path
            d="M323.674 123.027L323.469 123.113C323.127 123.255 322.78 123.387 322.431 123.51H277.195V123.027H323.674Z"
            fill="#CEE4ED"
          />
          <path
            d="M328.624 120.075C328.419 120.242 328.209 120.402 327.996 120.558H277.195V120.075H328.624Z"
            fill="#CEE4ED"
          />
          <path
            d="M331.678 117.123C331.54 117.286 331.4 117.448 331.258 117.604H277.211V117.123H331.678Z"
            fill="#CEE4ED"
          />
          <path
            d="M333.773 114.171C333.676 114.334 333.577 114.495 333.476 114.653H277.195V114.171H333.773Z"
            fill="#CEE4ED"
          />
          <path
            d="M335.255 111.22C335.238 111.263 335.22 111.307 335.201 111.35C335.153 111.468 335.102 111.585 335.049 111.702H277.195V111.22H335.255Z"
            fill="#CEE4ED"
          />
          <path
            d="M336.393 108.267L336.208 108.749H277.195V108.267H336.393Z"
            fill="#CEE4ED"
          />
          <path
            d="M337.52 105.316L337.336 105.798H277.195V105.316H337.52Z"
            fill="#CEE4ED"
          />
          <path
            d="M338.647 102.365L338.616 102.448L338.463 102.846H277.195V102.365H338.647Z"
            fill="#CEE4ED"
          />
          <path
            d="M326.545 99.8935H277.195V99.412H325.777C325.976 99.5477 326.185 99.6822 326.401 99.8101L326.545 99.8935Z"
            fill="#CEE4ED"
          />
          <path
            d="M323.15 96.9422H277.195V96.4606H322.805C322.914 96.6208 323.027 96.7809 323.15 96.9422Z"
            fill="#CEE4ED"
          />
          <path
            d="M321.577 93.9907H277.195V93.5092H321.429C321.468 93.6637 321.519 93.8239 321.577 93.9907Z"
            fill="#CEE4ED"
          />
          <path
            d="M321.064 91.0382H277.195V90.5566H321.039L321.064 91.0382Z"
            fill="#CEE4ED"
          />
          <path
            d="M320.911 88.0868H277.281C277.293 87.9255 277.306 87.7654 277.32 87.6052H320.888L320.911 88.0868Z"
            fill="#CEE4ED"
          />
          <path
            d="M320.731 85.1353H277.664C277.695 84.974 277.727 84.8139 277.761 84.6537H320.703L320.731 85.1353Z"
            fill="#CEE4ED"
          />
          <path
            d="M320.562 82.1828H278.414C278.465 82.0216 278.519 81.8603 278.574 81.7013H320.546V81.8258L320.562 82.1828Z"
            fill="#CEE4ED"
          />
          <path
            d="M320.308 79.2324H279.57C279.646 79.0712 279.722 78.9099 279.802 78.7509H320.224C320.254 78.9099 320.282 79.0701 320.308 79.2324Z"
            fill="#CEE4ED"
          />
          <path
            d="M319.578 76.2799H281.18C281.282 76.1186 281.387 75.9574 281.493 75.7983H319.402C319.465 75.9585 319.523 76.1186 319.578 76.2799Z"
            fill="#CEE4ED"
          />
          <path
            d="M318.247 73.3274H283.367C283.505 73.1651 283.646 73.0045 283.79 72.8459H317.965C318.007 72.9126 318.048 72.9804 318.087 73.0494C318.142 73.1406 318.195 73.2329 318.247 73.3274Z"
            fill="#CEE4ED"
          />
          <path
            d="M316.155 70.3759H286.352C286.545 70.2124 286.743 70.0512 286.942 69.8943H315.719C315.867 70.0512 316.013 70.2124 316.155 70.3759Z"
            fill="#CEE4ED"
          />
          <path
            d="M312.826 67.4234H290.695C290.865 67.3311 291.038 67.241 291.21 67.1532C291.351 67.0809 291.494 67.0097 291.636 66.9419H312.077C312.126 66.9719 312.176 67.0019 312.224 67.0331C312.432 67.1576 312.629 67.2878 312.826 67.4234Z"
            fill="#CEE4ED"
          />
          <path
            d="M286.153 152.629L285.839 152.817C285.681 152.913 285.52 153.007 285.358 153.1V153.111H277.172V152.629H286.153Z"
            fill="#CEE4ED"
          />
          <path
            d="M290.311 149.68C290.113 149.841 289.912 150.002 289.707 150.161H277.172V149.68H290.311Z"
            fill="#CEE4ED"
          />
          <path
            d="M293.555 146.727C293.396 146.889 293.236 147.05 293.072 147.209H277.18V146.727H293.555Z"
            fill="#CEE4ED"
          />
          <path
            d="M296.203 143.776C296.072 143.936 295.937 144.097 295.802 144.257H277.188V143.776H296.203Z"
            fill="#CEE4ED"
          />
          <path
            d="M298.416 140.824C298.305 140.983 298.193 141.145 298.082 141.306H277.195V140.824H298.416Z"
            fill="#CEE4ED"
          />
          <path
            d="M324.605 141.306H323.382C322.773 141.24 321.972 141.14 321.031 140.987C320.874 140.962 320.713 140.935 320.55 140.906C320.386 140.877 320.248 140.853 320.094 140.824H324.331L324.605 141.306Z"
            fill="#CEE4ED"
          />
          <path
            d="M300.292 137.872C300.198 138.029 300.104 138.19 300.006 138.353H277.195V137.872H300.292Z"
            fill="#CEE4ED"
          />
          <path
            d="M322.944 138.353H311.672C311.31 138.2 310.948 138.039 310.586 137.872H322.673L322.944 138.353Z"
            fill="#CEE4ED"
          />
          <path
            d="M301.868 134.919C301.793 135.074 301.713 135.234 301.631 135.402H277.195V134.919H301.868Z"
            fill="#CEE4ED"
          />
          <path
            d="M321.285 135.402H306.289C306.152 135.305 306.016 135.206 305.881 135.107L305.633 134.919H321.014L321.027 134.943L321.285 135.402Z"
            fill="#CEE4ED"
          />
          <path
            d="M319.614 132.449H277.195V131.967H319.343L319.614 132.449Z"
            fill="#CEE4ED"
          />
          <path
            d="M293.82 66.0066L297.701 61.3893C297.884 61.172 298.115 61.001 298.376 60.8901C298.637 60.7791 298.921 60.7315 299.204 60.751L309.648 61.466C309.951 61.487 310.244 61.5839 310.499 61.748C310.755 61.9121 310.965 62.1379 311.11 62.4046L314.446 68.5543C314.446 68.5543 309.82 64.3897 302.126 64.5409C297.162 64.6376 293.82 66.0066 293.82 66.0066Z"
            fill="#7EBAD2"
          />
          <path
            d="M314.561 68.4343C314.561 68.4343 309.656 64.3897 301.698 64.542C296.559 64.6388 293.102 66.0077 293.102 66.0077"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M338.457 102.416C318.996 102.606 320.979 90.818 320.979 90.818L310.922 101.13"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M291.875 97.1901C291.875 97.1901 297.573 124.867 316.276 125.064"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M310.875 59.2795L310.188 61.5892C310.014 61.5205 309.832 61.4789 309.646 61.4658L299.198 60.7496C299.147 60.7496 299.097 60.7496 299.047 60.7496L303.773 51.9309L310.875 59.2795Z"
            fill="#FFC200"
          />
          <path
            d="M310.882 59.2795L310.195 61.5892C310.022 61.5205 309.839 61.4789 309.653 61.4658L307.608 61.3279C306.884 60.6195 306.516 59.6531 306.532 58.5477L306.551 57.1098L306.859 55.1192L310.882 59.2795Z"
            fill="#A74602"
          />
          <path
            d="M307.589 51.2537C307.589 51.2537 311.786 51.9655 314.304 49.9193C317.201 47.5706 316.288 43.7439 313.756 43.4881C310.948 43.2046 310.473 45.8924 310.682 46.1671C310.682 46.1671 309.819 46.6942 307.638 46.2783C305.981 45.9636 304.666 45.6411 302.996 47.009C301.254 48.4369 301.572 52.2969 304.383 53.3166C306.095 53.9438 307.589 51.2537 307.589 51.2537Z"
            fill="#A74602"
          />
          <path
            d="M307.781 51.276C312.675 51.792 314.541 49.7035 314.541 49.7035C315.833 51.1492 315.653 55.6653 315.653 55.6653C313.293 56.7462 313.167 59.8723 313.167 59.8723C312.255 59.9373 311.339 59.934 310.427 59.8623C309.535 59.7928 308.702 59.3863 308.099 58.7253C307.496 58.0644 307.167 57.1985 307.179 56.3036L307.19 55.4985H306.962C306.678 55.505 306.395 55.4546 306.131 55.3505C305.867 55.2463 305.626 55.0903 305.423 54.8918C305.22 54.6932 305.058 54.4561 304.948 54.1943C304.838 53.9325 304.781 53.6513 304.781 53.3672C304.781 53.0832 304.838 52.802 304.948 52.5402C305.058 52.2783 305.22 52.0412 305.423 51.8427C305.626 51.6441 305.867 51.4882 306.131 51.384C306.395 51.2798 306.678 51.2294 306.962 51.2359L307.781 51.276Z"
            fill="#FFC200"
          />
          <path
            d="M311.75 52.0555C311.75 52.0555 312.905 51.3026 313.986 51.4005"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M313.044 53.7837C313.254 53.7837 313.425 53.5103 313.425 53.1732C313.425 52.836 313.254 52.5626 313.044 52.5626C312.834 52.5626 312.664 52.836 312.664 53.1732C312.664 53.5103 312.834 53.7837 313.044 53.7837Z"
            fill="#59221D"
          />
          <path
            d="M312.32 56.6406C312.32 56.6406 312.343 57.369 313.565 57.4269"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M306.258 53.29C306.258 53.1661 306.282 53.0434 306.329 52.9289C306.377 52.8144 306.446 52.7104 306.534 52.6228C306.621 52.5352 306.725 52.4658 306.84 52.4184C306.954 52.3711 307.077 52.3468 307.201 52.3469"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M307.299 50.6955H306.453C305.193 50.6955 304.172 51.7167 304.172 52.9763V53.8226C304.172 55.0823 305.193 56.1035 306.453 56.1035H307.299C308.559 56.1035 309.58 55.0823 309.58 53.8226V52.9763C309.58 51.7167 308.559 50.6955 307.299 50.6955Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M307.039 50.5899V45.9814C307.039 45.7965 307.113 45.6192 307.243 45.4884C307.374 45.3576 307.551 45.2842 307.736 45.2842C307.921 45.2842 308.099 45.3576 308.229 45.4884C308.36 45.6192 308.434 45.7965 308.434 45.9814"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M306.75 56.1647V59.6521C306.75 61.1079 307.175 62.5321 307.973 63.75C308.77 64.9678 309.906 65.9264 311.241 66.508C318.304 69.5885 322.871 76.8025 322.871 84.5091V90.9591"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M339.075 101.687C321.786 102.606 321.336 91.2338 321.336 91.2338L336.372 67.4589C336.965 66.5141 337.788 65.7353 338.764 65.1954C339.74 64.6555 340.837 64.3721 341.953 64.3718H343.907L343.581 64.9745C343.46 65.1975 343.4 65.4478 343.405 65.7011C343.411 65.9543 343.482 66.2018 343.612 66.4194C343.742 66.6369 343.926 66.8171 344.146 66.9422C344.366 67.0673 344.615 67.133 344.868 67.1331H346.267C346.534 67.1333 346.795 67.0609 347.023 66.9237C347.251 66.7866 347.437 66.5898 347.562 66.3546L351.096 59.6411L352.28 63.3042C352.663 64.4851 352.63 65.7611 352.187 66.9207L339.075 101.687Z"
            fill="#FFC200"
          />
          <path
            d="M350.98 59.8457L347.561 66.3491C347.437 66.5845 347.25 66.7815 347.022 66.9187C346.794 67.0559 346.533 67.1281 346.267 67.1275H344.821C344.57 67.1272 344.324 67.0626 344.106 66.94C343.888 66.8173 343.705 66.6407 343.574 66.4271C343.443 66.2134 343.37 65.9698 343.36 65.7196C343.351 65.4693 343.406 65.2209 343.52 64.9979L346.189 59.8568C346.344 59.5559 346.579 59.3034 346.868 59.1269C347.156 58.9504 347.488 58.8567 347.827 58.856H350.385C350.501 58.8563 350.615 58.8867 350.716 58.944C350.817 59.0013 350.901 59.0837 350.961 59.1832C351.021 59.2827 351.054 59.396 351.057 59.512C351.06 59.6281 351.034 59.743 350.98 59.8457Z"
            fill="#59221D"
          />
          <path
            d="M343.4 90.3342C343.4 90.3342 344.964 102.122 338.217 102.176C320.571 102.299 321.111 91.9022 321.111 91.9022C321.111 87.1604 325.307 84.8484 325.307 84.8484C325.307 84.8484 325.557 89.2844 332.37 91.2517C339.182 93.2189 343.4 90.3342 343.4 90.3342Z"
            fill="#7EBAD2"
          />
          <path
            d="M277.156 19.1805H9.92969"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M277.156 3V261.892"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M249.242 19.1805L277.157 44.5469"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M247.57 262H451.842"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M246.901 173.337H228.575C220.839 173.337 215.28 165.895 217.475 158.476C218.768 154.105 221.441 150.269 225.093 147.541C228.745 144.813 233.182 143.339 237.741 143.34C242.299 143.34 246.735 144.814 250.387 147.542C254.04 150.27 256.712 154.105 258.006 158.476C260.197 165.895 254.637 173.337 246.901 173.337Z"
            fill="#FFC200"
          />
          <path
            d="M257.65 179.051C257.819 184.807 253.896 189.727 249.265 189.727H226.065C223.749 189.727 221.653 188.532 220.136 186.602C218.619 184.671 217.68 182.003 217.68 179.056C217.68 176.721 218.271 174.553 219.274 172.795C220.765 169.509 224.516 167.176 228.873 167.481C230.205 167.562 231.514 167.868 232.744 168.386H242.419C243.649 167.868 244.958 167.562 246.29 167.481C249.849 167.232 253.003 168.743 254.853 171.096C255.482 171.828 257.512 174.387 257.65 179.051Z"
            fill="#59221D"
          />
          <path
            d="M252.119 201.269H239.185L238.728 177.508C238.636 172.67 242.749 168.734 247.563 169.203C249.566 169.401 251.424 170.336 252.776 171.826C254.129 173.316 254.88 175.256 254.883 177.268L252.119 201.269Z"
            fill="#A74602"
          />
          <path
            d="M220.594 177.268C220.594 172.648 224.34 168.88 229.033 169.261C233.235 169.601 236.435 173.181 236.435 177.395V201.27H223.147L220.594 177.268Z"
            fill="#A74602"
          />
          <path
            d="M252.083 201.269L250.913 210.643C249.851 210.906 243.776 212.276 239.348 210.459L239.18 201.269H252.083Z"
            fill="#7EBAD2"
          />
          <path
            d="M240.96 209.933C241.396 209.933 241.749 209.58 241.749 209.144C241.749 208.709 241.396 208.356 240.96 208.356C240.525 208.356 240.172 208.709 240.172 209.144C240.172 209.58 240.525 209.933 240.96 209.933Z"
            fill="#CEE4ED"
          />
          <path
            d="M244.718 208.072C245.154 208.072 245.507 207.719 245.507 207.284C245.507 206.848 245.154 206.495 244.718 206.495C244.283 206.495 243.93 206.848 243.93 207.284C243.93 207.719 244.283 208.072 244.718 208.072Z"
            fill="#CEE4ED"
          />
          <path
            d="M242.062 206.872C242.497 206.872 242.85 206.519 242.85 206.084C242.85 205.648 242.497 205.295 242.062 205.295C241.626 205.295 241.273 205.648 241.273 206.084C241.273 206.519 241.626 206.872 242.062 206.872Z"
            fill="#CEE4ED"
          />
          <path
            d="M244.718 204.108C245.154 204.108 245.507 203.755 245.507 203.319C245.507 202.884 245.154 202.531 244.718 202.531C244.283 202.531 243.93 202.884 243.93 203.319C243.93 203.755 244.283 204.108 244.718 204.108Z"
            fill="#CEE4ED"
          />
          <path
            d="M240.703 203.635C241.138 203.635 241.491 203.282 241.491 202.847C241.491 202.411 241.138 202.058 240.703 202.058C240.267 202.058 239.914 202.411 239.914 202.847C239.914 203.282 240.267 203.635 240.703 203.635Z"
            fill="#CEE4ED"
          />
          <path
            d="M246.953 209.69C247.388 209.69 247.741 209.337 247.741 208.902C247.741 208.466 247.388 208.113 246.953 208.113C246.517 208.113 246.164 208.466 246.164 208.902C246.164 209.337 246.517 209.69 246.953 209.69Z"
            fill="#CEE4ED"
          />
          <path
            d="M247.562 206.007C247.997 206.007 248.35 205.654 248.35 205.219C248.35 204.783 247.997 204.43 247.562 204.43C247.126 204.43 246.773 204.783 246.773 205.219C246.773 205.654 247.126 206.007 247.562 206.007Z"
            fill="#CEE4ED"
          />
          <path
            d="M250.031 203.795C250.466 203.795 250.819 203.442 250.819 203.007C250.819 202.571 250.466 202.218 250.031 202.218C249.595 202.218 249.242 202.571 249.242 203.007C249.242 203.442 249.595 203.795 250.031 203.795Z"
            fill="#CEE4ED"
          />
          <path
            d="M250.031 208.003C250.466 208.003 250.819 207.65 250.819 207.215C250.819 206.779 250.466 206.426 250.031 206.426C249.595 206.426 249.242 206.779 249.242 207.215C249.242 207.65 249.595 208.003 250.031 208.003Z"
            fill="#CEE4ED"
          />
          <path
            d="M250.343 210.523C250.343 210.612 250.327 210.701 250.297 210.785C249.947 210.859 249.491 210.949 248.956 211.036C248.873 210.939 248.815 210.824 248.786 210.7C248.757 210.577 248.759 210.448 248.791 210.324C248.823 210.201 248.884 210.088 248.969 209.994C249.055 209.899 249.161 209.827 249.281 209.783C249.4 209.739 249.528 209.724 249.654 209.74C249.78 209.756 249.901 209.803 250.005 209.876C250.109 209.948 250.195 210.045 250.253 210.158C250.312 210.271 250.343 210.396 250.343 210.523Z"
            fill="#CEE4ED"
          />
          <path
            d="M243.82 211.324C244.255 211.324 244.608 210.971 244.608 210.535C244.608 210.1 244.255 209.747 243.82 209.747C243.384 209.747 243.031 210.1 243.031 210.535C243.031 210.971 243.384 211.324 243.82 211.324Z"
            fill="#CEE4ED"
          />
          <path
            d="M236.457 210.405C231.553 212.331 224.549 210.716 224.549 210.716L223.148 201.269H236.435L236.457 210.405Z"
            fill="#7EBAD2"
          />
          <path
            d="M225.393 209.975C225.852 209.975 226.224 209.603 226.224 209.144C226.224 208.686 225.852 208.314 225.393 208.314C224.934 208.314 224.562 208.686 224.562 209.144C224.562 209.603 224.934 209.975 225.393 209.975Z"
            fill="#CEE4ED"
          />
          <path
            d="M229.104 208.114C229.563 208.114 229.935 207.743 229.935 207.284C229.935 206.825 229.563 206.453 229.104 206.453C228.645 206.453 228.273 206.825 228.273 207.284C228.273 207.743 228.645 208.114 229.104 208.114Z"
            fill="#CEE4ED"
          />
          <path
            d="M226.534 206.519C226.993 206.519 227.365 206.147 227.365 205.688C227.365 205.229 226.993 204.857 226.534 204.857C226.075 204.857 225.703 205.229 225.703 205.688C225.703 206.147 226.075 206.519 226.534 206.519Z"
            fill="#CEE4ED"
          />
          <path
            d="M229.354 204.107C229.813 204.107 230.185 203.735 230.185 203.276C230.185 202.817 229.813 202.445 229.354 202.445C228.895 202.445 228.523 202.817 228.523 203.276C228.523 203.735 228.895 204.107 229.354 204.107Z"
            fill="#CEE4ED"
          />
          <path
            d="M224.839 203.677C225.297 203.677 225.669 203.305 225.669 202.847C225.669 202.388 225.297 202.016 224.839 202.016C224.38 202.016 224.008 202.388 224.008 202.847C224.008 203.305 224.38 203.677 224.839 203.677Z"
            fill="#CEE4ED"
          />
          <path
            d="M231.503 210.096C231.961 210.096 232.333 209.724 232.333 209.266C232.333 208.807 231.961 208.435 231.503 208.435C231.044 208.435 230.672 208.807 230.672 209.266C230.672 209.724 231.044 210.096 231.503 210.096Z"
            fill="#CEE4ED"
          />
          <path
            d="M232.339 206.049C232.797 206.049 233.169 205.678 233.169 205.219C233.169 204.76 232.797 204.388 232.339 204.388C231.88 204.388 231.508 204.76 231.508 205.219C231.508 205.678 231.88 206.049 232.339 206.049Z"
            fill="#CEE4ED"
          />
          <path
            d="M234.745 203.677C235.204 203.677 235.575 203.305 235.575 202.847C235.575 202.388 235.204 202.016 234.745 202.016C234.286 202.016 233.914 202.388 233.914 202.847C233.914 203.305 234.286 203.677 234.745 203.677Z"
            fill="#CEE4ED"
          />
          <path
            d="M234.932 207.711C235.391 207.711 235.763 207.339 235.763 206.88C235.763 206.421 235.391 206.049 234.932 206.049C234.473 206.049 234.102 206.421 234.102 206.88C234.102 207.339 234.473 207.711 234.932 207.711Z"
            fill="#CEE4ED"
          />
          <path
            d="M234.44 210.977C234.899 210.977 235.271 210.605 235.271 210.146C235.271 209.687 234.899 209.315 234.44 209.315C233.981 209.315 233.609 209.687 233.609 210.146C233.609 210.605 233.981 210.977 234.44 210.977Z"
            fill="#CEE4ED"
          />
          <path
            d="M229.349 210.849C229.349 211.015 229.3 211.177 229.207 211.314C228.702 211.29 228.22 211.252 227.77 211.203C227.715 211.091 227.687 210.969 227.688 210.845C227.688 210.624 227.775 210.413 227.931 210.257C228.087 210.101 228.298 210.014 228.518 210.014C228.739 210.014 228.95 210.101 229.106 210.257C229.261 210.413 229.349 210.624 229.349 210.845V210.849Z"
            fill="#CEE4ED"
          />
          <path
            d="M239.367 210.457V216.891C239.367 217.383 239.476 217.869 239.685 218.315C239.895 218.76 240.201 219.153 240.58 219.467L245.901 223.856C246.405 224.204 247.003 224.391 247.615 224.391H253.285C255.554 224.391 257.012 222.637 255.959 220.626L250.763 210.711C250.763 210.711 244.236 212.458 239.367 210.457Z"
            fill="white"
            stroke="#592D29"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M256.179 221.355C255.677 222.263 254.809 222.912 253.628 222.912H247.608C246.995 222.912 246.397 222.725 245.893 222.377L240.864 218.228C240.393 217.839 240.014 217.352 239.754 216.8C239.494 216.248 239.359 215.645 239.359 215.035"
            stroke="#592D29"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M248.605 211.245L249.939 214.025C250.026 214.207 250.065 214.407 250.053 214.607C250.041 214.808 249.979 215.002 249.872 215.172C249.765 215.342 249.616 215.482 249.44 215.58C249.265 215.677 249.067 215.728 248.866 215.728H247.777C247.308 215.728 246.848 215.601 246.446 215.36C246.043 215.119 245.714 214.774 245.492 214.361L243.891 211.395"
            stroke="#592D29"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M236.28 210.457V216.951C236.28 217.448 236.17 217.938 235.959 218.387C235.747 218.837 235.439 219.234 235.056 219.55L229.688 223.979C229.179 224.331 228.575 224.519 227.957 224.519H222.24C219.949 224.519 218.476 222.749 219.54 220.72L224.784 210.711C224.784 210.711 231.369 212.475 236.28 210.457Z"
            fill="white"
            stroke="#592D29"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M219.305 221.456C219.813 222.373 220.688 223.031 221.879 223.031H227.954C228.573 223.031 229.176 222.843 229.685 222.491L234.759 218.304C235.234 217.912 235.617 217.42 235.88 216.862C236.142 216.304 236.278 215.696 236.278 215.079"
            stroke="#592D29"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M226.944 211.253L225.602 214.054C225.514 214.237 225.475 214.439 225.486 214.642C225.498 214.844 225.561 215.041 225.669 215.212C225.777 215.384 225.927 215.525 226.105 215.623C226.283 215.721 226.482 215.772 226.685 215.772H227.785C228.258 215.772 228.722 215.644 229.128 215.401C229.534 215.159 229.866 214.811 230.09 214.394L231.706 211.4"
            stroke="#592D29"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M237.036 160.937C228.461 161.627 223.277 157.14 225.657 154.71C227.608 152.715 232.33 155.933 234.602 157.695C234.99 158.945 235.848 160.093 237.036 160.937Z"
            fill="white"
          />
          <path
            d="M239.915 150.53C240.19 149.466 239.592 148.391 238.58 148.129C237.567 147.867 236.523 148.518 236.248 149.583C235.973 150.647 236.571 151.722 237.584 151.984C238.596 152.245 239.64 151.595 239.915 150.53Z"
            fill="white"
          />
          <path
            d="M238.055 151.245C237.982 151.525 237.837 151.782 237.635 151.99C237.614 151.986 237.594 151.982 237.574 151.976C236.562 151.714 235.963 150.642 236.239 149.575C236.274 149.438 236.323 149.305 236.385 149.177C236.643 149.167 236.899 149.217 237.134 149.324C237.37 149.43 237.576 149.591 237.738 149.791C237.901 149.992 238.013 150.228 238.068 150.481C238.123 150.733 238.117 150.995 238.053 151.245H238.055Z"
            fill="#CEE4ED"
          />
          <path
            d="M248.493 152.745C248.769 151.681 248.171 150.606 247.158 150.344C246.145 150.082 245.101 150.733 244.826 151.798C244.551 152.862 245.149 153.937 246.162 154.199C247.174 154.46 248.218 153.81 248.493 152.745Z"
            fill="white"
          />
          <path
            d="M248.516 152.542C248.506 152.608 248.493 152.672 248.476 152.738C248.201 153.802 247.157 154.453 246.141 154.192C246.05 154.168 245.962 154.137 245.876 154.1C245.87 153.923 245.89 153.746 245.934 153.574C246.175 152.638 247.094 152.066 247.986 152.297C248.176 152.345 248.356 152.428 248.516 152.542Z"
            fill="#CEE4ED"
          />
          <path
            d="M247.347 158.329C248.12 155.337 245.88 152.171 242.344 151.257C238.808 150.343 235.314 152.028 234.541 155.02C233.768 158.012 236.007 161.179 239.544 162.093C243.08 163.006 246.574 161.322 247.347 158.329Z"
            fill="white"
          />
          <path
            d="M238.748 155.843C238.807 155.616 238.679 155.387 238.463 155.331C238.247 155.276 238.024 155.415 237.965 155.641C237.907 155.868 238.035 156.098 238.251 156.153C238.467 156.209 238.69 156.07 238.748 155.843Z"
            fill="#59221D"
          />
          <path
            d="M243.911 157.727C243.97 157.501 243.842 157.272 243.626 157.216C243.41 157.16 243.188 157.299 243.129 157.525C243.071 157.752 243.198 157.981 243.414 158.036C243.63 158.092 243.853 157.954 243.911 157.727Z"
            fill="#59221D"
          />
          <path
            d="M251.464 169.644C254.689 169.277 256.704 166.054 255.959 163.395C255.013 160.022 252.067 159.238 248.849 159.595C245.117 160.01 236.283 160.696 228.04 159.455C228.04 159.455 226.031 165.391 231.204 168.029C233.106 168.993 243.436 170.556 251.464 169.644Z"
            fill="#A74602"
          />
          <path
            d="M228.098 159.705C228.098 159.705 226.059 165.55 231.637 168.476"
            stroke="#59221D"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M228.094 159.691C228.094 159.691 228.068 161.66 230.742 161.71"
            stroke="#59221D"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M227.797 161.662C227.797 161.662 227.917 163.598 230.589 163.648"
            stroke="#59221D"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M227.812 163.224C227.812 163.224 227.891 165.417 230.559 165.436"
            stroke="#59221D"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M242.453 143.873C241.89 145.476 239.993 146.653 237.739 146.653C235.484 146.653 233.584 145.472 233.023 143.866C233.639 143.727 234.263 143.616 234.895 143.532V140.155H241.085V143.602C241.545 143.679 242.001 143.769 242.453 143.873Z"
            fill="#A74602"
          />
          <path
            d="M239.437 142.823C240.41 142.19 241.08 141.314 241.08 140.154H234.891V143.601C234.891 143.601 237.685 143.964 239.437 142.823Z"
            fill="#59221D"
          />
          <path
            d="M242.249 136.44H240.453L237.047 132.103H238.983C239.849 132.103 240.68 132.446 241.292 133.059C241.905 133.671 242.249 134.502 242.249 135.368V136.44Z"
            fill="#FFC200"
          />
          <path
            d="M234.144 135.78L240.163 135.773L237.305 132.035C236.397 130.767 235.044 130.015 233.484 130.015H233.373C231.667 130.015 230.64 131.971 231.669 133.332C231.985 133.749 234.144 135.78 234.144 135.78Z"
            fill="white"
            stroke="#FFC200"
            stroke-width="4"
            stroke-miterlimit="10"
          />
          <path
            d="M243.676 138.128V138.461C243.676 138.909 243.499 139.338 243.182 139.655C242.865 139.971 242.436 140.149 241.988 140.149H240.406C240.134 140.893 239.64 141.535 238.991 141.989C238.342 142.443 237.569 142.686 236.777 142.686H236.261C235.893 142.686 235.529 142.614 235.19 142.473C234.85 142.333 234.541 142.126 234.281 141.867C234.021 141.607 233.815 141.298 233.674 140.958C233.533 140.619 233.461 140.255 233.461 139.887V137.136C233.461 136.702 233.558 136.274 233.744 135.883C233.929 135.491 234.2 135.146 234.536 134.872C234.872 134.597 235.264 134.402 235.685 134.298C236.106 134.194 236.545 134.185 236.97 134.272L237.591 134.399C238.158 134.515 238.691 134.759 239.15 135.112C239.609 135.464 239.982 135.917 240.24 136.435H241.988C242.21 136.435 242.43 136.479 242.635 136.564C242.84 136.649 243.027 136.774 243.184 136.931C243.34 137.088 243.465 137.275 243.549 137.48C243.634 137.686 243.677 137.906 243.676 138.128Z"
            fill="#A74602"
          />
          <path
            d="M241.172 138.512C241.172 138.197 241.297 137.895 241.52 137.673C241.742 137.45 242.045 137.325 242.36 137.325"
            stroke="#59221D"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M241.383 136.44C241.078 136.44 240.776 136.501 240.496 136.619C240.215 136.738 239.961 136.911 239.748 137.129C239.706 137.172 239.657 137.207 239.601 137.231C239.546 137.255 239.487 137.267 239.427 137.267C239.367 137.267 239.307 137.256 239.252 137.233C239.196 137.211 239.146 137.177 239.103 137.135C239.061 137.092 239.027 137.042 239.004 136.987C238.981 136.931 238.969 136.872 238.969 136.812V136.8C238.97 136.336 238.788 135.891 238.463 135.561C232.246 137.105 231.96 133.559 233.459 132.91C234.745 132.354 237.977 134.274 239.163 135.023L241.383 136.44Z"
            fill="#59221D"
          />
          <path
            d="M233.57 136.924C233.57 136.924 234.424 136.702 234.993 137.325C235.414 137.785 235.361 139.474 235.361 139.474"
            stroke="#59221D"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M236.5 136.994C236.5 136.994 237.7 136.765 238.326 137.268"
            stroke="#59221D"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M234.108 138.835C234.267 138.835 234.396 138.617 234.396 138.349C234.396 138.081 234.267 137.863 234.108 137.863C233.949 137.863 233.82 138.081 233.82 138.349C233.82 138.617 233.949 138.835 234.108 138.835Z"
            fill="#59221D"
          />
          <path
            d="M237.132 138.835C237.291 138.835 237.42 138.617 237.42 138.349C237.42 138.081 237.291 137.863 237.132 137.863C236.973 137.863 236.844 138.081 236.844 138.349C236.844 138.617 236.973 138.835 237.132 138.835Z"
            fill="#59221D"
          />
          <path
            d="M179.306 109.848C179.306 113.65 176.851 117.12 169.897 117.417C160.882 117.571 158.523 114.228 158.523 110.323C158.523 106.814 161.016 103.9 164.288 103.331V100.482C164.288 99.9524 164.393 99.4281 164.596 98.9389C164.798 98.4497 165.095 98.0053 165.47 97.631C165.845 97.2567 166.289 96.9599 166.779 96.7576C167.268 96.5553 167.792 96.4513 168.322 96.4518H169.416C170.483 96.4541 171.506 96.8798 172.26 97.6353C173.014 98.3909 173.437 99.4146 173.437 100.482V103.273C175.052 103.457 176.543 104.229 177.625 105.441C178.708 106.654 179.306 108.222 179.306 109.848Z"
            fill="white"
          />
          <path
            d="M151.374 181.369H183.645C190.087 181.369 195.309 175.933 195.309 169.227C195.309 162.521 190.082 157.085 183.645 157.085H151.374C144.932 157.085 139.711 162.521 139.711 169.227C139.711 175.933 144.932 181.369 151.374 181.369Z"
            fill="#59221D"
          />
          <path
            d="M195.614 172.256V256.274C195.615 257.01 195.47 257.74 195.189 258.42C194.908 259.101 194.495 259.719 193.975 260.24C191.528 258.846 188.267 257.137 185.56 255.89C175.823 251.412 170.428 251.902 169.448 252.045L166.668 178.426L166.455 172.801C166.384 170.865 166.698 168.934 167.381 167.121C168.064 165.308 169.101 163.649 170.432 162.241C171.763 160.834 173.362 159.706 175.134 158.922C176.906 158.139 178.816 157.717 180.753 157.681C182.69 157.644 184.615 157.994 186.415 158.71C188.216 159.426 189.855 160.493 191.238 161.849C192.622 163.206 193.721 164.824 194.471 166.61C195.222 168.397 195.609 170.314 195.61 172.252L195.614 172.256Z"
            fill="#59221D"
          />
          <path
            d="M165.668 259.441C165.552 259.657 165.495 259.9 165.5 260.145C165.506 260.391 165.576 260.63 165.702 260.841C165.828 261.052 166.006 261.226 166.219 261.347C166.433 261.468 166.674 261.532 166.919 261.532H190.154C190.8 261.534 191.441 261.407 192.038 261.159C192.635 260.911 193.176 260.546 193.63 260.086C191.15 258.662 188.39 257.189 185.563 255.889C174.747 250.915 169.622 252.069 169.622 252.069L165.668 259.441Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="6"
            stroke-miterlimit="10"
          />
          <path
            d="M166.907 177.435L149.641 252.435C149.641 252.435 142.746 251.28 132.22 256.255C129.471 257.555 126.787 259.027 124.375 260.452C123.879 259.939 123.508 259.32 123.289 258.642C123.07 257.964 123.009 257.244 123.11 256.539L135.198 171.542C135.745 167.693 137.662 164.172 140.598 161.624C143.534 159.076 147.291 157.673 151.178 157.673C161.555 157.673 169.235 167.323 166.907 177.435Z"
            fill="#59221D"
          />
          <path
            d="M152.801 258.024C152.998 258.402 153.095 258.825 153.081 259.251C153.068 259.677 152.944 260.093 152.723 260.457C152.502 260.822 152.191 261.124 151.82 261.333C151.448 261.542 151.029 261.652 150.602 261.652H127.874C127.242 261.653 126.616 261.525 126.035 261.277C125.453 261.028 124.928 260.664 124.492 260.206C126.904 258.782 129.59 257.308 132.339 256.008C142.859 251.034 149.758 252.188 149.758 252.188L152.801 258.024Z"
            fill="white"
            stroke="#F3B304"
            stroke-width="6"
            stroke-miterlimit="10"
          />
          <path
            d="M151.406 157.896C161.782 157.896 169.463 167.545 167.135 177.657L164.151 190.622"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M201.688 261.892V171.592C201.688 170.538 201.48 169.494 201.077 168.52C200.674 167.546 200.083 166.661 199.337 165.916C198.592 165.17 197.707 164.579 196.733 164.175C195.759 163.772 194.715 163.564 193.661 163.564C191.532 163.564 189.49 164.41 187.984 165.916C186.479 167.421 185.633 169.463 185.633 171.592"
            stroke="#F3B304"
            stroke-width="6"
            stroke-miterlimit="10"
          />
          <path
            d="M188.914 261.892C188.914 259.867 189.718 257.926 191.15 256.494C192.582 255.062 194.524 254.258 196.548 254.258H206.809C207.812 254.258 208.805 254.455 209.731 254.839C210.658 255.222 211.499 255.785 212.209 256.494C212.918 257.202 213.48 258.044 213.864 258.97C214.247 259.897 214.445 260.89 214.445 261.892"
            stroke="#F3B304"
            stroke-width="6"
            stroke-miterlimit="10"
          />
          <path
            d="M215.778 261.892H213.008L213.262 261.202C213.329 261.021 213.45 260.866 213.608 260.756C213.765 260.646 213.953 260.587 214.145 260.587H214.64C214.833 260.587 215.02 260.646 215.178 260.756C215.336 260.866 215.457 261.021 215.523 261.202L215.778 261.892Z"
            fill="#59221D"
          />
          <path
            d="M203.02 261.892H200.25L200.505 261.202C200.571 261.021 200.692 260.866 200.85 260.756C201.008 260.646 201.195 260.587 201.388 260.587H201.883C202.075 260.587 202.263 260.646 202.42 260.756C202.578 260.866 202.699 261.021 202.766 261.202L203.02 261.892Z"
            fill="#59221D"
          />
          <path
            d="M190.356 261.892H187.586L187.841 261.202C187.907 261.021 188.028 260.866 188.186 260.756C188.343 260.646 188.531 260.587 188.724 260.587H189.22C189.412 260.587 189.6 260.646 189.758 260.756C189.915 260.866 190.036 261.021 190.103 261.202L190.356 261.892Z"
            fill="#59221D"
          />
          <path
            d="M190.741 117.06C192.952 117.6 194.966 118.755 196.549 120.391C198.132 122.027 199.22 124.077 199.687 126.305L186.947 132.476L187.289 157.339H148.968L149.746 132.611L136.977 126.425C137.444 124.198 138.532 122.147 140.115 120.512C141.698 118.876 143.711 117.721 145.922 117.181L164.192 112.844H173.599L190.741 117.06Z"
            fill="#F5BBBB"
          />
          <path
            d="M168.828 123.873C169.333 123.873 169.742 123.464 169.742 122.959C169.742 122.454 169.333 122.045 168.828 122.045C168.323 122.045 167.914 122.454 167.914 122.959C167.914 123.464 168.323 123.873 168.828 123.873Z"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M168.828 130.41C169.333 130.41 169.742 130.001 169.742 129.496C169.742 128.991 169.333 128.582 168.828 128.582C168.323 128.582 167.914 128.991 167.914 129.496C167.914 130.001 168.323 130.41 168.828 130.41Z"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M168.828 136.947C169.333 136.947 169.742 136.537 169.742 136.033C169.742 135.528 169.333 135.118 168.828 135.118C168.323 135.118 167.914 135.528 167.914 136.033C167.914 136.537 168.323 136.947 168.828 136.947Z"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M168.828 143.482C169.333 143.482 169.742 143.073 169.742 142.568C169.742 142.063 169.333 141.654 168.828 141.654C168.323 141.654 167.914 142.063 167.914 142.568C167.914 143.073 168.323 143.482 168.828 143.482Z"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M159.773 113.955L162.216 121.449L166.881 117.649"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M170.992 117.649L175.367 121.449L177.721 113.984"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M149.959 130.332L137.727 124.471"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M186.781 130.342L199.126 124.369"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M139.57 163.305L194.21 163.292C195.083 163.291 195.952 163.179 196.797 162.958C198.004 162.755 199.159 162.315 200.196 161.664C201.234 161.013 202.132 160.164 202.84 159.166C203.548 158.167 204.052 157.038 204.323 155.844C204.594 154.65 204.627 153.414 204.419 152.208L199.696 126.296L186.956 132.467L187.117 143.786L152.194 148.966C151.053 149.182 149.948 149.558 148.912 150.085L138.34 159.982C137.092 160.807 136.881 163.311 139.57 163.305Z"
            fill="#A74602"
          />
          <path
            d="M190.907 143.239L184.781 144.093"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M136.979 126.425L149.748 132.612L149.435 144.066L194.617 150.364C195.596 150.5 196.529 150.865 197.341 151.43C198.152 151.994 198.819 152.742 199.288 153.612L206.018 169.699H205.036C204.29 169.699 203.559 169.496 202.92 169.113C202.28 168.729 201.757 168.179 201.406 167.521L200.533 165.882L190.457 166.976C188.566 167.205 186.774 165.922 186.099 164.148L185.784 163.319H140.24C138.077 163.324 135.968 162.639 134.222 161.362C130.85 158.902 129.403 154.579 130.487 150.545L136.979 126.425Z"
            fill="#A74602"
          />
          <path
            d="M184.723 159.957L186.099 164.15C186.774 165.929 188.572 167.208 190.457 166.978L200.533 165.884L201.406 167.523C201.757 168.181 202.28 168.732 202.919 169.116C203.559 169.5 204.29 169.702 205.036 169.703H206.018L199.288 153.616C198.819 152.745 198.152 151.997 197.341 151.433C196.529 150.868 195.596 150.503 194.617 150.366L142.203 143.065"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M200.565 165.948L197.406 159.288"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M196.382 165.983L193.344 159.684"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M192.421 166.478L189.383 160.179"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M173.601 112.846L172.461 107.359H165.409L164.199 112.846C164.199 112.846 163.32 117.517 168.902 117.517C174.483 117.517 173.601 112.846 173.601 112.846Z"
            fill="#A74602"
          />
          <path
            d="M172.508 107.601C172.487 108.747 172.058 109.848 171.296 110.704C170.535 111.561 169.493 112.117 168.358 112.272L164.195 112.846L165.409 107.358H172.461L172.508 107.601Z"
            fill="#59221D"
          />
          <path
            d="M175.27 105.315V105.622C175.271 105.865 175.224 106.107 175.131 106.332C175.038 106.557 174.902 106.762 174.73 106.935C174.558 107.107 174.354 107.244 174.129 107.338C173.904 107.431 173.662 107.479 173.419 107.479H172.579C172.263 109.104 170.788 110.333 169.02 110.333H168.808C167.035 110.333 165.56 109.104 165.244 107.479H164.453C163.962 107.479 163.491 107.284 163.144 106.937C162.797 106.589 162.602 106.118 162.602 105.627V105.32C162.602 104.829 162.797 104.359 163.144 104.012C163.491 103.664 163.962 103.469 164.453 103.469C164.719 103.469 164.976 103.561 165.182 103.729V102.854C165.182 102.024 165.998 101.422 166.825 101.644C167.067 101.709 167.317 101.741 167.568 101.741C168.869 101.741 169.938 100.884 170.061 99.7868H170.074C171.495 99.7868 172.647 100.899 172.647 102.273V103.738C172.869 103.563 173.143 103.468 173.425 103.469C173.669 103.469 173.909 103.517 174.134 103.61C174.359 103.703 174.563 103.839 174.735 104.011C174.907 104.183 175.043 104.387 175.136 104.612C175.229 104.836 175.277 105.077 175.277 105.32L175.27 105.315Z"
            fill="#A74602"
          />
          <path
            d="M165.014 105.811C165.013 105.491 164.886 105.183 164.659 104.957C164.432 104.73 164.125 104.603 163.805 104.603"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M172.695 105.811C172.696 105.491 172.823 105.183 173.05 104.957C173.276 104.73 173.584 104.603 173.904 104.603"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M170.109 102.668C170.109 102.668 171.147 102.306 171.866 102.962"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M170.647 104.731C170.836 104.731 170.99 104.473 170.99 104.154C170.99 103.835 170.836 103.577 170.647 103.577C170.458 103.577 170.305 103.835 170.305 104.154C170.305 104.473 170.458 104.731 170.647 104.731Z"
            fill="#59221D"
          />
          <path
            d="M166.999 104.731C167.188 104.731 167.341 104.473 167.341 104.154C167.341 103.835 167.188 103.577 166.999 103.577C166.81 103.577 166.656 103.835 166.656 104.154C166.656 104.473 166.81 104.731 166.999 104.731Z"
            fill="#59221D"
          />
          <path
            d="M169.429 105.687C169.073 105.847 168.67 105.862 168.304 105.73C168.304 105.73 168.533 103.463 167.859 102.779"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M166.008 102.963C166.726 102.306 167.764 102.666 167.764 102.666"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M167.984 107.238C168.307 107.405 168.672 107.471 169.032 107.429C169.393 107.387 169.732 107.238 170.007 107.001"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M169.859 106.671L170.306 107.469"
            stroke="#59221D"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M92.1371 261.892H66.7819C66.305 261.892 65.8343 261.784 65.405 261.577C64.9756 261.369 64.5987 261.067 64.3024 260.694C64.0061 260.32 63.798 259.884 63.6937 259.419C63.5895 258.954 63.5917 258.471 63.7003 258.007L65.3195 251.099H81.9149C82.8291 251.1 83.7284 251.331 84.5294 251.771C85.3304 252.212 86.0071 252.847 86.4966 253.619L86.6357 253.842C86.9481 254.334 87.3797 254.74 87.8906 255.02C88.4015 255.301 88.9751 255.449 89.5582 255.449H91.8324C92.0481 255.757 92.2705 256.062 92.4996 256.364L93.9453 258.288C94.1964 258.623 94.3492 259.022 94.3865 259.439C94.4237 259.856 94.344 260.275 94.1562 260.649C93.9684 261.024 93.68 261.338 93.3233 261.558C92.9666 261.777 92.5559 261.893 92.1371 261.892Z"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M92.1371 261.892H66.7819C66.305 261.892 65.8343 261.784 65.405 261.577C64.9756 261.369 64.5987 261.067 64.3024 260.694C64.0061 260.32 63.798 259.884 63.6937 259.419C63.5895 258.954 63.5917 258.471 63.7003 258.007L63.9794 256.812C64.0141 257.628 64.3626 258.399 64.9521 258.964C65.5416 259.53 66.3266 259.846 67.1433 259.846H92.4985C92.8458 259.848 93.1888 259.769 93.4998 259.615C93.8108 259.46 94.0811 259.235 94.2889 258.956C94.3957 259.294 94.4213 259.653 94.3636 260.003C94.306 260.352 94.1667 260.684 93.9572 260.97C93.7476 261.256 93.4736 261.488 93.1573 261.648C92.8411 261.809 92.4916 261.892 92.1371 261.892Z"
            fill="#A74602"
          />
          <path
            d="M92.1371 261.892H66.7819C66.305 261.892 65.8343 261.784 65.405 261.577C64.9756 261.369 64.5987 261.067 64.3024 260.694C64.0061 260.32 63.798 259.884 63.6937 259.419C63.5895 258.954 63.5917 258.471 63.7003 258.007L63.9794 256.812C64.0141 257.628 64.3626 258.399 64.9521 258.964C65.5416 259.53 66.3266 259.846 67.1433 259.846H92.4985C92.8458 259.848 93.1888 259.769 93.4998 259.615C93.8108 259.46 94.0811 259.235 94.2889 258.956C94.3957 259.294 94.4213 259.653 94.3636 260.003C94.306 260.352 94.1667 260.684 93.9572 260.97C93.7476 261.256 93.4736 261.488 93.1573 261.648C92.8411 261.809 92.4916 261.892 92.1371 261.892Z"
            stroke="#592D29"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M82.1562 251.099C84.0145 251.099 85.5025 252.05 86.4933 253.619L86.6323 253.842C86.9447 254.334 87.3764 254.74 87.8873 255.02C88.3982 255.301 88.9718 255.449 89.5549 255.449H91.829C90.0831 252.948 86.3599 247.619 85.9751 242.451L82.1941 247.372L82.1562 251.099Z"
            fill="#7EBAD2"
          />
          <path
            d="M82.1562 251.099C84.0145 251.099 85.5025 252.05 86.4933 253.619L86.6323 253.842C86.9447 254.334 87.3764 254.74 87.8873 255.02C88.3982 255.301 88.9718 255.449 89.5549 255.449H91.829C90.0831 252.948 86.3599 247.619 85.9751 242.451L82.1941 247.372L82.1562 251.099Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M82.1971 247.37H65.3281V251.099H82.1971V247.37Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M68.0625 247.366V251.099"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M70.8594 247.366V251.099"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M73.6562 247.366V251.099"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M76.4688 247.366V251.099"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M79.2578 247.366V251.099"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.2943 261.892H21.9404C20.9396 261.892 19.545 261.892 18.4875 260.958L4.03055 249.628C2.24123 248.228 3.11532 246.762 4.14954 245.658L9.62649 239.745C9.62649 239.745 18.8344 245.383 25.9806 245.061C28.0101 244.97 29.8728 244.499 31.1551 242.102C29.4369 245.357 30.0852 253.932 32.454 258.227C32.6608 258.603 32.7663 259.026 32.76 259.455C32.7536 259.884 32.6357 260.305 32.4179 260.674C32.2 261.044 31.8896 261.351 31.5173 261.564C31.145 261.778 30.7235 261.891 30.2943 261.892Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.2947 261.892H21.9409C20.94 261.892 19.5466 261.892 18.4879 260.958L4.03098 249.628C2.34953 248.313 3.01899 246.939 3.96425 245.863C3.97649 246.428 4.2634 247.009 4.99403 247.581L19.4509 258.911C20.5096 259.846 21.9075 259.846 22.905 259.846H31.2578C31.8044 259.849 32.3363 259.669 32.768 259.333C32.7793 259.665 32.7237 259.996 32.6046 260.306C32.4854 260.616 32.305 260.899 32.0742 261.137C31.8434 261.376 31.5669 261.566 31.2612 261.696C30.9555 261.825 30.6268 261.892 30.2947 261.892Z"
            fill="#A74602"
          />
          <path
            d="M30.2947 261.892H21.9409C20.94 261.892 19.5466 261.892 18.4879 260.958L4.03098 249.628C2.34953 248.313 3.01899 246.939 3.96425 245.863C3.97649 246.428 4.2634 247.009 4.99403 247.581L19.4509 258.911C20.5096 259.846 21.9075 259.846 22.905 259.846H31.2578C31.8044 259.849 32.3363 259.669 32.768 259.333C32.7793 259.665 32.7237 259.996 32.6046 260.306C32.4854 260.616 32.305 260.899 32.0742 261.137C31.8434 261.376 31.5669 261.566 31.2612 261.696C30.9555 261.825 30.6268 261.892 30.2947 261.892Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25.9901 245.065L11.4688 236.481L9.57064 239.692L24.092 248.276L25.9901 245.065Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.8291 237.868L11.9297 241.082"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.2354 239.294L14.3359 242.506"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.6416 240.718L16.7422 243.931"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.049 242.143L19.1484 245.357"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23.4708 243.567L21.5703 246.781"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.7495 253.147H28.9368C28.5296 253.147 28.1353 253.004 27.8226 252.743C27.5098 252.483 27.2985 252.121 27.2253 251.72L27.1441 251.275C27.0627 250.828 26.884 250.405 26.6207 250.035C26.3574 249.665 26.016 249.358 25.6206 249.134L24.0859 248.267L25.9854 245.056C28.0138 244.964 29.8776 244.495 31.1598 242.097C29.9866 244.324 29.9154 249.022 30.7495 253.147Z"
            fill="#7EBAD2"
          />
          <path
            d="M30.7495 253.147H28.9368C28.5296 253.147 28.1353 253.004 27.8226 252.743C27.5098 252.483 27.2985 252.121 27.2253 251.72L27.1441 251.275C27.0627 250.828 26.884 250.405 26.6207 250.035C26.3574 249.665 26.016 249.358 25.6206 249.134L24.0859 248.267L25.9854 245.056C28.0138 244.964 29.8776 244.495 31.1598 242.097C29.9866 244.324 29.9154 249.022 30.7495 253.147Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M123.018 25.8296L126.283 18.1341C126.75 17.0316 127.532 16.0911 128.531 15.43C129.529 14.7689 130.7 14.4164 131.898 14.4164H142.532C143.343 14.4166 144.129 14.6945 144.759 15.2038C145.39 15.7131 145.827 16.4231 145.998 17.2155L146.418 19.1806H143.86C143.501 19.181 143.15 19.2888 142.853 19.4901C142.556 19.6915 142.326 19.9771 142.192 20.3104L140.173 25.3436C139.576 26.8301 138.548 28.1039 137.222 29.0012C135.895 29.8984 134.33 30.3779 132.728 30.378H126.033C125.492 30.3787 124.96 30.2453 124.483 29.9899C124.007 29.7345 123.601 29.365 123.302 28.9144C123.004 28.4637 122.821 27.9461 122.772 27.4078C122.722 26.8695 122.807 26.3273 123.018 25.8296Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M130.383 18.1341C130.851 17.0316 131.633 16.0912 132.631 15.4301C133.63 14.769 134.801 14.4165 135.999 14.4164"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M134.492 18.1341C134.96 17.0315 135.742 16.091 136.741 15.4299C137.739 14.7688 138.91 14.4163 140.108 14.4164"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M138.594 18.1342C138.998 17.1812 139.638 16.3468 140.454 15.7094C141.27 15.0721 142.234 14.6528 143.257 14.491"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M56.3281 54.3253L58.3688 49.1508L67.3866 49.1263V54.3253H56.3281Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M61.3472 50.0449C64.0462 52.4915 68.837 50.4731 68.837 50.4731C68.7647 49.5567 68.6669 49.2854 68.5657 48.021C68.5312 47.5839 69.7812 47.4649 69.7467 47.0323C69.5721 44.8393 68.1275 41.5832 68.1109 41.3853C65.9779 44.4646 61.0781 43.7573 61.0781 43.7573L61.3472 50.0449Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M65.5938 44.7481C65.5938 44.7481 66.0842 43.9429 67.4587 43.8006"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M66.8883 46.2906C67.0811 46.2906 67.2374 46.0063 67.2374 45.6556C67.2374 45.3049 67.0811 45.0206 66.8883 45.0206C66.6954 45.0206 66.5391 45.3049 66.5391 45.6556C66.5391 46.0063 66.6954 46.2906 66.8883 46.2906Z"
            fill="#59221D"
          />
          <path
            d="M69.1543 50.7044C69.1721 50.8594 69.1586 51.0163 69.1145 51.166C69.0704 51.3156 68.9967 51.4548 68.8977 51.5754C68.7988 51.6959 68.6765 51.7954 68.5384 51.8677C68.4002 51.9401 68.2489 51.9839 68.0934 51.9966C66.2685 52.1423 62.1138 52.2191 61.1463 50.1873C61.0907 50.0761 61.0418 50.0639 60.9973 49.9415C59.3292 49.9471 57.9191 50.3597 57.1273 47.8508C56.6291 46.2717 56.3733 43.605 56.7792 42.7598C57.2474 41.7834 68.2969 36.5166 68.9597 36.8958C69.6225 37.2751 69.8493 39.8373 68.3247 41.7067C66.8957 43.4537 64.3591 44.1755 61.8469 44.0987C62.2672 45.4332 63.1992 47.5984 65.1019 47.8475C67.8821 48.2089 68.7717 47.3782 68.7717 47.3782L69.1543 50.7044Z"
            fill="#59221D"
          />
          <path
            d="M61.698 47.2369L61.6868 50.0805H59.8664C59.3134 50.0805 58.783 49.8608 58.392 49.4698C58.0009 49.0787 57.7812 48.5484 57.7812 47.9953C57.7812 47.4423 58.0009 46.912 58.392 46.5209C58.783 46.1299 59.3134 45.9102 59.8664 45.9102H60.3779C60.5518 45.9104 60.7239 45.9448 60.8845 46.0116C61.045 46.0784 61.1907 46.1763 61.3134 46.2995C61.436 46.4228 61.5331 46.569 61.5991 46.7299C61.6651 46.8907 61.6987 47.0631 61.698 47.2369Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M59.5156 47.0579C59.8204 47.0579 60.1128 47.1786 60.3287 47.3937C60.5447 47.6088 60.6666 47.9007 60.6677 48.2055"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M34.3632 123.327L34.1285 140.268C34.0906 141.212 33.8669 142.139 33.4702 142.996C33.0736 143.854 32.5117 144.624 31.8168 145.264C31.1219 145.904 30.3076 146.4 29.4206 146.724C28.5336 147.049 27.5913 147.196 26.6476 147.155L19.1478 146.861C18.1296 146.817 17.1323 146.558 16.2222 146.099C15.3121 145.64 14.5101 144.993 13.8694 144.2C13.2288 143.408 12.7642 142.488 12.5066 141.502C12.249 140.516 12.2042 139.486 12.3753 138.481L23.4805 73.2706C24.2015 68.9209 26.4431 64.9685 29.8061 62.1173C33.1691 59.2661 37.4351 57.7013 41.8441 57.7016H103.961C105.474 57.7016 106.949 57.2304 108.182 56.3535C109.414 55.4766 110.343 54.2376 110.839 52.8085L117.87 32.5421C118.402 31.0061 119.4 29.6742 120.725 28.7315C122.049 27.7888 123.635 27.2822 125.26 27.282H134.531C135.446 27.2816 136.348 27.4941 137.167 27.9027C137.985 28.3114 138.697 28.905 139.247 29.6365C139.796 30.3681 140.168 31.2175 140.332 32.1175C140.496 33.0175 140.449 33.9434 140.193 34.8219L130.016 69.8854C129.022 73.309 126.944 76.3174 124.093 78.4583C121.243 80.5991 117.775 81.7567 114.21 81.7568H93.2362C92.2998 81.7566 91.3726 81.9409 90.5075 82.2992C89.6424 82.6574 88.8563 83.1825 88.1941 83.8446C87.532 84.5066 87.0067 85.2926 86.6484 86.1577C86.29 87.0227 86.1056 87.9499 86.1056 88.8863V123.531L34.3632 123.327Z"
            fill="#FFC200"
          />
          <path
            d="M129.882 70.3037C128.819 73.6275 126.727 76.5275 123.909 78.585C121.09 80.6425 117.691 81.7509 114.201 81.7502H112.606C112.328 80.802 112.187 79.819 112.188 78.831C112.188 72.937 117.134 68.1551 123.234 68.1551C125.622 68.1475 127.95 68.9 129.882 70.3037Z"
            fill="#A74602"
          />
          <path
            d="M29.9891 104.78C29.9891 110.731 25.5864 115.555 20.1529 115.555C18.8661 115.556 17.594 115.282 16.4219 114.751L19.9549 94.0074H20.1529C25.5864 94.0052 29.9891 98.8293 29.9891 104.78Z"
            fill="#A74602"
          />
          <path
            d="M31.0106 144.217C31.1295 141.925 27.4207 139.87 22.7267 139.626C18.0327 139.383 14.131 141.043 14.0121 143.335C13.8933 145.626 17.6021 147.681 22.2961 147.925C26.9901 148.168 30.8917 146.508 31.0106 144.217Z"
            fill="#59221D"
          />
          <path
            d="M53.5312 57.6972L56.1313 53.9884H67.7368C67.7368 53.9884 71.2955 57.7027 70.8962 57.7027L53.5312 57.6972Z"
            fill="#A74602"
          />
          <path
            d="M29.2025 150.195L28.2661 155.033C28.1645 155.565 27.8807 156.045 27.4636 156.39C27.0465 156.735 26.5221 156.924 25.9808 156.924H24.2282L23.3975 159.674C23.1683 160.431 22.7018 161.094 22.0667 161.565C21.4317 162.036 20.6619 162.291 19.8711 162.291H8.60695C8.2733 162.29 7.94301 162.224 7.63494 162.096C7.32687 161.968 7.04707 161.78 6.8115 161.544C6.57594 161.308 6.38924 161.027 6.26206 160.719C6.13489 160.41 6.06973 160.08 6.07032 159.746C6.07061 159.236 6.2734 158.747 6.63413 158.386C6.99487 158.025 7.48404 157.823 7.9942 157.822H9.09626L13.1297 146.813C13.5751 145.597 14.3865 144.55 15.4521 143.815C16.5176 143.08 17.7849 142.694 19.0793 142.709L23.1384 142.75C24.0541 142.762 24.9563 142.974 25.7811 143.372C26.6058 143.77 27.3331 144.344 27.9116 145.054C28.49 145.764 28.9054 146.593 29.1285 147.481C29.3515 148.369 29.3768 149.296 29.2025 150.195Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.4853 162.291C12.1514 162.291 11.8207 162.225 11.5123 162.097C11.2038 161.969 10.9236 161.781 10.6877 161.545C10.4518 161.309 10.2648 161.028 10.1374 160.72C10.01 160.411 9.94473 160.08 9.94532 159.746C9.94532 159.236 10.148 158.747 10.5088 158.386C10.8696 158.025 11.359 157.822 11.8692 157.822H12.9713L14.5504 153.51"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.3592 162.291C16.0253 162.291 15.6948 162.225 15.3865 162.097C15.0782 161.969 14.7981 161.781 14.5623 161.545C14.3265 161.308 14.1396 161.028 14.0123 160.719C13.885 160.411 13.8197 160.08 13.8203 159.746C13.8203 159.236 14.023 158.747 14.3838 158.386C14.7446 158.025 15.234 157.822 15.7442 157.822H16.8463L18.4254 153.51"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.8993 162.291C19.5654 162.291 19.2348 162.225 18.9263 162.097C18.6179 161.969 18.3377 161.782 18.1017 161.545C17.8658 161.309 17.6788 161.028 17.5514 160.72C17.4241 160.411 17.3588 160.08 17.3594 159.746C17.3594 159.236 17.5621 158.747 17.9229 158.386C18.2837 158.025 18.773 157.822 19.2833 157.822H20.3853L21.5463 154.654"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.137 156.919H21.0859V156.45C21.0859 155.449 21.7532 154.458 22.7084 154.179L23.885 153.817C23.885 153.817 21.5397 152.77 21.9945 148.534"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M85.9695 123.911V228.51L85.9283 228.438V242.496C85.928 243.788 85.4149 245.026 84.5017 245.94C83.5885 246.853 82.35 247.366 81.0586 247.366H64.4276C63.2183 247.366 62.0586 246.886 61.2035 246.031C60.3485 245.176 59.8681 244.016 59.8681 242.807V173.104C59.8681 173.104 57.2536 204.198 31.1522 242.102C30.0657 243.889 28.6334 244.743 27.0376 244.966C26.6659 245.018 26.2905 245.037 25.9155 245.024L11.4563 236.479L10.8436 235.933C9.81435 235.117 9.10532 233.965 8.84096 232.679C8.57659 231.392 8.77377 230.054 9.3979 228.898C17.5427 213.831 37.114 169.625 34.3361 123.716L85.9695 123.911Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M59.8621 174.218V144.578L55.0312 144.496"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M74.9103 163.527H85.9632V167.176H73.8672V164.573C73.8669 164.435 73.8937 164.3 73.946 164.173C73.9982 164.046 74.075 163.931 74.1719 163.834C74.2688 163.736 74.3839 163.659 74.5106 163.607C74.6373 163.554 74.7731 163.527 74.9103 163.527Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M74.9141 167.178H85.9703V178.234H76.6066C76.3841 178.234 76.1637 178.19 75.9581 178.105C75.7526 178.02 75.5658 177.895 75.4086 177.737C75.2514 177.58 75.1268 177.393 75.0419 177.187C74.9571 176.981 74.9136 176.761 74.9141 176.538V167.176V167.178Z"
            fill="white"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M86.9831 57.6971H78.6425C77.1677 57.6971 75.7452 58.2435 74.6495 59.2307C73.5539 60.2179 72.8628 61.576 72.7096 63.0428L71.4508 121.257C71.1734 123.909 69.9239 126.364 67.9432 128.149C65.9625 129.934 63.391 130.922 60.7249 130.923H42.0376C40.6875 130.923 39.3533 130.632 38.1255 130.07C36.8978 129.509 35.8053 128.689 34.9224 127.668C34.0396 126.646 33.3869 125.447 33.0089 124.151C32.6309 122.855 32.5364 121.492 32.7318 120.157L41.0379 63.1685C41.2602 61.6465 42.0232 60.2554 43.1873 59.2498C44.3513 58.2443 45.8385 57.6915 47.3767 57.6927H53.2128C51.7393 57.6923 50.3178 58.2375 49.2224 59.2231C48.1271 60.2088 47.4355 61.5652 47.2811 63.0306L47.1331 64.4463C47.0649 65.0964 47.134 65.7535 47.3361 66.3752C47.5382 66.9968 47.8687 67.5691 48.3061 68.0548C48.7436 68.5405 49.2782 68.9288 49.8755 69.1945C50.4727 69.4603 51.119 69.5975 51.7727 69.5974H60.7471C61.8147 69.5956 62.8495 69.2281 63.6792 68.5561C64.5088 67.8841 65.0832 66.9482 65.3066 65.9042L65.9738 62.7559C66.279 61.322 67.0672 60.0364 68.2066 59.1139C69.3461 58.1915 70.7677 57.6882 72.2337 57.6882L86.9831 57.6971Z"
            fill="#7EBAD2"
          />
          <path
            d="M32.481 120.157C32.2865 121.492 32.3817 122.854 32.7601 124.15C33.1386 125.445 33.7914 126.644 34.6743 127.665C35.5572 128.686 36.6495 129.505 37.8769 130.066C39.1044 130.628 40.4382 130.918 41.7879 130.918H60.8388C63.5033 130.916 66.0729 129.928 68.0523 128.145C70.0317 126.361 71.2809 123.908 71.5592 121.258L72.8158 63.0431C72.9687 61.5764 73.6596 60.2183 74.755 59.231C75.8505 58.2438 77.2729 57.6974 78.7476 57.6974"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32.5547 120.157C32.8123 122.456 33.9083 124.58 35.6332 126.123C37.3581 127.665 39.591 128.518 41.905 128.518H54.6938C57.3596 128.517 59.9307 127.529 61.9111 125.745C63.8915 123.96 65.141 121.506 65.4186 118.854L71.4916 63.0474C71.8085 60.0092 74.4475 57.5715 78.5354 57.7016"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M53.3298 57.6971C50.2761 57.6971 47.3825 60.0024 47.0644 63.035L46.9154 64.4507C46.8482 65.1006 46.9181 65.7573 47.1206 66.3785C47.323 66.9997 47.6536 67.5715 48.0908 68.057C48.5281 68.5424 49.0623 68.9307 49.659 69.1968C50.2557 69.4629 50.9016 69.6009 51.555 69.6018"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M43.2314 104.668C42.8721 104.668 42.5253 104.8 42.2573 105.039C41.9892 105.279 41.8186 105.608 41.7779 105.965L40.7248 115.282C40.6915 115.565 40.7184 115.851 40.8038 116.122C40.8893 116.394 41.0312 116.644 41.2204 116.856C41.4096 117.068 41.6416 117.238 41.9012 117.354C42.1608 117.471 42.4421 117.53 42.7265 117.53H56.0369C56.7377 117.53 57.4132 117.268 57.9313 116.796C58.4494 116.325 58.7728 115.676 58.8382 114.979L59.7067 105.7C59.719 105.569 59.7037 105.436 59.6619 105.311C59.6201 105.187 59.5528 105.072 59.4641 104.975C59.3755 104.877 59.2675 104.799 59.1471 104.746C59.0267 104.693 58.8965 104.665 58.7648 104.665L43.2314 104.668Z"
            fill="white"
          />
          <path
            d="M57.0884 106.093H39.4688L39.8024 104.291C39.8448 104.058 39.9673 103.849 40.1485 103.697C40.3298 103.546 40.5583 103.463 40.7943 103.463H57.3297L57.0884 106.093Z"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40.4612 106.093L39.5215 114.078C39.4884 114.36 39.5155 114.647 39.6009 114.918C39.6864 115.189 39.8284 115.439 40.0176 115.651C40.2067 115.864 40.4386 116.034 40.6982 116.15C40.9577 116.266 41.2389 116.326 41.5233 116.325H54.8336C55.5345 116.326 56.2102 116.064 56.7283 115.592C57.2465 115.121 57.5698 114.472 57.6349 113.774L58.5035 104.497C58.5157 104.366 58.5004 104.234 58.4586 104.109C58.4169 103.984 58.3495 103.87 58.2609 103.772C58.1722 103.675 58.0642 103.597 57.9438 103.544C57.8234 103.491 57.6932 103.463 57.5615 103.463"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M45.4688 74.8197H63.4821"
            stroke="#59221D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Procurement from "@/utils/api/procurement/ProcurementApi";
import Shipment from "@/utils/api/shipment/ShipmentApi";
import SupplierPayments from "../utils/api/supplierPayments/SupplierPayments";
export default {
  data() {
    return {
      metrics: {
        successFullShipments: 0,
        successFullProcurements: 0,
        paidSupplierPayments: 0,
      },
    };
  },
  mounted() {
    this.fetchAllMetrics();
  },
  methods: {
    // function to approximate digits and make them shorter
    formatNumber(number) {
      const suffixes = ["", "k", "M", "B", "T"];
      const threshold = 1000;

      if (number < threshold) return number; // No need for suffix if less than 1000

      const magnitude = Math.floor(Math.log10(number) / 3);
      const formatted = number / Math.pow(threshold, magnitude);

      return formatted.toFixed(1) + suffixes[magnitude];
    },
    routeToPage(pageName) {
      // Navigate to the specified page
      this.$router.push({ name: pageName });
    },
    // Function to calculate the total amountInLocal
    calculateTotalAmountInLocal(transactions) {
      let totalAmountInLocal = 0;

      // Iterate over the transactions array
      for (let i = 0; i < transactions.length; i++) {
        // Add the amountInLocal of each transaction to the total
        totalAmountInLocal += transactions[i].amountinLocal;
      }

      return totalAmountInLocal;
    },

    // async useRefreshToken() {
    //   const refreshToken = this.$store.state.tokens.refreshToken;
    //   try {
    //     const getAccessTokenRes = await Authentication.refreshToken({
    //       refresh_token: refreshToken,
    //     });

    //     const payload = {
    //       accessToken: getAccessTokenRes.data.accessToken,
    //       refreshToken,
    //     };

    //     this.$store.dispatch("updateAccessToken", payload);

    //     this.fetchAllMetrics();
    //   } catch (err) {
    //     this.$toast.error(err.response?.data?.message || err.message);
    //   }
    // },

    async fetchAllMetrics() {
      const token = this.$store.state.tokens.accessToken;

      try {
        const userShipmentRes = await Shipment.getUserShipments({
          access_token: token,
        });

        const userProcurementRes = await Procurement.getUserProcurements({
          access_token: token,
        });

        const userSupplierPaymentRes =
          await SupplierPayments.getSupplierPayment({
            access_token: token,
          });

        const numberOfShipments = userShipmentRes.data.data?.filter(
          (item) => item.status.toLowerCase() === "delivered"
        );

        const numberOfProcurements = userProcurementRes.data.data?.filter(
          (item) => item.status.toLowerCase() === "procured"
        );

        const numberOfSupplierPayments =
          userSupplierPaymentRes.data.data?.filter(
            (item) => item.status.toLowerCase() === "paid"
          );

        this.metrics.paidSupplierPayments = this.formatNumber(
          this.calculateTotalAmountInLocal(numberOfSupplierPayments)
        );
        this.metrics.successFullShipments = numberOfShipments.length;

        this.metrics.successFullProcurements = numberOfProcurements.length;
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(err.response?.data?.message || err.message);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
.tracking_stats {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    color: #363539;
    font-family: $primary-font;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .tracking_stats_wrapper {
    display: flex;
    flex: 1;
    gap: 2.5rem;
    margin-top: 1.3125rem;

    @media only screen and (max-width: 920px) {
      width: 100%;
      overflow-x: auto;
    }

    .tracking_stats_wrapper_card:nth-child(3) {
      background: $black-color;

      h1,
      p {
        color: $lilac-color;
      }
    }
    .tracking_stats_wrapper_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      flex: 1;
      padding: 1.5rem 2rem;
      height: 10.1875em;
      flex-shrink: 0;
      border-radius: 1.25rem;
      border: 0.0625rem solid rgba(222, 220, 233, 0.52);
      background: #fefefe;
      gap: 0.875rem;

      @media only screen and (max-width: 920px) {
        width: 250px;
        flex: none;
      }

      h1 {
        color: #363539;
        font-family: $primary-font;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @media only screen and (max-width: 920px) {
          font-size: 25px;
        }
      }

      p {
        color: #363539;
        font-family: $secondary-font;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media only screen and (max-width: 920px) {
          font-size: 14px;
        }
      }
    }
  }
}
.tracking_faq {
  margin-top: 2.6875rem;
  height: 22.625em;
  display: flex;
  padding: 6.0625rem 2.5625rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 0.0625rem solid #dedce9;
  background: #fefefe;
  align-items: center;

  @media only screen and (max-width: 920px) {
    flex-direction: column;
    padding: 1.6875em 1.3125em;
  }

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.75rem;

    svg {
      @media only screen and (max-width: 920px) {
        width: 300px;
        height: 265px;
      }
    }

    h1 {
      color: #363539;
      font-family: $primary-font;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 18px;
      }
    }

    p {
      color: #363539;
      font-family: $secondary-font;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem; /* 130% */

      @media only screen and (max-width: 920px) {
        font-size: 15px;
      }
    }
  }
}
</style>
