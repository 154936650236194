<template>
  <div class="overview">
    <div class="overview_side_menu">
      <side-menu />
    </div>

    <div class="overview_inner">
      <kyc-modal
        v-if="!userDetails?.verifiedBusinessName"
        :isVisible="isModalVisible"
        ctaLink="/profile"
        @close="isModalVisible = false"
      />
      <div class="overview_inner_header">
        <div class="overview_inner_header_welcome">
          <h1>Welcome, {{ userDetails?.firstName }}</h1>
        </div>
        <div class="btn_wrapper">
          <div class="button">
            <router-link to="/shipments/book-a-shipment">
              <button class="button_btn">Book a Shipment</button>
            </router-link>
          </div>
        </div>
      </div>

      <dashboard-menu />
      <router-view> </router-view>
      <dashboard-stats />
    </div>
  </div>
</template>

<script>
import SideMenu from "../../components/menu/SideMenu.vue";
import DashboardMenu from "../../components/menu/DashboardMenu.vue";
import KycModal from "../../components/modals/KycModal.vue";
import DashboardStats from "@/components/DashboardStats.vue";
import Authentication from "@/utils/api/AuthApi";

export default {
  data() {
    return {
      userDetails: [],
      isModalVisible: true,
    };
  },
  components: {
    SideMenu, // Register the component
    DashboardMenu, // Register the component
    DashboardStats,
    KycModal,
  },

  mounted() {
    this.getUserDetails();
    this.showModal();
    this.addVisitorDetails();
  },
  methods: {
    async addVisitorDetails() {
      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_API.visitor = {
        name: this.userDetails?.firstName,
        email: this.userDetails?.email,
      };
    },
    async getUserDetails() {
      const token = this.$store.state.tokens.accessToken;

      try {
        const res = await Authentication.getUserDetails({
          access_token: token,
        });
        // Save user details to general store
        this.$store.dispatch("storeUserDetails", res.data.data);

        this.userDetails = res.data.data;

        return res;
      } catch (err) {
        this.$toast.error(err.response?.data?.message);
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.overview {
  display: flex;

  .overview_side_menu {
    @media only screen and (max-width: 920px) {
      display: none;
    }
  }

  .overview_inner {
    padding: 2.75rem 5.125em;
    height: 100vh;
    overflow: scroll;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex: 1;

    .overview_inner_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h1 {
        color: $black-color;
        font-family: $primary-font;
        font-size: 1.5625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media only screen and (max-width: 920px) {
          font-size: 1rem;
        }
      }

      .btn_wrapper {
        display: flex;

        .button {
          display: flex;
          justify-content: flex-end;
          a {
            text-decoration: none;

            button {
              width: 10.875em;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 920px) {
      padding: 0rem 0.5em;
    }
  }
}
</style>
