<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <circle-loader v-if="isLoading" />
  <div class="booking" v-else>
    <div class="booking_inner">
      <h1>{{ editActivity ? "Edit" : "Book" }} a Shipment</h1>

      <form class="booking_form" @submit.prevent="bookShipmentHandler">
        <div class="shipments_select">
          <label>Name Of Product</label>
          <div class="input">
            <input
              v-model="name"
              required
              type="text"
              :disabled="isProcuredItem"
              placeholder="Name of Product"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Quantity (How Many Items?)</label>
          <div class="input">
            <input
              v-model="quantity"
              required
              type="number"
              min="0"
              placeholder="How many products do you want to ship?"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Goods Category</label>
          <div class="shipments_select_wrapper">
            <select
              required
              v-model="categorySelectionOption"
              :items="categoryOptions"
              class="input_field"
            >
              <option value="">Select category</option>
              <option
                v-for="category in categoryOptions"
                :key="category._id"
                :value="category._id"
              >
                {{ category?.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Shipment Origin</label>
          <div class="shipments_select_wrapper">
            <select
              required
              v-model="shipmentLocation"
              :items="shipmentLocationOptions"
              class="input_field"
            >
              <option value="">Select location</option>
              <option
                v-for="warehouse in shipmentLocationOptions"
                :key="warehouse._id"
                :value="warehouse"
              >
                {{ warehouse?.country }}
              </option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Shipment Destination</label>
          <div class="shipments_select_wrapper">
            <select
              required
              v-model="shipmentDestination"
              :items="shipmentDestinationOptions"
              class="input_field"
            >
              <option value="">Select destination</option>
              <option
                v-for="warehouse in shipmentDestinationOptions"
                :key="warehouse._id"
                :value="warehouse._id"
              >
                {{ warehouse?.country }}
              </option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Shipment Method</label>
          <div class="shipments_select_wrapper">
            <select
              :items="shipmentMethodOptions"
              v-model="shipmentMethod"
              required
              class="input_field"
            >
              <option value="">Select a category</option>
              <option
                v-for="shipment_method in shipmentMethodOptions"
                :key="shipment_method.value"
                :value="shipment_method.value"
              >
                {{ shipment_method?.type }}
              </option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Weight Unit</label>
          <div class="shipments_select_wrapper">
            <select required v-model="weightUnit" class="input_field">
              <option value="">Select Weight Unit</option>
              <option value="KG">KG</option>
              <option value="LBS">LBS</option>
              <option value="CBM">CBM</option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Weight Estimate (in KG, LBS or CBM)</label>
          <div class="input">
            <input
              v-model="weight"
              required
              type="text"
              placeholder="What is the estimated weight for this shipment"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Supplier Tracking Number</label>
          <div class="input">
            <input
              v-model="supplierTrackingNumber"
              type="text"
              :disabled="isProcuredItem"
              placeholder="Enter tracking number if applicable"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label
            >Are your goods dangerous/special goods? (i.e electronics,
            batteries, liquid)</label
          >
          <div class="shipments_select_wrapper">
            <select required v-model="dangerousGoods" class="input_field">
              <option value="">Select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>

        <div v-if="!submitLoading" class="button">
          <button class="button_btn">Submit</button>
        </div>

        <div v-else class="button">
          <button :disabled="submitLoading" class="button_btn">
            <div class="loading-circle"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Shipment from "@/utils/api/shipment/ShipmentApi";
import CircleLoader from "../../components/loader/CircleLoader.vue";
export default {
  data() {
    return {
      dangerousGoods: "",
      weightUnit: "",
      quantity: null,
      editActivity: false,
      supplierTrackingNumber: "",
      categorySelectionOption: "",
      shipmentLocation: "",
      shipmentDestination: "",
      categoryOptions: [],
      shipmentMethodOptions: [
        {
          value: "SEA_FREIGHT",
          type: "Sea-freight",
        },
        {
          value: "AIR_FREIGHT",
          type: "Air-freight",
        },
      ],
      shipmentLocationOptions: [],
      shipmentDestinationOptions: [],
      shipmentMethod: "",
      weight: "",
      name: "",
      shipmentCategory: [],
      destinationAddress: [],
      dropOffAddress: [],
      isLoading: false,
      submitLoading: false,
      item: null,
      warehouses: [],
      isProcuredItem: false,
      procuredItem: [],
    };
  },
  watch: {
    shipmentLocation(newValue) {
      if (newValue.country === "China") {
        this.shipmentDestinationOptions = this.warehouses.filter(
          (destination) =>
            destination.country !== "Australia" &&
            destination.country !== "China"
        );
      } else if (newValue.country === "Nigeria") {
        this.shipmentDestinationOptions = this.warehouses.filter(
          (destination) =>
            destination.country !== "Nigeria" && destination.country !== "China"
        );
      } else {
        this.shipmentDestinationOptions = this.warehouses.filter(
          (destination) => destination.country === "Nigeria"
        );
      }
    },

    // dangerousGoods(newValue) {
    //   if (newValue === "true") {
    //     this.shipmentMethod === "";

    //     this.shipmentMethodOptions = [
    //       {
    //         value: "SEA_FREIGHT",
    //         type: "Sea-freight",
    //       },
    //     ];
    //   } else {
    //     this.shipmentMethodOptions = [
    //       {
    //         value: "SEA_FREIGHT",
    //         type: "Sea-freight",
    //       },
    //       {
    //         value: "AIR_FREIGHT",
    //         type: "Air-freight",
    //       },
    //     ];
    //   }
    // },
  },
  components: {
    CircleLoader,
  },
  mounted() {
    this.getShipmentCategories();
    this.checkUserActivity();
  },
  methods: {
    getShipmentDetails(payload) {
      this.name = payload.name;
      this.categorySelectionOption = this.shipmentCategory._id;
      this.shipmentLocation = this.dropOffAddress;
      this.shipmentDestination = this.destinationAddress._id;
      this.shipmentMethod = payload.freightType;
      this.weight = payload.weight;
      this.quantity = payload.quantity;
      this.weightUnit = payload.weightUnit;
      this.supplierTrackingNumber = payload.supplierTrackingNumber;
      this.dangerousGoods = payload.isDangerous;
    },

    async fetchUserShipment() {
      const token = this.$store.state.tokens.accessToken;

      const shipmentId = this.$route.params.Id;

      this.isLoading = true;
      try {
        const userShipmentRes = await Shipment.getSingleShipment({
          shipmentId,
          access_token: token,
        });

        // get shipment categories and filter to what is gotten from backend
        const shipmentCategories = await Shipment.getCategory();

        this.shipmentCategory = shipmentCategories.data.data.find(
          (category) => category._id === userShipmentRes.data.data[0].category
        );

        // get warehouses and filter to what is gotten from backend
        const getWarehouses = await Shipment.getWarehouse();

        this.dropOffAddress = getWarehouses.data.data.find(
          (location) =>
            location._id ===
            userShipmentRes.data.data[0].dropOffwarehouseAddress
        );

        this.destinationAddress = getWarehouses.data.data.find(
          (location) =>
            location._id === userShipmentRes.data.data[0].pickUpWareHouseAddress
        );

        this.isLoading = false;
        this.item = userShipmentRes.data.data[0];
        this.getShipmentDetails(userShipmentRes.data.data[0]);
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(err.response?.data?.message || err.message);
        }
      }
    },
    checkUserActivity() {
      const id = this.$route.params.Id;
      if (id) {
        this.fetchUserShipment();
        this.editActivity = true;
      } else {
        this.editActivity = false;
      }
    },
    async getShipmentCategories() {
      if (this.$route.path === "/shipments/procurement-to-shipment") {
        this.isProcuredItem = true;
        this.name = `${this.$store.state.procurementDetails.productName}`;
        this.supplierTrackingNumber =
          this.$store.state.procurementDetails.listingId;
      } else {
        this.isProcuredItem = false;
      }

      try {
        const shipmentCategories = await Shipment.getCategory();

        const getWarehouses = await Shipment.getWarehouse();

        this.categoryOptions = shipmentCategories.data.data;

        this.warehouses = getWarehouses.data.data;

        this.shipmentLocationOptions = getWarehouses.data.data;

        this.shipmentDestinationOptions = getWarehouses.data.data;
      } catch (err) {
        console.log(err.response);
      }
    },

    // Function to book a shipment
    async bookShipmentHandler() {
      const token = this.$store.state.tokens.accessToken;
      this.submitLoading = true;
      try {
        if (this.editActivity) {
          const bookingRes = await Shipment.editShipment({
            shipmentId: this.$route.params.Id,
            name: this.name,
            isDangerous: this.dangerousGoods,
            category: this.categorySelectionOption,
            freightType: this.shipmentMethod,
            supplierTrackingNumber: this.supplierTrackingNumber,
            fromCountry: this.shipmentLocation.country,
            weightUnit: this.weightUnit,
            weight: this.weight,
            quantity: this.quantity,
            access_token: token,
            dropOffwarehouseAddressID: this.shipmentDestination,
            pickUpWarehouseAddressID: this.shipmentLocation._id,
          });

          this.submitLoading = false;
          this.$toast.success("Form has been editted successfully");
          // route to shipments list page after successful procurement of item
          this.$router.push("/shipments");
          return bookingRes.data;
        } else {
          const bookingRes = await Shipment.createShipment({
            name: this.name,
            isDangerous: this.dangerousGoods,
            category: this.categorySelectionOption,
            freightType: this.shipmentMethod,
            supplierTrackingNumber: this.supplierTrackingNumber,
            fromCountry: this.shipmentLocation.country,
            weightUnit: this.weightUnit,
            quantity: this.quantity,
            weight: this.weight,
            access_token: token,
            dropOffwarehouseAddressID: this.shipmentDestination,
            pickUpWarehouseAddressID: this.shipmentLocation._id,
          });

          this.submitLoading = false;
          this.$toast.success(
            "Shipment Created! Track its progress easily on your dashboard."
          );
          // route to shipments list page after successful procurement of item
          this.$router.push("/shipments");
          return bookingRes.data;
        }
      } catch (err) {
        this.submitLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data ||
              err.response?.data?.message ||
              err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "vue-select/dist/vue-select.css";
@import "../../styles/variables.scss";
.booking {
  width: 100%;
  padding: 2.75rem 5.125em;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .booking_inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 53.3125em;
    flex-shrink: 0;
    border-radius: 1.875rem;
    padding: 5.8125rem 0rem;
    gap: 2.8125rem;
    border: 0.0625rem solid #dedce9;
    background: #fafafa;
    align-items: center;

    @media only screen and (max-width: 920px) {
      overflow: visible;
    }

    h1 {
      margin-top: 5px;
      color: #000;
      font-family: $primary-font;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 1.5625rem;
        margin-top: 0.3125rem;
      }
    }

    .booking_form {
      display: flex;
      flex-direction: column;
      gap: 3.4375rem;
      width: 100%;
      align-items: center;
      overflow: auto;

      .loading-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        position: relative;
        animation: rotate 1s linear infinite;
      }

      .loading-circle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        animation: bounce 0.6s linear infinite;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      @keyframes bounce {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(0);
        }
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
        padding: 0rem 0.5rem;
      }

      .shipments_select {
        border-radius: 0.625rem;
        width: 29.1875em;
        height: 50px;
        gap: 0.625em;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }

        .shipments_select_wrapper {
          padding: 0.3125rem 0.8125rem;
          width: 100%;
          height: 3.225em;
          display: flex;
          border: 0.0625rem solid #dedce9;
          border-radius: 0.625rem;
          background-color: #fff;

          select {
            border-radius: 0.625rem;
            width: 100%;
            height: 3.225em;
            background-color: #fff;

            color: #363539;
            border: none;
            border: 1px solid transparent;

            font-family: $secondary-font;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:focus {
              outline: none;
            }
          }
        }

        label {
          color: #606060;
          font-family: $secondary-font;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .button {
        margin-top: 30px;
        width: 29.1875em;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
