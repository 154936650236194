const Faq = {
  faqData: [
    {
      question: "What are your shipping costs?",
      answer: `Our shipping costs vary depending on factors like the origin and destination of your shipment, the weight, dimensions, and type of goods. For an accurate estimate, use the rate calculator on our platform or contact our support team for a tailored quote.`,
      id: 1,
    },
    {
      question: "Are there additional or hidden charges?",
      answer: `At Novoinno, we believe in transparency. All costs, including duties, taxes, and any additional fees, will be clearly outlined in your quote. We do not have hidden charges, and you will be informed of all potential costs upfront or as they arise.`,
      id: 2,
    },
    {
      question: "How long does it take to get my package delivered?",
      answer: `Delivery times vary based on the shipping route and the service selected. For instance, air freight typically takes 5-7 business days from the U.S. or UK to most major cities, while Sea freight can take 4-6 weeks. Estimated delivery times for each service will be provided during the booking process.`,
      id: 3,
    },
    {
      question:
        " What is the process for shipping from the U.S. (or other countries) to my location?",
      answer: `Shipping with Novoinno is simple and straightforward:<br/><br/>1.	Sign Up: Create an account on our platform.<br/><br/><br/>
	2.	Book Your Shipment: Click on “Book a Shipment” and enter the details of your shipment, including origin, destination, and package specifics.<br/><br/><br/>
	3.	Choose Your Service: Select from our air or sea freight options based on your needs, and the urgency of your shipment.<br/><br/><br/>
	4.	Make Payment: Once your shipment arrives at our warehouse, it will be logged and marked as “pending.” You will have the option to:<br/><br/>
	•	Tick the items you’re ready to ship and click “merge.”<br/><br/>
	•	Choose whether to keep the original packaging or have us consolidate the ticked items into one carton to save space and cost.<br/><br/>
After this step, an invoice will be sent to the email on your account, and the total amount will also reflect on your shipment page. To complete the payment, click on the “Pay” button on the platform and follow the prompt, or transfer to the bank account listed on your invoice.<br/><br/>
	•	For Full Container Load (FCL): If you’re shipping a full container load, there’s no need to merge. Once we acknowledge the arrival of your shipment, the status will change to “pending,” and you’ll see the total amount to pay on the “Pay” button. An invoice will also be sent to your registered email. Complete payment using any of our accepted methods.<br/><br/><br/>	5.	Track Your Shipment: Monitor your shipment in real time through our platform, with updates at every key stage of the journey.<br/><br/><br/>
	6.	Delivery: We handle customs clearance and arrange final delivery to your specified address, or you can opt to pick up from our warehouse.<br/><br/><br/>

This clear, step-by-step process is designed to make your shipping experience as seamless and efficient as possible, whether you’re moving small packages or large consignments.
`,
      id: 4,
    },
    {
      question: "What are the accepted methods of payment?",
      answer: `We accept major credit and debit cards through our payment partners (Paystack) and Bank Transfers. We’re continuously expanding our payment options to make it even easier for you to transact with us globally.`,
      id: 5,
    },
    {
      question: "Do I have to pay for delivery at the destination?",
      answer: `If delivery is specified when booking, the fees will be included in your final invoice unless otherwise stated. There are no additional delivery charges at the destination unless specified.`,
      id: 6,
    },
    {
      question: "How can I change my delivery address?",
      answer: `To change your delivery address, contact our customer support team immediately for assistance.`,
      id: 7,
    },
    {
      question: "Are my fragile items safe with Novoinno?",
      answer: `Yes, we take extra care with fragile items. We recommend that customers opt for additional packaging and insurance for delicate items. Our team will ensure that your fragile goods are handled with care throughout the shipping process.`,
      id: 8,
    },
    {
      question: "Can I ship perfumes or other restricted items?",
      answer: `Yes, you can ship perfumes; however, they are classified as hazardous materials and may require special handling. Shipping restrictions vary by country, so please check with our support team for specific guidelines on shipping perfumes or other restricted items to your destination.`,
      id: 9,
    },
    {
      question: "Why should I consolidate my shipments?",
      answer: `Consolidation allows you to combine multiple packages into a single shipment, reducing your overall shipping costs by minimizing weight and volume charges. This is particularly beneficial for customers who order from multiple suppliers or want to reduce handling fees. Consolidation also simplifies the customs process and makes tracking your goods easier, ensuring a more efficient and cost-effective shipping experience.`,
      id: 10,
    },
  ],
};
export default Faq;
