import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/Store";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import "./styles/dropdown.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import "vue-select/dist/vue-select.css";
import VueClipboard from "vue-clipboard2";
import "./main.css";
import posthogPlugin from "./plugins/posthog";

const globalOptions = {
  mode: "auto",
};

VueClipboard.config.autoSetContainer = true;

createApp(App)
  .use(posthogPlugin)
  .use(ToastPlugin, {
    duration: 5000,
    position: "top-left",
  })
  .use(store)
  .use(VueClipboard)
  .use(VueTelInput, globalOptions)
  .use(router)
  .mount("#app");
