<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="procure_inner">
      <h1>
        {{ editActivity ? "Edit" : "New" }} Purchase
        {{ editActivity ? "New" : "" }} Order
      </h1>

      <form class="procure_form" @submit.prevent="bookShipmentHandler">
        <div class="shipments_select">
          <label>Product Name</label>
          <div class="input">
            <input
              v-model="productName"
              required
              type="text"
              placeholder="Enter product name"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Product Link </label>
          <div class="input">
            <input
              v-model="productLink"
              :required="productImage.length === 0"
              type="text"
              placeholder="Enter the product link "
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Quantity</label>
          <div class="input">
            <input
              v-model="quantity"
              required
              type="number"
              min="0"
              placeholder="Enter quantity of items"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Product Description</label>
          <div class="input_textarea">
            <textarea
              v-model="productDescription"
              required
              type="text"
              placeholder="Add more specific details about the product, eg budget, sizes, colors"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_image">
          <label>Product Image {{ this.fileName }}</label>
          <div class="input_image" v-if="!editActivity">
            <input
              type="file"
              ref="fileInput"
              id="file-input"
              @change="handleFileUpload"
              :required="productLink.length === 0"
              accept="image/png,  image/jpeg"
              style="display: none"
            />
            <div>
              <label
                v-if="!productImage"
                @click="handleFileInputClick"
                for="file-input"
              >
                Attach Item Picture
              </label>
              <label v-else @click="handleFileInputClick" for="file-input">
                Choose Another Item Picture
              </label>
            </div>

            <img :src="imageFileUrl" alt="Preview Image" v-if="productImage" />
          </div>

          <div class="input_image" v-else>
            <input
              type="file"
              ref="fileInput"
              id="file-input"
              @change="handleFileUpload"
              accept="image/png,  image/jpeg"
              style="display: none"
            />
            <div>
              <label
                v-if="!productImage"
                @click="handleFileInputClick"
                for="file-input"
              >
                Attach Item Picture
              </label>
              <label v-else @click="handleFileInputClick" for="file-input">
                Choose Another Item Picture
              </label>
            </div>

            <img :src="imageFileUrl" alt="Preview Image" />
          </div>
        </div>

        <div class="shipments_select">
          <label>
            Note: A commitment fee of N5,000 will be required. It will be
            deducted from the final invoice.
          </label>
          <div style="flex-direction: row">
            <input required type="checkbox" />
            <label>
              I agree to Novoinno's
              <a href="https://www.novoinnotrade.com/terms" target="_blank"
                >Terms and Conditions</a
              >
            </label>
          </div>
        </div>

        <div v-if="!submitLoading" class="button">
          <button class="button_btn">Submit</button>
        </div>

        <div v-else class="button">
          <button :disabled="submitLoading" class="button_btn">
            <div class="loading-circle"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Procurement from "@/utils/api/procurement/ProcurementApi";
import CircleLoader from "../../components/loader/CircleLoader.vue";

export default {
  data() {
    return {
      productName: "",
      productLink: "",
      productDescription: "",
      quantity: "",
      productImage: "",
      imageFile: null,
      imageFileUrl: "",
      fileName: "",
      isLoading: false,
      item: null,
      editActivity: "",
      submitLoading: false,
    };
  },
  components: {
    CircleLoader,
  },
  mounted() {
    this.checkUserActivity();
  },
  methods: {
    getShipmentDetails(payload) {
      this.productName = payload.productName;
      this.productLink = payload.productLink;
      this.productDescription = payload.productDescription;
      this.quantity = payload.quantity;
      this.imageFileUrl = payload.productImage;
    },

    async fetchUserProcurements() {
      const token = this.$store.state.tokens.accessToken;

      const procurementId = this.$route.params.Id;

      this.id = this.$route.params.Id;

      this.isLoading = true;
      try {
        const userProcurementRes = await Procurement.getSingleProcurement({
          procurementId,
          access_token: token,
        });

        this.isLoading = false;
        this.item = userProcurementRes.data.data;
        this.getShipmentDetails(userProcurementRes.data.data);
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(err.response?.data?.message || err.message);
        }
      }
    },
    checkUserActivity() {
      const id = this.$route.params.Id;
      if (id) {
        this.fetchUserProcurements();
        this.editActivity = true;
      } else {
        this.editActivity = false;
      }
    },
    // Function to trigger selection of image
    handleFileInputClick() {
      this.$refs.fileInput;
    },

    removeBase64Prefix(base64String) {
      // Check if the string starts with the prefix
      if (base64String.startsWith("data:image/png;base64,")) {
        // Remove the prefix from the string
        return base64String.slice("data:image/png;base64,".length);
      } else if (base64String.startsWith("data:image/jpeg;base64,")) {
        return base64String.slice("data:image/jpeg;base64,".length);
      } else {
        // Return the original string if it doesn't start with the prefix
        return base64String;
      }
    },

    // Function to update and store the image url
    handleFileUpload(event) {
      const imgFile = event.target.files[0];

      this.imageFile = imgFile;

      this.fileName = imgFile.name;

      if (imgFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imageFileUrl = event.target.result;
          this.productImage = this.removeBase64Prefix(event.target.result);
        };
        reader.readAsDataURL(imgFile);
      }
    },

    // Function to book a shipment
    async bookShipmentHandler() {
      const token = this.$store.state.tokens.accessToken;

      this.submitLoading = true;

      try {
        if (this.editActivity) {
          const bookingRes = await Procurement.editProcurement({
            procurementId: this.$route.params.Id,
            productName: this.productName,
            productLink: this.productLink,
            productDescription: this.productDescription,
            quantity: this.quantity,
            productImage: this.imageFileUrl,
            access_token: token,
          });

          this.submitLoading = true;

          this.$toast.success(
            "Procurement Order Placed! We'll notify you when it's confirmed."
          );

          // route to procurement list page after successful procurement of item
          this.$router.push("/procurement");

          return bookingRes.data;
        } else {
          const bookingRes = await Procurement.createProcurement({
            productName: this.productName,
            productLink: this.productLink,
            productDescription: this.productDescription,
            quantity: this.quantity,
            productImage: this.productImage,
            access_token: token,
          });

          this.submitLoading = true;

          this.$toast.success(
            "Procurement Order Placed! We'll notify you when it's confirmed."
          );

          // route to procurement list page after successful procurement of item
          this.$router.push("/procurement");

          return bookingRes.data;
        }
      } catch (err) {
        this.submitLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data ||
              err.response?.data?.message ||
              err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "vue-select/dist/vue-select.css";
@import "../../styles/variables.scss";
.procure {
  width: 100%;
  padding: 2.75rem 5.125em;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .procure_inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 53.3125em;
    flex-shrink: 0;
    padding: 1.5625rem 0rem;
    border-radius: 1.875rem;
    gap: 2.8125rem;
    border: 1px solid #dedce9;
    background: #fafafa;
    align-items: center;

    @media only screen and (max-width: 920px) {
      overflow: visible;
    }

    h1 {
      margin-top: 2.1875rem;
      color: #000;
      font-family: $primary-font;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 1.5625rem;
        margin-top: 0.3125rem;
      }
    }

    .procure_form {
      display: flex;
      flex-direction: column;
      gap: 2.8125rem;
      width: 100%;
      overflow: scroll;
      align-items: center;

      .loading-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        position: relative;
        animation: rotate 1s linear infinite;
      }

      .loading-circle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        animation: bounce 0.6s linear infinite;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      @keyframes bounce {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(0);
        }
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
        padding: 0px 0.5rem;
      }

      .shipments_image {
        border-radius: 0.625rem;
        height: 9.1875em;
        gap: 0.625rem;
        display: flex;
        flex-direction: column;
        width: 29.1875em;
        align-items: flex-start;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }

        .input_image {
          width: 100%;
          height: 9.1875em;
          flex-shrink: 0;
          border-radius: 0.3125rem;
          border: 0.0625rem solid #c4c1c1;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          justify-content: center;

          img {
            width: 100%;
            height: 100px;
            object-fit: contain;
          }
          div {
            width: 100%;
            height: auto;
            position: relative;
            align-items: center;
            display: flex;
            justify-content: center;

            label {
              cursor: pointer;
              color: #606060;
              font-family: $secondary-font;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              z-index: 100;
            }
          }
          @media only screen and (max-width: 920px) {
            width: 100%;
          }
        }

        label {
          color: #606060;
          font-family: $secondary-font;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .shipments_select:nth-child(4) {
        height: 6.9375em;

        .input_textarea {
          height: 6.9375em;
          border-radius: 0.3125rem;
          width: 29.1875em;
          border: 0.0625rem solid #c4c1c1;

          @media only screen and (max-width: 920px) {
            width: 100%;
          }

          & textarea {
            width: 100%;
            resize: none;
            flex: 1;
            height: 6.75em;
            display: flex;
            border: none;
            border-radius: 0.3125rem;
            padding: 0.875rem 1rem;

            color: $black-color;
            font-family: $secondary-font;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:focus {
              outline: none;
            }
          }
        }
      }
      .shipments_select {
        border-radius: 0.625rem;
        width: 29.1875em;
        height: 3.125em;
        gap: 0.625rem;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }

        label {
          color: #606060;
          font-family: $secondary-font;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .button {
        width: 29.1875em;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
