<template>
  <div v-if="isVisible" class="kyc-notification">
    <div class="kyc-notification-content">
      <div class="kyc-header">
        <h2>{{ title }}</h2>
        <p>{{ message }}</p>
      </div>
      <div class="kyc-body">
        <div class="kyc-footer">
          <router-link :to="ctaLink" class="cta-button">{{
            ctaText
          }}</router-link>
          <button class="close-button" @click="closeNotification">×</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KYCNotification",
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Complete your KYC verification",
    },
    message: {
      type: String,
      default:
        "Verify your business identity to have full access to all features",
    },
    ctaText: {
      type: String,
      default: "Continue",
    },
    ctaLink: {
      type: String,
      default: "/profile",
    },
  },
  methods: {
    closeNotification() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.kyc-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f0f4ff;
  border: 1px solid #d0e3ff;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 87.5em;
  margin: 0.75rem auto;

  .kyc-notification-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .kyc-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;

    h2 {
      flex-grow: 1;
      font-size: 0.95rem;
      font-weight: bold;
      color: #333;
      margin: 0;
    }

    p {
      font-size: 0.75rem;
      margin: 0;
      color: #666;
    }
  }

  .kyc-body {
    display: flex;
    justify-content: space-between;

    .kyc-footer {
      display: flex;
      justify-content: flex-end;

      .close-button {
        background: none;
        border: none;
        font-size: 1.2rem;
        cursor: pointer;
        color: $black-color;
      }

      .cta-button {
        font-size: 0.875rem;
        color: $black-color;
        padding: 0.5rem 1rem;
        text-decoration: none;
        border-radius: 4px;
        font-weight: bold;
      }
    }
  }
}
</style>
