import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const Procurement = {
  createProcurement: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/procurement/create`,
      method: "POST",
      data: JSON.stringify({
        productName: payload.productName,
        productLink: payload.productLink,
        productDescription: payload.productDescription,
        quantity: payload.quantity,
        productImage: payload.productImage,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  editProcurement: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/procurement/${payload.procurementId}`,
      method: "PUT",
      data: JSON.stringify({
        productName: payload.productName,
        productLink: payload.productLink,
        productDescription: payload.productDescription,
        quantity: payload.quantity,
        productImage: payload.productImage,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },
  printInvoice: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/procurement/download/${payload.procurementId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getUserProcurements: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/procurement`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getSingleProcurement: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/procurement/${payload.procurementId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default Procurement;
