<template>
  <div class="app">
    <div :class="this.$store.state.tokens.accessToken ? 'app_nav' : ''">
      <div v-if="this.$store.state.tokens.accessToken" class="nav">
        <nav-bar />

        <Menu
          class="menu"
          :class="{
            open: this.$store.state.openNav,
          }"
        />
      </div>
    </div>

    <div class="app_pages"><router-view /></div>
  </div>
</template>

<script>
import Menu from "./components/menu/MobileSideMenu.vue";
import NavBar from "./components/nav/NavBar.vue";
export default {
  components: {
    Menu,
    NavBar,
  },

  created() {
    if (window.top !== window.self) {
      window.top.location.href = window.self.location.href;
    }
  },

  mounted() {
    // this.checkRoute();
  },
  watch: {
    $route: {
      handler() {
        this.$store.dispatch("navMenuHandler", false);
        this.checkRoute();
      },
      immediate: false, // Ensure function runs on initial mount
    },
  },
  methods: {
    checkRoute() {
      if (this.$route.path === "/") {
        this.$router.push("/home");
      }
      // console.log(this.$store.state.accessToken);
    },
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";

.app_nav {
  @media only screen and (max-width: 920px) {
    display: flex;
    margin-bottom: 80px;
  }

  .nav {
    display: none;

    @media only screen and (max-width: 920px) {
      display: flex;
    }

    .menu {
      position: absolute;
      left: 0;
      transform: translateX(-100%);
      transition: 0.1s ease-in-out;
    }

    .open {
      transform: translateX(0);
    }
  }
}

@font-face {
  font-family: "Nohemi";
  src: url("./utils/fonts/Nohemi/Nohemi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nohemi";
  src: url("./utils/fonts/Nohemi/Nohemi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Nohemi";
  src: url("./utils/fonts/Nohemi/Nohemi-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
.app {
  /* App.css */
  margin: 0 auto;
  /* Center the container */
  max-width: 1740px;
  /* Set maximum width for the entire app */
}
body {
  margin: 0;
  font-family: "Nohemi", "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
