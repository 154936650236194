<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="procure_inner">
      <h1>Supplier Name : {{ item?.accountName.toUpperCase() }}</h1>

      <div class="item_details">
        <div>
          <p>Status</p>
          <p
            :class="{
              delivered:
                item?.status.toLowerCase() === 'delivered' ||
                item?.status.toLowerCase() === 'sorted',
              shipped:
                item?.status.toLowerCase() === 'pending' ||
                item?.status.toLowerCase() === 'shipped',
              logged: item?.status.toLowerCase() === 'logged',
            }"
          >
            {{ item?.status }}
          </p>
        </div>
        <div>
          <p>Amount in {{ item?.currency }}</p>
          <p>{{ Number(item?.amount).toLocaleString() }}</p>
        </div>
        <div>
          <p>Amount in NGN</p>
          <p>{{ Number(item?.amountinLocal).toLocaleString() }}</p>
        </div>

        <div v-if="item?.method">
          <p>Payment Method</p>
          <p>{{ item?.method }}</p>
        </div>

        <div>
          <p>Bank Name</p>
          <p>{{ item?.bankName }}</p>
        </div>

        <div>
          <p>Account Number</p>
          <p>{{ item?.accountNumber }}</p>
        </div>

        <div v-if="item?.fedWireRoutingNumber">
          <p>Fed Wire Routing Number</p>
          <p>{{ item?.fedWireRoutingNumber }}</p>
        </div>

        <div v-if="item?.swiftBicCode">
          <p>Swift BIC Code</p>
          <p>{{ item?.swiftBicCode }}</p>
        </div>

        <div v-if="item?.routingNumber">
          <p>ACH Routing Number</p>
          <p>{{ item?.routingNumber }}</p>
        </div>

        <div v-if="item?.address">
          <p>Address</p>
          <p>{{ item?.address }}</p>
        </div>

        <div v-if="item?.city">
          <p>City</p>
          <p>{{ item?.city }}</p>
        </div>

        <div v-if="item?.country">
          <p>Country</p>
          <p>{{ item?.country }}</p>
        </div>

        <div v-if="item?.email">
          <p>Email</p>
          <p>{{ item?.email }}</p>
        </div>

        <div v-if="item?.postCode">
          <p>Post Code</p>
          <p>{{ item?.postCode }}</p>
        </div>

        <div v-if="item?.sortCode">
          <p>Sort Code</p>
          <p>{{ item?.sortCode }}</p>
        </div>
      </div>

      <iframe
        class="iframe"
        v-if="item?.invoiceFile !== null"
        :src="item?.reciept || item?.invoiceFile"
        width="60%"
        height="300px"
        frameborder="0"
      ></iframe>

      <div
        v-if="item?.status.toLowerCase() === 'logged'"
        class="btn_wrapper"
        @click="openModalHandler"
      >
        <div class="button">
          <button class="button_btn">Make Payment</button>
        </div>
      </div>
      <p v-if="item?.status.toLowerCase() === 'logged'">
        (If you already made payment, wait for a mail to confirm your payment.)
      </p>

      <!-- <div class="btn_wrapper" @click="downloadPdf">
        <div class="button">
          <button class="button_btn">Download Invoice</button>
        </div>
      </div> -->
    </div>
  </div>
  <Overlay
    :amountToPayInNaira="amountToPayInNaira"
    :payment_id="payment_id"
    @closeModal="modalOpen = false"
    :openModal="modalOpen"
  />
</template>

<script>
import Overlay from "../../components/cards/Overlay.vue";
import CircleLoader from "../../components/loader/CircleLoader.vue";
import SupplierPayments from "../../utils/api/supplierPayments/SupplierPayments";
export default {
  data() {
    return {
      pdfUrl: "",
      isLoading: false,
      item: null,
      modalOpen: false,
      payment_id: "",
      amountToPayInNaira: 0,
    };
  },
  components: {
    CircleLoader,
    Overlay,
  },
  mounted() {
    this.fetchSupplierPaymentWithId();
  },
  methods: {
    openModalHandler() {
      this.modalOpen = true;
    },
    async fetchSupplierPaymentWithId() {
      const token = this.$store.state.tokens.accessToken;

      const payment_id = this.$route.params.itemId;

      this.isLoading = true;
      try {
        const userSupplierPaymentRes =
          await SupplierPayments.getSupplierPaymentById({
            payment_id,
            access_token: token,
          });

        this.isLoading = false;
        this.item = userSupplierPaymentRes.data.data[0];

        this.amountToPayInNaira = this.item.amountinLocal;

        this.payment_id = this.item._id;
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
    convertToPdf(base64Pdf) {
      if (base64Pdf) {
        const binaryData = atob(base64Pdf.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
      } else {
        this.pdfUrl = "";
      }
    },
    async downloadPdf() {
      const token = this.$store.state.tokens.accessToken;

      const payment_id = this.$route.params.itemId;

      this.isLoading = true;
      try {
        const userSupplierPaymentRes = await SupplierPayments.printInvoice({
          supplierPaymentId: payment_id,
          access_token: token,
        });

        this.isLoading = false;

        console.log(userSupplierPaymentRes.data);

        // Convert the response to a Blob
        const pdfData = userSupplierPaymentRes.data;

        // Convert binary data to a Blob
        const blob = new Blob([pdfData], { type: "application/pdf" });

        // console.log(blob);

        // Create a Blob URL
        // const url = window.URL.createObjectURL(blob);

        const formdata = new FileReader();

        formdata.readAsDataURL(blob);

        formdata.onload = (event) => {
          // console.log(event.target.result);
          const a = document.createElement("a");
          a.href = event.target.result;
          a.download = "invoice.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(event.target.result);
        };

        // Clean up after download
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.procure {
  width: 100%;
  padding: 2.75rem 5.125rem;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .procure_inner {
    display: flex;
    padding: 0.875rem 5.125rem;
    flex: 1;
    flex-direction: column;
    height: 53.3125em;
    flex-shrink: 0;
    border-radius: 1.875rem;
    gap: 1.5625rem;
    border: 1px solid #dedce9;
    background: #fafafa;

    @media only screen and (max-width: 1280px) {
      max-width: 770px;
      height: auto;
    }

    @media only screen and (max-width: 920px) {
      overflow: visible;
      gap: 1.5625rem;
      padding: 0rem 0.5em;
    }

    h1 {
      margin-top: 4.875rem;
      color: #000;
      font-family: $primary-font;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p {
      @media only screen and (max-width: 920px) {
        color: #1d1d1f;
        font-family: $secondary-font;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 50px;
      }
    }

    .item_details {
      display: inline-flex;
      overflow-y: scroll;
      height: 46.125em;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5625rem;
      width: 40.8125em;

      @media only screen and (max-width: 920px) {
        height: auto;
        overflow: auto;
      }

      h1 {
        margin-top: 0rem;
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      div {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        // .shipped {
        //   background: #badae6;
        //   height: 1.9375em;
        //   // width: 6.875em;
        //   align-self: center;
        //   display: flex;
        //   justify-content: center; /* Center text horizontally within the paragraph */
        //   align-items: center;
        //   border-radius: 6.25rem;
        //   padding: 0.375rem 0.8125rem;
        //   font-size: 0.9375rem;
        //   font-weight: 600;
        // }
        // .logged {
        //   flex: 0;
        //   background: #dedce9;
        //   height: 1.9375em;
        //   width: 6.875em;
        //   align-self: center;
        //   display: flex;
        //   justify-content: center; /* Center text horizontally within the paragraph */
        //   align-items: center;
        //   border-radius: 6.25rem;
        //   padding: 0.375rem 0.8125rem;
        //   font-size: 0.9375rem;
        //   font-weight: 600;
        // }
        // .delivered {
        //   background: #fed4c7;
        //   height: 1.9375em;
        //   // width: 6.875em;
        //   align-self: center;
        //   display: flex;
        //   justify-content: center; /* Center text horizontally within the paragraph */
        //   align-items: center;
        //   border-radius: 6.25rem;
        //   padding: 0.375rem 0.8125rem;
        //   font-size: 0.9375rem;
        //   font-weight: 600;
        // }

        p:nth-child(1) {
          color: #626262;
          font-family: $secondary-font;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0px;

          @media only screen and (max-width: 920px) {
            font-size: 0.9375rem;
          }
        }

        p:nth-child(2) {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #1d1d1f;
          font-family: $secondary-font;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 0px;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }

        a {
          flex: 1;
          max-width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #2d5795;
          font-family: $secondary-font;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }
      }
    }
    .iframe {
      @media only screen and (max-width: 920px) {
        width: 100%;
      }
    }
    .btn_wrapper {
      display: flex;
      flex-direction: column;
      width: 150px;
      padding: 23px, 248px, 23px, 243px;
      a {
        width: 100%;
      }
    }
  }
}
</style>
