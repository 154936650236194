<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="btn_header_wrapper" v-if="!isLoading">
      <div class="button">
        <router-link
          :to="{
            name: 'editProcurement',
            params: { Id: id },
          }"
        >
          <button
            :disabled="item?.status.toLowerCase() !== 'logged'"
            class="button_btn"
            @mouseenter="handleMouseEnter(item?.status.toLowerCase())"
            @mouseleave="handleMouseLeave"
          >
            Edit Procurement
          </button>
        </router-link>
        <div
          v-if="showTooltip && item?.status.toLowerCase() !== 'logged'"
          class="tooltip"
        >
          {{ tooltipMessage }}
        </div>
      </div>
    </div>
    <div class="procure_inner">
      <div class="procure_inner_header">
        <h1>{{ item?.productName }}</h1>
        <!-- <div @click="openDeleteModal">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_2581)">
              <path
                d="M17 4H22V6H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                fill="#3E414C"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_2581">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div> -->
      </div>

      <div class="item_details">
        <div>
          <p>ID</p>
          <p>{{ item?.listingId }}</p>
        </div>

        <div>
          <p>Description</p>
          <p>{{ item?.productDescription }}</p>
        </div>

        <div>
          <p>Quantity</p>
          <p>{{ item?.quantity }}</p>
        </div>

        <div v-if="item.amount">
          <p>Amount in NGN</p>
          <p>{{ Number(item?.amount).toLocaleString() }}</p>
        </div>

        <div>
          <p>Status</p>
          <p>
            {{
              item?.status?.charAt(0)?.toUpperCase() + item?.status?.slice(1)
            }}
          </p>
        </div>

        <div>
          <p>Product Link</p>
          <a :href="item?.productLink" target="_blank">{{
            item?.productLink?.substring(0, 70)
          }}</a>
        </div>
      </div>

      <router-link
        :to="{
          name: 'procurementToShipment',
          // params: { Id: item?._id },
        }"
      >
        <div
          class="procure_to_shipment"
          v-if="item.status.toLowerCase() === 'procured'"
        >
          <p>Do you want to ship this item with us?</p>
        </div>
      </router-link>

      <div class="procure_item_image">
        <div class="procure_item_image_inner">
          <img :src="item?.productImage" alt="procurement_image" />
        </div>
      </div>

      <!-- <div class="btn_wrapper" @click="downloadPdf">
        <div class="button">
          <button class="button_btn">Download Invoice</button>
        </div>
      </div> -->
    </div>
  </div>
  <delete-modal
    :visible="showModal"
    @cancel="handleCancel"
    @confirm="handleConfirm"
  />
</template>

<script>
import Procurement from "@/utils/api/procurement/ProcurementApi";
import DeleteModal from "../../components/cards/DeleteModal.vue";
import CircleLoader from "../../components/loader/CircleLoader.vue";

export default {
  data() {
    return {
      isLoading: true,
      item: null,
      id: "",
      showModal: false,
      showTooltip: false,
      tooltipMessage:
        "This procurement order is already logged and cannot be edited. For any changes, please contact us at +2347037067376.",
    };
  },
  components: {
    CircleLoader,
    DeleteModal,
  },
  mounted() {
    this.fetchUserProcurements();
  },
  methods: {
    handleMouseEnter(status) {
      if (status !== "logged") {
        this.showTooltip = true;
      }
    },
    handleMouseLeave() {
      this.showTooltip = false;
    },
    openDeleteModal() {
      this.showModal = true;
    },
    handleCancel() {
      this.showModal = false;
    },
    handleConfirm() {
      // Handle the delete action here
      console.log("Item deleted:");
      this.showModal = false;
      // Implement further delete logic here
    },
    async fetchUserProcurements() {
      const token = this.$store.state.tokens.accessToken;

      const procurementId = this.$route.params.itemId;

      this.id = this.$route.params.itemId;

      this.isLoading = true;
      try {
        const userProcurementRes = await Procurement.getSingleProcurement({
          procurementId,
          access_token: token,
        });

        this.isLoading = false;

        if (userProcurementRes.data.data.status.toLowerCase() === "procured") {
          this.$store.dispatch(
            "storeProcurementDetails",
            userProcurementRes.data.data
          );
        }

        this.item = userProcurementRes.data.data;
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(err.response?.data?.message || err.message);
        }
      }
    },

    async downloadPdf() {
      const token = this.$store.state.tokens.accessToken;

      const procurementId = this.$route.params.itemId;

      this.isLoading = true;
      try {
        const userSupplierPaymentRes = await Procurement.printInvoice({
          procurementId: procurementId,
          access_token: token,
        });

        this.isLoading = false;
        console.log(userSupplierPaymentRes.data);
      } catch (err) {
        this.isLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.procure {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 2.75rem 5.125rem;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .btn_header_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 3.125em;

    @media only screen and (max-width: 920px) {
      width: 100%;
    }

    .button {
      position: relative;
      display: inline-block;

      .tooltip {
        background: #626262;
        color: #fafafa;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        width: 200px;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 5px;
      }

      .tooltip::after {
        content: "";
        position: absolute;
        top: -5px;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #fafafa transparent;
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      a {
        text-decoration: none;

        @media only screen and (max-width: 920px) {
          margin-top: 0.75rem;
          width: 100%;
        }

        button {
          width: 12.875em;

          @media only screen and (max-width: 920px) {
            width: 100%;
          }
        }
      }
    }
  }

  .procure_inner {
    display: flex;
    padding: 0.875rem 5.125rem;
    flex: 1;
    flex-direction: column;
    height: auto;
    flex-shrink: 0;
    border-radius: 1.875rem;
    gap: 2.8125rem;
    border: 1px solid #dedce9;
    background: #fafafa;

    @media only screen and (max-width: 1280px) {
      max-width: 770px;
    }

    @media only screen and (max-width: 920px) {
      overflow: visible;
      gap: 1.5625rem;
      padding: 0rem 0.5em;
    }
    .procure_inner_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
      }

      @media only screen and (max-width: 920px) {
        padding: 0.8rem 0em;
      }
      h1 {
        color: #000;
        font-family: $primary-font;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .item_details {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5625rem;
      width: 40.8125em;

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      div {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p:nth-child(1) {
          color: #626262;
          font-family: $secondary-font;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.9375rem;
          }
        }

        p:nth-child(2) {
          flex: 1;
          max-width: 70%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #1d1d1f;
          font-family: $secondary-font;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }

        a {
          flex: 1;
          max-width: 70%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #2d5795;
          font-family: $secondary-font;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
        }
      }
    }
    a {
      color: #626262;
      .procure_to_shipment {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .procure_item_image {
      width: 33.3125em;
      height: 14.125em;
      display: flex;
      flex-shrink: 0;

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      .procure_item_image_inner {
        display: flex;
        width: 100%;
        height: auto;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
