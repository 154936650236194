import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const Shipment = {
  createShipment: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/create`,
      method: "POST",
      data: JSON.stringify({
        name: payload.name,
        isDangerous: payload.isDangerous,
        supplierTrackingNumber: payload.supplierTrackingNumber,
        category: payload.category,
        freightType: payload.freightType,
        fromCountry: payload.fromCountry,
        weight: payload.weight,
        quantity: payload.quantity,
        weightUnit: payload.weightUnit,
        dropOffwarehouseAddressID: payload.dropOffwarehouseAddressID,
        pickUpWarehouseAddressID: payload.pickUpWarehouseAddressID,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  mergeShipments: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/merge`,
      method: "POST",
      data: JSON.stringify({
        shipmentIds: payload.shipmentIds,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  editShipment: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/${payload.shipmentId}`,
      method: "PUT",
      data: JSON.stringify({
        name: payload.name,
        isDangerous: payload.isDangerous,
        supplierTrackingNumber: payload.supplierTrackingNumber,
        category: payload.category,
        freightType: payload.freightType,
        fromCountry: payload.fromCountry,
        quantity: payload.quantity,
        weight: payload.weight,
        weightUnit: payload.weightUnit,
        dropOffwarehouseAddressID: payload.dropOffwarehouseAddressID,
        pickUpWarehouseAddressID: payload.pickUpWarehouseAddressID,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  printInvoice: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/invoice/${payload.shipmentId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  shipMentPayment: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/make-payment/${payload.shipmentId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getUserShipments: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getSingleShipment: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/${payload.shipmentId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getCategory: () => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/category`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getWarehouse: () => {
    const res = axios({
      url: `${baseUrl}/api/v1/shipment/warehouse`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default Shipment;
