<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="resources">
    <div class="resources_side_menu">
      <side-menu />
    </div>
    <circle-loader v-if="isLoading" />
    <div class="profile-wrapper" v-else>
      <div class="profile">
        <h1>Profile</h1>

        <div class="profile_inner">
          <div class="profile_inner_info">
            <div class="profile_inner_info_title"><p>Name</p></div>

            <div class="input">
              <input
                :value="`${userDetails.firstName} ${userDetails.lastName}`"
                class="input_field"
                disabled
              />
            </div>
          </div>

          <div class="profile_inner_info">
            <div class="profile_inner_info_title">
              <p>Company Name</p>
            </div>
            <div class="input">
              <input
                :value="userDetails.businessName"
                class="input_field"
                disabled
              />
            </div>
          </div>

          <div
            class="profile_inner_info"
            v-if="!userDetails?.verifiedBusinessName"
          >
            <div class="profile_inner_info_title">
              <p>Company RC number</p>
            </div>

            <div
              class="input"
              :style="
                rcNumber.length >= 0 && rcNumber.length < 6
                  ? { flexDirection: 'column', marginBottom: '12px' }
                  : ''
              "
            >
              <input
                v-model="rcNumber"
                required
                placeholder="111111"
                class="input_field"
              />
              <p
                v-if="rcNumber.length >= 0 && rcNumber.length < 6"
                class="rcNumber_error_message"
              >
                Enter your correct business RC number
              </p>
            </div>
          </div>

          <div class="profile_inner_info">
            <div class="profile_inner_info_title">
              <p>Email</p>
            </div>

            <div class="input">
              <input :value="userDetails.email" class="input_field" disabled />
            </div>
          </div>

          <div class="profile_inner_info">
            <div class="profile_inner_info_title">
              <p>Phone Number</p>
            </div>

            <div class="input">
              <input
                :value="`+234${userDetails.businessPhoneNumber}`"
                class="input_field"
                disabled
              />
            </div>
          </div>

          <div class="profile_inner_info">
            <div class="profile_inner_info_title">
              <p>Address</p>
            </div>

            <div class="input">
              <input
                :value="userDetails?.businessLocation"
                class="input_field"
                disabled
              />
            </div>
          </div>

          <div class="btn_wrapper" v-if="!userDetails?.verifiedBusinessName">
            <div
              v-if="!formLoading"
              class="button"
              @click="completeKycRegisteration"
            >
              <button :disabled="rcNumber.length < 6" class="button_btn">
                Complete KYC
              </button>
            </div>

            <div v-else class="button">
              <button :disabled="formLoading" class="button_btn">
                <div class="loading-circle"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="profile" style="margin-bottom: 100px">
        <h1>Refer and Earn Points</h1>

        <div class="profile_inner">
          <div class="profile_inner_info">
            <div class="profile_inner_info_title">
              <p>Referral Code</p>
            </div>

            <div class="input">
              <input
                :value="referralDetails?.code"
                class="input_field"
                disabled
              />
              <svg
                @click="
                  copy(
                    `https://app.novoinno.com/register?referralCode=${referralDetails?.code}`
                  )
                "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_2013)">
                  <path
                    d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                    fill="#3E414C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_2013">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          <div class="profile_inner_info">
            <div class="profile_inner_info_title">
              <p>Tier</p>
            </div>

            <div class="input">
              <input
                :value="referralDetails?.tier"
                class="input_field"
                disabled
              />
            </div>
          </div>

          <div class="profile_inner_info">
            <div class="profile_inner_info_title">
              <p>Reward Points</p>
            </div>

            <div class="input">
              <input
                :value="referralDetails?.rewardPoint"
                class="input_field"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Authentication from "@/utils/api/AuthApi";
import SideMenu from "../../components/menu/SideMenu.vue";
import CircleLoader from "../../components/loader/CircleLoader.vue";

export default {
  data() {
    return {
      userDetails: [],
      isLoading: false,
      rcNumber: "",
      referralDetails: [],
      formLoading: false,
    };
  },
  components: {
    SideMenu, // Register the component
    CircleLoader,
  },
  mounted() {
    this.getUserDetails();
  },
  methods: {
    copy(data) {
      this.$copyText(data);
      this.$toast.success(`Referral Code Copied`);
    },
    async completeKycRegisteration() {
      const token = this.$store.state.tokens.accessToken;
      this.formLoading = true;
      try {
        const KycRegisterationRes = await Authentication.verifyUserBusiness({
          RCNumber: this.rcNumber,
          access_token: token,
        });
        this.formLoading = false;
        this.$toast.success("Your business has successfully been verified");
        window.location.reload();
        return KycRegisterationRes;
      } catch (err) {
        this.formLoading = false;
        this.$toast.error(err.response?.data?.message);
      }
    },
    async getUserDetails() {
      const token = this.$store.state.tokens.accessToken;

      this.isLoading = true;
      try {
        const res = await Authentication.getUserDetails({
          access_token: token,
        });

        const getRefferalDetailsRes = await Authentication.getRefferalDetails({
          access_token: token,
        });

        this.referralDetails = getRefferalDetailsRes.data.data;

        this.userDetails = res.data.data;

        this.isLoading = false;

        return res;
      } catch (err) {
        this.isLoading = false;
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.resources {
  display: flex;
  height: 100vh;
  overflow: scroll;

  .resources_side_menu {
    @media only screen and (max-width: 920px) {
      display: none;
    }
  }

  .profile-wrapper {
    width: 100%;
    padding: 0.75rem 0.9375rem;
    overflow-y: scroll;

    .profile {
      display: flex;
      width: 100%;
      flex-direction: column;

      h1 {
        color: #000;
        margin: 0px 35px;
        font-family: $primary-font;
        font-size: 2rem;
        margin-top: 3.4375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media only screen and (max-width: 920px) {
          margin: 0px 0px;
          font-size: 1.5625rem;
          margin-top: 5px;
        }
      }

      .profile_inner {
        display: flex;
        margin: 50px 35px;
        flex-direction: column;
        gap: 1.5625rem;
        width: 100%;
        max-width: 950px;

        .btn_wrapper {
          display: flex;
          flex-direction: column;
          width: 50%;
          flex: 1;
          align-self: center;
        }

        @media only screen and (max-width: 920px) {
          margin: 1.875rem 0rem;
        }

        .profile_inner_info {
          display: flex;
          align-items: center;

          .input {
            width: 50%;
            display: flex;
            gap: 0.375rem;
            flex: 1;
            flex-direction: row;

            .rcNumber_error_message {
              color: rgb(255, 58, 58);
              text-decoration: dashed;
              width: 100%;
              display: flex;
              align-items: flex-start;
              flex: 1;
              font-size: 0.875rem;

              @media only screen and (max-width: 920px) {
                font-size: 0.75rem;
              }
            }

            @media only screen and (max-width: 920px) {
              width: 70%;
            }
          }

          .profile_inner_info_title {
            display: flex;
            flex: 1;
            max-width: 20%;
            @media only screen and (max-width: 920px) {
              max-width: 30%;
            }
          }

          p {
            color: #606060;
            font-family: $secondary-font;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media only screen and (max-width: 920px) {
              font-size: 0.9375rem;
            }
          }
        }
      }
    }
  }
}
</style>
