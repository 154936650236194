<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="resources">
    <div class="resources_side_menu">
      <side-menu />
    </div>
    <div class="resources_body">
      <h1>Frequently asked questions</h1>
      <div class="input resources_body_search">
        <input v-model="faqSearch" placeholder="Search" class="input_field" />
      </div>

      <div
        class="resources_body_faq"
        v-for="faq in filteredFaq"
        :key="faq.question"
      >
        <div class="resources_body_faq_question" @click="faqHandler(faq.id)">
          <div class="resources_body_faq_question_inner">
            <p>{{ faq?.question }}</p>
            <svg
              :class="{
                openSvg: questionIndex === faq?.id,
              }"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="11.1562"
                x2="11.1562"
                y2="22"
                stroke="#1D1D1F"
                stroke-opacity="0.6"
                stroke-width="2"
              />
              <line
                y1="10"
                x2="22"
                y2="10"
                stroke="#1D1D1F"
                stroke-opacity="0.6"
                stroke-width="2"
              />
            </svg>
          </div>
          <div
            :class="{
              resources_body_faq_question_answer: true,
              open: questionIndex === faq?.id,
            }"
          >
            <p v-if="questionIndex === faq?.id">
              <span v-html="faq?.answer"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "../../components/menu/SideMenu.vue";
import Faq from "../../utils/Faq";
export default {
  data() {
    return {
      faqData: [],
      questionIndex: null,
      filteredFaq: [],
      faqSearch: "",
    };
  },
  components: {
    SideMenu, // Register the component
  },
  mounted() {
    this.fechFaq();
  },
  computed: {
    filterList() {
      const { faqSearch } = this;
      return {
        faqSearch,
      };
    },
  },

  watch: {
    filterList: {
      handler: function (value) {
        const filterByQuestion = this.faqData.filter((question) => {
          return question.question
            ?.toLowerCase()
            .includes(value?.faqSearch?.toLowerCase());
        });

        this.filteredFaq = filterByQuestion;
      },
      deep: true,
    },
  },

  methods: {
    fechFaq() {
      this.faqData = Faq.faqData;

      this.filteredFaq = this.faqData;
    },
    faqHandler(id) {
      if (this.questionIndex === id) {
        this.questionIndex = null;
      } else {
        this.questionIndex = id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.resources {
  display: flex;
  height: 100vh;
  overflow: scroll;

  .resources_side_menu {
    @media only screen and (max-width: 920px) {
      display: none;
    }
  }

  .resources_body {
    padding: 5.3125rem 9.0625rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
    height: 100vh;
    overflow: scroll;

    h1 {
      color: #000;
      font-family: $primary-font;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 1.5rem;
      }
    }

    @media only screen and (max-width: 920px) {
      padding: 0.9375rem 1.5rem;
      gap: 1.5625rem;
      height: auto;
      overflow: scroll;
    }

    .resources_body_search {
      width: 100%;
      height: 54.431px;
      border-radius: 5px;
      border: 1px solid #c4c1c1;
    }

    .resources_body_faq {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3.125rem;
      width: 100%;

      .resources_body_faq_question {
        width: 100%;
        height: auto;
        cursor: pointer;
        display: flex;
        gap: 0.9375rem;
        flex-direction: column;

        .resources_body_faq_question_inner {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            color: #363539;
            font-family: $secondary-font;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media only screen and (max-width: 920px) {
              font-size: 0.9375rem;
            }
          }

          .openSvg {
            transform: rotate(44deg);
            transition: all 0.3s ease-in-out;
          }
        }

        .resources_body_faq_question_answer {
          display: flex;
          flex-direction: column;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease-in-out;

          p {
            color: #363539;
            font-family: $secondary-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;

            @media only screen and (max-width: 920px) {
              font-size: 14px;
            }
          }
        }
        .open {
          max-height: 55em;
        }
      }
    }
  }
}
</style>
