<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <transition name="slide">
    <div @click="closeModal" v-if="openModal" class="overlay">
      <div @click.stop class="content">
        <!-- Your content here -->
        <div class="content_inner">
          <h1>Make Payment</h1>
          <div class="content_inner_card">
            <div>
              <p>Account Number: 5400956311</p>
            </div>
            <div>
              <p>Bank Name: Providus Bank</p>
            </div>
            <div>
              <p>Account Name: Novoinno Logistics Limited</p>
            </div>
            <div>
              <p>
                Amount to pay: ₦{{
                  Number(amountToPayInNaira).toLocaleString()
                }}
              </p>
            </div>
          </div>
          <div @click="closeModal" class="button">
            <button class="button_btn">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    openModal: Boolean,
    payment_id: String,
    amountToPayInNaira: Number,
  },
  methods: {
    closeModal() {
      this.$router.push(`/supplier-payments/${this.payment_id}`);
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: white;
  animation: slideIn 0.3s forwards;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 920px) {
    position: relative;
    width: 95%;
    height: 60%;
    margin: auto;
    padding: 0.75rem;
    border-radius: 0.75rem;
  }

  .content_inner {
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    gap: 1.5625rem;
    align-items: center;
    width: 25.1875em;

    @media only screen and (max-width: 920px) {
      width: 100%;
    }

    h1 {
      align-self: flex-start;
      display: flex;
      text-align: left;
    }

    .content_inner_card {
      width: 27.1875em;
      height: 14.6875em;
      border-radius: 0.75rem;
      background-color: #f1f1f1;
      padding: 0.75rem 1.5rem;
      gap: 1.25rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      div {
        width: 100%;
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20.24px;
          text-align: left;
        }
      }
    }

    .button {
      width: 19.1875em;

      @media only screen and (max-width: 920px) {
        width: 100%;
      }
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.slide-leave-active .content {
  animation: slideOut 0.3s forwards;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
