import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

export default createStore({
  strict: true,
  state: {
    tokens: [],
    openNav: false,
    signUpDatails: [],
    userDetails: [],
    procurementDetails: [],
  },
  getters: {},
  mutations: {
    storeSignUpDetails: (state, payload) => {
      state.signUpDatails = payload;
    },
    clearSignUpDetails: (state) => {
      state.signUpDatails = [];
      state.tokens = [];
    },
    storeAccessToken: (state, payload) => {
      state.tokens = payload;
    },
    navMenuHandler: (state, payload) => {
      state.openNav = payload;
    },
    storeUserDetails: (state, payload) => {
      state.userDetails = payload;
    },
    updateAccessToken: (state, payload) => {
      state.tokens = payload;
    },

    storeProcurementDetails: (state, payload) => {
      state.procurementDetails = payload;
    },
  },
  actions: {
    storeSignUpDetails: (context, payload) => {
      context.commit("storeSignUpDetails", payload);
    },
    clearSignUpDetails: (context) => {
      context.commit("clearSignUpDetails");
    },
    storeAccessToken: (context, payload) => {
      context.commit("storeAccessToken", payload);
    },

    navMenuHandler: (context, payload) => {
      context.commit("navMenuHandler", payload);
    },
    storeUserDetails: (context, payload) => {
      context.commit("storeUserDetails", payload);
    },
    updateAccessToken: (context, payload) => {
      context.commit("updateAccessToken", payload);
    },
    storeProcurementDetails: (context, payload) => {
      context.commit("storeProcurementDetails", payload);
    },
  },
  plugins: [new VuexPersistence().plugin],
});
